import { OrderEditMode, SummaryDto } from '../../../core/models/order.dto';
import { Component, OnInit } from '@angular/core';
import { NgRedux, select, select$ } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { WizardStep } from '../../models/ui.model';
import { UIActions } from '../../actions/ui.actions';
import { OrderItemVo, Stop, StopType } from '../../models/order.model';
import {
    getCurrentStop,
    getMainStop,
    isStopsRequiredFieldsProvided,
    ShipmentSelectors, hasInvalidAddresses
} from '../../selectors/shipment.selectors';
import { ShipmentActions } from '../../actions/shipment.actions';
import { OrderSelectors } from '../../selectors/order.selectors';
import { OrderStopsActions } from '../../actions/order-stops.actions';
import { ShipmentEditSelectors } from '../../../shipment-edit/selectors/shipment-edit.selectors';
import { UISelectors } from '../../selectors/ui.selectors';
import { IAppState } from '../../../store/model';

@Component({
  selector: 'tuya-shipment-builder-page',
  templateUrl: './shipment-builder-page.component.html',
  styleUrls: ['./shipment-builder-page.component.scss']
})
export class ShipmentBuilderPageComponent implements OnInit {

    WizardStep: typeof WizardStep = WizardStep;
    StopType: typeof StopType = StopType;

    @select(['shipment', 'ui', 'isLoading'])
    readonly isLoading$: Observable<boolean>;
    @select(['home', 'myOrders', 'ordersDetails', 'isLoading'])
    readonly isLoadingOrderDetail$: Observable<boolean>;
    @select(UISelectors.invalidTimeframesStopsSelector)
    invalidTimeframesStopsList$: Observable<Array<string>>;
    @select(ShipmentSelectors.wizardStepSelector)
    wizardStep$: Observable<number>;
    @select(OrderSelectors.orderStopListSelector)
    orderStopList$: Observable<Stop[]>;
    @select$ (['shipment', 'order'], getCurrentStop)
    currentStop$: Observable<Stop>;
    @select(OrderSelectors.orderItemListSelector)
    orderItemList$: Observable<OrderItemVo[]>;
    @select$(['shipment', 'order', 'stopList'], hasInvalidAddresses)
    hasInvalidAddress$: Observable<boolean>;
    @select$(['shipment', 'order'], isStopsRequiredFieldsProvided)
    isStopsRequiredFieldsProvided$: Observable<boolean>;
    @select(OrderSelectors.orderDeliveryTypeSelector)
    orderDeliveryType$: Observable<number>;
    @select$ (['shipment', 'order'], getMainStop)
    mainStop$: Observable<Stop|undefined>;
    @select(OrderSelectors.isDirectOrderSelector)
    isDirectOrder$: Observable<boolean>;
    @select(ShipmentEditSelectors.selectEditMode)
    editMode$: Observable<OrderEditMode>;
    @select(['shipment', 'order', 'referenceId'])
    orderRefID$: Observable<any>;
    @select(['shipmentEdit', 'initialOrder', 'referenceId'])
    orderEditRefID$: Observable<any>;

    // briefSummary
    @select(OrderSelectors.orderSummarySelector)
    orderSummary$: Observable<SummaryDto>;

    private currentStop = new BehaviorSubject<Stop>(new Stop());
    private orderDeliveryType = new BehaviorSubject<number>(0);
    private isStopsRequiredFieldsProvided = true;
    private isHasInvalidTimeframes = false;
    private orderStopList = new BehaviorSubject<Stop[]>([]);

    constructor(private uiActions: UIActions,
                private ngRedux: NgRedux<IAppState>,
                private shipmentActions: ShipmentActions,
                private orderStopsActions: OrderStopsActions) {

        this.currentStop$.subscribe(this.currentStop);
        this.orderDeliveryType$.subscribe(this.orderDeliveryType);
        this.orderStopList$.subscribe(orderStopList => {
            this.orderStopList.next(orderStopList);
            this.uiActions.setWizardStepContinueAllowed(WizardStep.Stops, this.isContinueAllowed());
        });

        this.isStopsRequiredFieldsProvided$.subscribe(isProvided => {
            this.isStopsRequiredFieldsProvided = isProvided;
            this.uiActions.setStopDetailsEditMode(this.isContinueAllowed());
            this.uiActions.setWizardStepContinueAllowed(WizardStep.Stops, this.isContinueAllowed());
        });

        // disable Continue button if invalid timeframes exist in stop list
        this.invalidTimeframesStopsList$.subscribe(list => {
            const editInProgressOrder = ShipmentEditSelectors.selectEditMode(this.ngRedux.getState()) === OrderEditMode.PartiallyCompleted;
            if (editInProgressOrder) return;

            this.isHasInvalidTimeframes = !!list.length;
            this.uiActions.setWizardStepContinueAllowed(WizardStep.Stops, this.isContinueAllowed());
        });

        this.wizardStep$.subscribe( step => {
            // TODO: needs actual preloader.
            if (step === WizardStep.Stops) {
                this.onBeforeShipmentBuilderEnter();
            }
        });
    }

    ngOnInit() {
    }

    isContinueAllowed() {
        return this.isStopsRequiredFieldsProvided && !this.isHasInvalidTimeframes && this.orderStopList.getValue().length > 1;
    }

    onUpdateStop(stopUpdates, sendOrderUpdate = true) {
        // Local stop update ( sync )
        this.shipmentActions.updateOrderStop({
            ...stopUpdates,
            stopId: this.currentStop.getValue().id
        });

        // async send
        if (sendOrderUpdate) {
            this.shipmentActions.shipmentOrderUpdate();
        }
    }

    onSelectStop(stopId: any) {
        this.shipmentActions.selectCurrentStop(stopId);
    }

    onRemoveStop(stopId: any) {
        this.shipmentActions.removeStop(stopId);
        // Backend request to save order
        this.shipmentActions.shipmentOrderUpdate();
    }


    onEditStop(stopId: any) {
        this.shipmentActions.selectCurrentStop(stopId);
        this.uiActions.setWizardStep(WizardStep.EditStopDetails);
        // if all required fields provided - stop in edit mode and continue is avaliable
        if (this.isStopsRequiredFieldsProvided) {
            this.uiActions.setStopDetailsEditMode(true);
        }
        this.uiActions.setWizardStepContinueAllowed(WizardStep.EditStopDetails, this.isContinueAllowed());
    }

    onUpdateSuiteNumber (stopUpdates) {
        this.onUpdateStop(stopUpdates);
        // if suite number and address was entered - go to edit details
        if (stopUpdates.address.suiteNumber && this.currentStop.getValue().address.addressLine) {
            this.onEditStop(this.currentStop.getValue().id);
        }
    }

    onBeforeShipmentBuilderEnter() {
        if (this.isStopsRequiredFieldsProvided
          || (this.orderStopList.getValue() && this.orderStopList.getValue().length === 1)) {
            this.onSelectStop(null);
        }
    }

    onSetIsDirectOrder(isDirect: boolean) {
        this.orderStopsActions.setIsDirectOrder(isDirect);
    }
}
