import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { first, Observable } from 'rxjs';
import { NgRedux } from '@angular-redux-ivy/store';
import { AuthGuard } from './auth.guard';
import { AuthActions } from '../actions/auth.actions';
import { IAppState } from '../../store/model';

@Injectable()
export class OrdersListGuard implements CanActivate {

    constructor(private ngRedux: NgRedux<IAppState>,
                private _authGuard: AuthGuard,
                private authActions: AuthActions) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

        return new Observable<boolean>(observer => {
            this._authGuard.canActivate(route).pipe(first()).subscribe((data: boolean) => {
                if (!data) {
                    observer.next(false);
                } else {
                    const currentUser = this.ngRedux.getState().auth.currentUser;
                    if (currentUser && currentUser.shipperProfile &&
                        currentUser.shipperProfile.isEmailConfirmed && currentUser.shipperProfile.hasOrders) {
                        observer.next(true);
                    } else {
                        this.authActions.goToHome();
                        observer.next(false);
                    }
                }
            });
        });
    }
}
