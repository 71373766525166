import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';
import { Stop } from '../models/order.model';

export type OrderStopsAction = Action<any, any>;

export const SHIPMENT_IS_DIRECT_ORDER_UPDATE = '[SHIPMENT] set is order direct';
export const SHIPMENT_SET_DEFAULT_DATE_TIME_FOR_STOP_LIST = '[SHIPMENT] Set default datetime values for stop list';
export const SHIPMENT_SAVE_DEFAULT_DATE_TIME_FOR_STOP_LIST = '[SHIPMENT] Save default datetime values for stop list';
export const SHIPMENT_SET_EXTENDABLE = '[SHIPMENT] Set order extendable';
export const SHIPMENT_SAVE_INVALID_TIMEFRAMES_STOPS = '[SHIPMENT] save stop ids with invalid timeframes';

@Injectable()
export class OrderStopsActions {

    @dispatch()
    setIsDirectOrder = (isDirect: boolean, isUpdateOrder = true): OrderStopsAction => ({
        type: SHIPMENT_IS_DIRECT_ORDER_UPDATE,
        payload: {isDirect, isUpdateOrder}
    })

    @dispatch()
    setDefaultDateTimeForStopList = (): OrderStopsAction => ({
        type: SHIPMENT_SET_DEFAULT_DATE_TIME_FOR_STOP_LIST,
        payload: null
    })

    @dispatch()
    saveDefaultDateTimeForStopList = (stopList: Stop[]): OrderStopsAction => ({
        type: SHIPMENT_SAVE_DEFAULT_DATE_TIME_FOR_STOP_LIST,
        payload: stopList
    })

    @dispatch()
    setOrderExtendable = (isExtendable: boolean): OrderStopsAction => ({
        type: SHIPMENT_SET_EXTENDABLE,
        payload: isExtendable
    })

    @dispatch()
    saveInvalidTimeframesStops = (stopIds: Array<any>): OrderStopsAction => ({
        type: SHIPMENT_SAVE_INVALID_TIMEFRAMES_STOPS,
        payload: stopIds
    })
}

