import { ItemsSizesActions } from './core/actions/items-sizes.actions';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { Alert, AlertType } from './core/models/alert.model';
import { IAppState } from './store/model';
import { StatesActions } from './core/actions/states.actions';
import { NotificationDto } from './core/models/notification.model';
import { NotificationActions } from './core/actions/notification.actions';
import { Router } from '@angular/router';
import { AuthSelectors } from './auth/selectors/auth.selectors';
import { RouteSubstituteService } from './core/services/route-substitute.service';
import { UIActions } from './shipment/actions/ui.actions';
import { ShipperProfileDto } from './core/models/dto';
import { ShipperActions } from './shipper/actions/shipper.status.actions';
import { ShipperAdminActions } from './admin/actions/shipper.admin.actions';
import { AuthActions } from './auth/actions/auth.actions';
import { MyOrdersActions } from './home/actions/my-orders.actions';
import { AlertActions } from './core/actions/alert.actions';
import { ShipperStatuses } from './shipper/model/model';
import { SearchOrderActions } from './home/actions/search-order.actions';
import { ShipmentSelectors } from './shipment/selectors/shipment.selectors';
import { LatLng } from './core/models/map.model';
import { StripeScriptTag } from "stripe-angular"
import { ConfigService } from './app.config.service';

// contains stateId, where TUYA service works
export const TUYA_US_STATES = new Set();

@Component({
    selector: 'tuya-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    @select(AuthSelectors.loggedInSelector)
    readonly loggedIn$: Observable<boolean>;
    @select(['auth', 'currentUser', 'adminProfile'])
    adminProfile$: Observable<ShipperProfileDto>;
    @select(['auth', 'showSuspendAlert'])
    showSuspendAlert$: Observable<boolean>;
    @select(['core', 'alert', 'alert'])
    readonly alert$: Observable<Alert>;
    @select(['core', 'notification', 'notification'])
    readonly itemList$: Observable<any>;
    @select(ShipmentSelectors.mapCenterDefault)
    mapDefaultCenter$: Observable<LatLng>;

    @select(['shipper', 'shipperStatus'])
    readonly shipperStatus$: Observable<ShipperStatuses>;

    @select(['router'])
    readonly currentRouter$: Observable<string>;

    constructor(private ngRedux: NgRedux<IAppState>,
        private statesActions: StatesActions,
        private itemsSizesActions: ItemsSizesActions,
        private notificationActions: NotificationActions,
        private router: Router,
        private alertActions: AlertActions,
        private uIActions: UIActions,
        private shipperActions: ShipperActions,
        private shipperAdminActions: ShipperAdminActions,
        private authActions: AuthActions,
        private myordersActions: MyOrdersActions,
        private searchOrderActions: SearchOrderActions,
        private routeSubstituteService: RouteSubstituteService,
        private stripeScriptTag: StripeScriptTag,
        private configService: ConfigService) {

            this.currentRouter$.subscribe(res => {
                if (res === '/admin-search' || res === '/driver-portal') {
                    this.shipperActions.setInitialState();
                    this.shipperAdminActions.resetAdminStoreValues();
                    this.authActions.updateShipperProfileToState(null);
                    this.myordersActions.resetPagination();
                    this.searchOrderActions.resetOrderSearch();
                } else if (res === '/login') {
                    this.authActions.logout();
                }
            });
            // add a Texas state id
            // store->core->states
            TUYA_US_STATES.add(43);
        }

    // ATTENTION!!! Do not use here any queries which required authentication
    ngOnInit() {
        this.riderectUserToWWW();
        this.routeSubstituteService.subscribeToRoute();
        if (this.ngRedux.getState().core && !this.ngRedux.getState().core.states.length) {
            this.statesActions.load();
            this.itemsSizesActions.loadItemsSizes();
        }
        this.notificationActions.subscribeNotifications();

        this.shipperStatus$.subscribe(res => {
            if (res.suspended && this.ngRedux.getState().auth.showSuspendAlert) {
                const alert: Alert = {
                    type: AlertType.Suspended,
                    callback: (accepted: boolean) => {
                        this.authActions.updateShowSuspendStatus(false);
                        return new Promise<boolean>((resolve) => {
                            resolve(accepted);
                        });
                    }
                };
               this.alertActions.show(alert);
            }
        });

        this.initStripe();
    }

    initStripe() {

        if (!this.stripeScriptTag.StripeInstance) {

            const stripePubKey = this.configService.getConfiguration().stripePubKey;

            this.stripeScriptTag.setPublishableKey(stripePubKey);

          }
    }

    riderectUserToWWW() {
        const a =  window.location;
        if (a.hostname === 'tuyanow.com') {
            if (a.hostname.substring(0, 3) !== 'www') {
                a.hostname = `www.${a.hostname}`;
            }
        }
    }

    logout() {
        this.router.navigate(['/logout']);
    }

    onDeleteNotification(data: NotificationDto) {
        this.notificationActions.remove(data);
    }

    onOpenOrderDetails(orderId: any) {
        this.uIActions.goToOrderDetails({orderId});
    }

    // do i hide the header?
    isHideHeader() {
        const route = this.ngRedux.getState().router;
        return route.indexOf('driver/home') !== -1 ||
            route.indexOf('driver/resetPassword') !== -1 || route.indexOf('mobile/home') !== -1 ||
            route.indexOf('admin-search') !== -1 || route.indexOf('driver-portal') !== -1 || route.indexOf('tracking') !== -1 ||
            route.indexOf('login') !== -1 || route.indexOf('error') !== -1;
    }
}
