import { Alert, AlertType } from '../../../core/models/alert.model';
import { AlertSelectors } from '../../../core/selectors/alert.selectors';
import {
    AfterContentInit, ChangeDetectorRef, Component, Input, OnDestroy
} from '@angular/core';
import { NgRedux, select } from '@angular-redux-ivy/store';
import { catchError, map, Observable, of, Subscription, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Stop, StopType } from '../../models/order.model';
import { IAppState } from '../../../store/model';
import { ShipmentActions } from '../../actions/shipment.actions';
import { AutocompleteItem } from '../../../core/models/autocomplete-item.model';
import { getAddressAutocompleteItems } from '../../selectors/shipment.selectors';
import { AddressAutocompleteActions } from '../../actions/address-autocomplete.actions';
import { AlertActions } from '../../../core/actions/alert.actions';
import { ShipmentService } from '../../services/shipment.service';

@Component({
    selector: 'tuya-address-autocomplete',
    templateUrl: 'address-autocomplete.container.html',
    styleUrls: ['./address-autocomplete.container.scss']
})
export class AddressAutocompleteContainerComponent implements AfterContentInit, OnDestroy {
    @Input() stopType: StopType;
    @Input() isFirstStop = false;
    @Input() isOptionalStop = false;

    @Input()
    set selectedAddress(addr: string) {
        this._selectedAddress.next(addr);
    }

    get selectedAddress() {
        return this._selectedAddress.getValue();
    }

    @Input() isControlDisabled = false;
    @Input() stopId = null;
    @Input() selectedStopId = null;
    @Input() stopSequenceNumber = 0;
    @Input() className = '';
    @Input() stopTypeId: number;


    public addressAutocompletFoundItems$: Observable<AutocompleteItem[]>;
    public addressAutoCompleteIsLoading: boolean;
    /**
     * We can use alert selectors here
     */
    @select(AlertSelectors.alertSelector)
    alert$: Observable<Alert>;

    public AlertType: typeof AlertType = AlertType;
    public placeholder = 'tuya-shipment.address-input-placeholder';
    public focusAndClear = false;
    public isInvalid = false;

    private _selectedAddress = new BehaviorSubject<string>('');
    private _subscription: Subscription;
    private SEARCH_START_LENGTH = 3;
    private itemsRes = [];

    constructor(private ngRedux: NgRedux<IAppState>,
                private actions: AddressAutocompleteActions,
                private shipmentActions: ShipmentActions,
                private alertActions: AlertActions,
                private shipmentService: ShipmentService,
                private cd: ChangeDetectorRef) {
    }

    ngAfterContentInit() {
        this._subscription = this.alert$.subscribe(alert => {
            if (alert) {
                if (!this.selectedStopId || this.selectedStopId === this.stopId) {
                    this.isInvalid = false;
                    this.focusAndClear = true;
                }
                this.cd.detectChanges();
            }
        });
    }

    /*
    * @param searchTerm
    * @description we need to use service in the smart component, because we are using the component in multiple places in the one page.
    * Therefore, this avoid to update several smart components after update one.
    */
    onRequestSearchResults(searchTerm: string) {
        this.focusAndClear = false;
        if (this.isFirstStop) {
            this.shipmentActions.removeFirstStop();
        }
        if (searchTerm && searchTerm.length >= this.SEARCH_START_LENGTH) {
            this.addressAutoCompleteIsLoading = true;
            this.addressAutocompletFoundItems$ = this.shipmentService.getNameAddressList(searchTerm, true).pipe(
                map((data: Stop[]) => this.filterAddressList(data))
                ,map(data => this._getNameAddressListSuccess(data))
                ,catchError(err => {
                    // send error to modal on shipment initial/builder page
                    this.alertActions.show({error: err, type: AlertType.BadRequest});
                    return throwError(err);
                }));
        }
    }

    onSelectStopItem(stopItemIndex: number) {
        this.actions.selectAutocompleteItem(this.itemsRes[stopItemIndex], {
            stopId: this.stopId,
            firstStop: this.stopSequenceNumber === 1,
            stopType: this.stopType
        });
    }

    onClearSearchResults() {
        if (this._selectedAddress.getValue() === '') {
            return;
        }
        this.addressAutocompletFoundItems$ = of([]);
    }

    onClearSearchInput() {
        // clear current row's input
        if (!this.selectedStopId || this.selectedStopId === this.stopId) {
            this.alertActions.hide();
            this.isInvalid = false;
            this.focusAndClear = true;
        }
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    private _getNameAddressListSuccess(data: Stop[]): AutocompleteItem[] {
        if (!data.length) {
            // remove stop address
            if (this.stopId) {
                this.shipmentActions.removeStopAddress(this.stopId);
            }
        }
        this.itemsRes = data;
        this.addressAutoCompleteIsLoading = false;
        return getAddressAutocompleteItems(data, this.ngRedux.getState().core.states);
    }

    private filterAddressList (data: Stop[]) {
        return data.reduce((p, c) => {
            const index = p.findIndex(e =>
                (e.address.addressLine === c.address.addressLine)
                &&
                (e.nameOrDescription === c.nameOrDescription)
                &&
                (e.suiteNumber === c.country)
                &&
                (e.suiteNumber === c.stateId)
                &&
                (e.suiteNumber === c.suiteNumber)
                &&
                (e.suiteNumber === c.city));

            if (index < 0) {  return p.concat(c); }
            if (new Date(c.pickupNoEarlierThan).getTime() > new Date(p[index].pickupNoEarlierThan).getTime()) {
                p[index] = c;
            }
            return p;
        }, []);
    }

}
