import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';
import { OrderVo } from '../../shipment/models/order.model';
export const EDIT_ORDER_ACTION = '[EDIT] Edit Order';
export const EDIT_ORDER_ACTION_SUCCEED = '[EDIT] Edit Order Succeed';
export const EDIT_ORDER_ACTION_FAILED = '[EDIT] Edit Order Failed';
export const EDIT_DISCARD_ORDER_ACTION = '[EDIT] Edit Discard Order';
export const EDIT_DISCARD_ORDER_CHANGES_SUCCEED = '[Edit] Discard order changes Succeed';
export const EDIT_DISCARD_ORDER_CHANGES_FAILURE = '[Edit] Discard order changes failed';

export type ShipmentEditAction = Action<any, any>;

@Injectable()
export class ShipmentEditActions {
    @dispatch()
    editOrder = (orderId: any): ShipmentEditAction => ({
        type: EDIT_ORDER_ACTION,
        payload: orderId
    })

    @dispatch()
    editDiscardOrder = (orderId: any): ShipmentEditAction => ({
        type: EDIT_DISCARD_ORDER_ACTION,
        payload: orderId
    })
    editDiscardChangesSuccess = (): ShipmentEditAction => ({
        type: EDIT_DISCARD_ORDER_CHANGES_SUCCEED,
        payload: null
    })

    editDiscardChangesFail = (error: any): ShipmentEditAction => ({
        type: EDIT_DISCARD_ORDER_CHANGES_FAILURE,
        payload: null,
        error
    })

    editOrderSucceeded = (orderVo: OrderVo): ShipmentEditAction => ({
        type: EDIT_ORDER_ACTION_SUCCEED,
        payload: orderVo
    })

    editOrderFailed = (error: any): ShipmentEditAction => ({
        type: EDIT_ORDER_ACTION_FAILED,
        payload: null,
        error
    })




}
