import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';
import { OrderItemDto } from '../../core/models/order.dto';

export type DeliveryStopAction = Action<any, any>;

export const OPEN_NEW_DELIVERY_STOP = '[DELIVRY_STOP] Create';
export const OPEN_NEW_DELIVERY_STOP_SUCCESS = '[DELIVRY_STOP] Open new';
export const SAVE_DELIVERY_STOP_ITEMS = '[DELIVRY_STOP] Add items';

@Injectable()
export class DeliveryStopActions {
    @dispatch()
    openNew = (): DeliveryStopAction => ({
        type: OPEN_NEW_DELIVERY_STOP,
        payload: null
    })

    @dispatch()
    openNewSuccess = (): DeliveryStopAction => ({
        type: OPEN_NEW_DELIVERY_STOP_SUCCESS,
        payload: null
    })

    @dispatch()
    saveDeliveryStopItems = (items: [OrderItemDto]): DeliveryStopAction => ({
        type: SAVE_DELIVERY_STOP_ITEMS,
        payload: items
    })
}
