import {
    ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, OnInit, OnDestroy, ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { StopDtoType, AddressDto } from '../../../core/models/dto';
import { AutocompleteItem } from '../../../core/models/autocomplete-item.model';
import { } from 'google.maps';

import { BuilderActions } from 'app/builder/actions/builder.actions';
import { NgRedux, select } from '@angular-redux-ivy/store';
import { IAppState } from 'app/store/model';
import { Observable, Subscription } from 'rxjs';
import { getInitialAddressState } from 'app/builder/models/address.model';

export type isFormControlDisabled = 'true' | 'false';

@Component({
    selector: 'tuya-simple-autocomplete',
    templateUrl: 'simple-autocomplete.component.html',
    styleUrls: ['simple-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleAutocompleteComponent implements OnInit, OnDestroy {
    @select(['builder', 'order', 'stops'])
    readonly builderStops$: Observable<any[]>;
    @select(['builder', 'ui', 'accordion'])
    readonly currentStep$: Observable<number>;

    StopDtoType: typeof StopDtoType = StopDtoType;
    @ViewChild('searchText', {static: true}) searchText: any;

    public element: ElementRef;
    @Input() isRequired = false;
    @Input() placeholder = '';
    @Input() className = '';
    @Input() isInvalid = false;
    @Input() isLoading = false;
    @Input() isInline = false;
    @Input() initialValue = '';

    @Input() set focusAndClear(isFocused) {
        // for invalid address clear input and set focus to it
        if (isFocused) {
            this.onUpdateInputValue('');
            this.element.nativeElement.querySelector('.address-input').focus();
        }
    }

    @Input() set disable(va: isFormControlDisabled) {
        va ? this.searchControlTerm.disable() : this.searchControlTerm.enable();
    }

    @Input() set searchResults(searchResults: AutocompleteItem[]) {
        this._searchResults = searchResults || [];
        if (searchResults !== null && searchResults.length === 0 && this.searchControlTerm.value.length > 0) {
            this._searchResults = [ { 'empty': true } ];
        }
        this.showPopup = !!this._searchResults.length;
    }

    get searchResults() {
        return this._searchResults;
    }

    @Output() loadSearchResults = new EventEmitter<AddressDto>();
    @Output() clearSearchResults = new EventEmitter();
    @Output() selectValue = new EventEmitter<number>();
    @Output() keyDown = new EventEmitter<string>();
    @Output() emitFocusIn = new EventEmitter();
    @Output() emitFocusOut = new EventEmitter<boolean>();


    showPopup = false;
    chooseError = false;
    searchControlTerm: UntypedFormControl = new UntypedFormControl('');
    builderStops;
    currentStep: number;

    prevVal = '';
    private _searchResults = [];
    private _subscription: Subscription;
    private focusedElement: AutocompleteItem;

    constructor(element: ElementRef,
                private builderActions: BuilderActions,
                private ngRedux: NgRedux<IAppState>) {
        this.element = element;
    }

    ngOnInit() {
        this.onUpdateInputValue(this.initialValue);
        this.builderStops$.subscribe(res => {
            this.emitFocusOut.emit(false);
            return this.builderStops = res;
        });
        this.currentStep$.subscribe(res => this.currentStep = res['currentStep']);
        this._subscription = this.searchControlTerm.valueChanges
        .subscribe(searchControlTermString => {
            this.prevVal = searchControlTermString;
            if (searchControlTermString.trim() === '') {
                this.clearAddress();
            }
            if (this.searchText.nativeElement.value) {
                this.getPlaceAutocomplete();
            }
        });
    }

    getPlaceAutocomplete(): any {
        const autocomplete = new google.maps.places.Autocomplete(
            this.searchText.nativeElement, {componentRestrictions: { country: 'US' }}
        );
        google.maps.event.addListener(autocomplete, 'place_changed', (res) => {
            const place = autocomplete.getPlace();
            if (!place.adr_address) {
                return false;
            }

            const response = this.addressGenerator(place);
            if (response.postalCode === '' || !response.postalCode) {
                this.searchText.nativeElement.focus();
                this.searchText.nativeElement.value = '';
                this.emitFocusOut.emit(true);
                return false;
            } else {
                if (this.prevVal !== '') {
                    this.builderActions.checkAddressInMsa(response, this.ngRedux.getState().builder.ui.accordion.currentStep);
                    this.searchControlTerm.patchValue(place.formatted_address, {emitEvent: false});
                    this.onSelectValue(response.name || '');
                    this.prevVal = '';
                }
            }
            return response;
        });
    }

    addressGenerator(place: any) {
        const htmlWithClass = this.getTextContentOf(place.adr_address);
        return {
            'addressLine': place.formatted_address,
            'city': htmlWithClass('locality'),
            'country': null,
            'countryShortName': null,
            'id': 0,
            'latitude': place.geometry.location.lat(),
            'longitude': place.geometry.location.lng(),
            'name': place.name,
            'postalCode': htmlWithClass('postal-code').split('-')[0],
            'stateAbbr': htmlWithClass('region'),
            'stateId': null,
            'stateName': '',
            'suiteNumber': null
        };
    }

    clearAddress() {
        const newStop = { ...this.builderStops[this.currentStep] };
        newStop.nameOrDescription = null;
        newStop.address = getInitialAddressState();
        this.builderActions.onAddressSelect(newStop, this.currentStep);
    }

    getTextContentOf(addressHtml: string) {
        const el = document.createElement( 'html' );
        el.innerHTML = addressHtml;
        return function(className: string) {
            const elem = el.getElementsByClassName(className)[0];
            return elem ? elem.textContent : '';
        };
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    onUpdateInputValue(initialValue: string) {
        this.searchControlTerm.patchValue(initialValue, {emitEvent: false});
    }

    onFocusIn() {
        this.emitFocusIn.emit();
    }

    onFocusOut() {
        const val = this.searchControlTerm.value || '';
        const noSearchResults = !val.trim() || val.trim() === '';
        this.emitFocusOut.emit(noSearchResults);
    }

    isItemFocused(item: AutocompleteItem) {
        return this.focusedElement && item.value === this.focusedElement.value;
    }

    public keydownEvent(e: any, isUpMode: boolean = false): void {
        this.keyDown.emit(e);
    }

    onSelectValue(name) {
        this.selectValue.emit(name);
    }

    clearState() {
        this.focusedElement = null;
        this.showPopup = false;
    }

    offClick() {
        if (this.showPopup) {
            this.clearState();
            this.chooseError = true;
        }
    }
}
