import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../models/redux.action';
import { NotificationBucketDto, NotificationDto } from '../models/notification.model';
import { LoadNotificationsParamsDto } from '../models/dto';

export type NotificationAction = Action<any, any>;

export const ADD_NOTIFICATION = '[NOTIFICATION] Add';
export const REMOVE_NOTIFICATION = '[NOTIFICATION] Remove';
export const NOTIFICATION_SUBSCRIBE = '[NOTIFICATION] Subscribe';
export const NOTIFICATION_SUBSCRIBE_SUCCESS = '[NOTIFICATION] Subscribe success';
export const LOAD_NOTIFICATION_BUCKET = '[NOTIFICATION] Load notification bucket';
export const SAVE_NOTIFICATION_BUCKET = '[NOTIFICATION] Save notification bucket to State';
export const CLEAR_NOTIFICATION_BUCKET = '[NOTIFICATION] Clear notification bucket';
export const SHOW_ERROR_MESSAGE = '[NOTIFICATION] Show notification error message';

@Injectable()
export class NotificationActions {
    @dispatch()
    add = (notification: NotificationDto): NotificationAction => ({
        type: ADD_NOTIFICATION,
        payload: notification
    })
    @dispatch()
    remove = (notification: NotificationDto): NotificationAction => ({
        type: REMOVE_NOTIFICATION,
        payload: notification
    })
    @dispatch()
    subscribeNotifications = (): NotificationAction => ({
        type: NOTIFICATION_SUBSCRIBE,
        payload: null
    })
    @dispatch()
    loadNotificationBucket = (searchPaginationParams: LoadNotificationsParamsDto): NotificationAction => ({
        type: LOAD_NOTIFICATION_BUCKET,
        payload: searchPaginationParams
    })
    saveNotificationBucket = (notificationBucket: NotificationBucketDto): NotificationAction => ({
        type: SAVE_NOTIFICATION_BUCKET,
        payload: notificationBucket
    })

    @dispatch()
    clearNotificationBucket = (): NotificationAction => ({
        type: CLEAR_NOTIFICATION_BUCKET,
        payload: null
    })
    @dispatch()
    showErrorMessage = (errorMessageKey: string): NotificationAction => ({
        type: SHOW_ERROR_MESSAGE,
        payload: errorMessageKey
    })
}