import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MyOrdersActions } from 'app/home/actions/my-orders.actions';
import { select } from '@angular-redux-ivy/store';
import { Observable, takeUntil } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConfirmPopup, ConfirmPopupType } from 'app/shared/models/confirm-alert.model';
import { ConfirmPopupComponent } from 'app/shared/components/confirm-popup/confirm-popup.component';

export interface RecurringDetail {
  id: number;
  orderId?: number;
  dayFrequency: number[];  // dayOfWeekRepeatance 0 - 6
  dateFrequency: number; // monthDay 1 - ~31
  recurringPeriod: number; // recurringPeriod 1 - 2
  start: string; // startOccur DATE
  endTime: string; // endOccur
  periodRepeatance: number; // weekQty 1-4, monthRepeatance 1 - 12
  dayOfWeekRepeatance: number; // dayOfWeekRepeatance 1 - 2
  occurences: number; // occurQty = 0 No end time, 0 > occurQty (Ends After ...)
  isActive: boolean; // create reccurence TRUE, delete FALSE
}

@Component({
  selector: 'tuya-recurring-modal',
  templateUrl: './recurring-modal.component.html',
  styleUrls: ['./recurring-modal.component.scss']
})

export class RecurringModalComponent implements OnInit, OnDestroy {

  @select(['home', 'myOrders', 'ordersTable', 'isLoading'])
  isLoading$: Observable<boolean>;

  form: UntypedFormGroup;
  recurringForm: UntypedFormGroup;

  weekDays = [
    { id: 1, name: 'Monday', selected: false },
    { id: 2, name: 'Tuesday', selected: false },
    { id: 3, name: 'Wednesday', selected: false },
    { id: 4, name: 'Thursday', selected: false },
    { id: 5, name: 'Friday', selected: false },
    { id: 6, name: 'Saturday', selected: false },
    { id: 0, name: 'Sunday', selected: false }
  ];

  userDefaults = {
    week: {
      endDate: 6,
      occurence: 25,
      periodRepeatanceWeek: 1
    },
    month: {
      endDate: 12,
      occurence: 13,
    }
  };

  minDateWS$ = new BehaviorSubject<string>(new Date().toISOString());
  minDateWE$ = new BehaviorSubject<string>(new Date().toISOString());
  minDateWED$ = new BehaviorSubject<string>(new Date().toISOString());

  minDateMS$ = new BehaviorSubject<string>(new Date().toISOString());
  minDateME$ = new BehaviorSubject<string>(new Date().toISOString());
  minDateMED$ = new BehaviorSubject<string>(new Date().toISOString());

  //dateMask = [/\d/];
  //monthMask = [/\d/, /\d/];

  todaysDay = new Date().getDay();

  endActiveDateWeekly = 0;
  endActiveDateMonthly = 0;
  repeatTypeMonth = 0;

  deletePopupIsActive = false;

  unsubscriber$ = new Subject();

  constructor(private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<RecurringModalComponent>,
    private myOrdersActions: MyOrdersActions,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public modalData: RecurringDetail) {
  }

  ngOnInit() {
    if (this.modalData.id && this.modalData.recurringPeriod === 0) {
      this.modalData.dayFrequency.forEach(dayIndex => {
        this.weekDays[dayIndex === 0 ? 6 : dayIndex - 1].selected = true;
      });
    }

    let loader = false;
    this.isLoading$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      if (loader && !res && !this.deletePopupIsActive) {
        this.onCloseModal(true);
      }
      if (res) {
        loader = res;
      }
    });

    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscriber$.next(true);
    this.unsubscriber$.unsubscribe();
  }

  prepareToSubmit() {
    const data: RecurringDetail = {
      id: this.modalData.id,
      orderId: null,
      dayFrequency: [0],
      dateFrequency: null,
      recurringPeriod: null,
      start: '',
      endTime: null,
      periodRepeatance: null,
      dayOfWeekRepeatance: null,
      occurences: null,
      isActive: true
    };

    data.orderId = this.modalData.orderId;
    data.recurringPeriod = parseInt(this.recurringForm.controls.recurringPeriod.value, 10);

    const monthlyType = parseInt(this.recurringForm.controls.monthOccurence.value.monthlyRepeatTypeMonth, 10);
    this.repeatTypeMonth = monthlyType;

    if (this.recurringForm.controls.recurringPeriod.value === '0') {
      data.periodRepeatance = parseInt(this.recurringForm.controls.weekOccurence.value.periodRepeatanceWeek, 10);
      data.dayFrequency = this.getSelectedDays();
      data.start = this.recurringForm.controls.weekOccurence.value.startWeek;

      switch (this.recurringForm.controls.weekOccurence.value.endDateTypeWeek) {
        case '0':
          data.endTime = this.recurringForm.controls.weekOccurence.value.endTimeWeek;
          break;
        case '1':
          data.occurences = parseInt(this.recurringForm.controls.weekOccurence.value.occurencesWeek, 10);
          break;
        case '2':
          data.occurences = null;
          data.endTime = null;
          break;
        default:
          break;
      }
    } else {
      data.start = this.recurringForm.controls.monthOccurence.value.startMonth;
      if (monthlyType === 0) {
        data.dateFrequency = parseInt(this.recurringForm.controls.monthOccurence.value.dateFrequencyMonth, 10);
        data.periodRepeatance = parseInt(this.recurringForm.controls.monthOccurence.value.periodRepeatanceMonthA, 10);
      } else {
        data.dayOfWeekRepeatance = parseInt(this.recurringForm.controls.monthOccurence.value.dayOfWeekRepeatanceMonth, 10);
        data.periodRepeatance = parseInt(this.recurringForm.controls.monthOccurence.value.periodRepeatanceMonthB, 10);
        data.dayFrequency[0] = parseInt(this.recurringForm.controls.monthOccurence.value.dayFrequencyMonth, 10);
      }

      switch (this.recurringForm.controls.monthOccurence.value.endDateTypeMonth) {
        case '0':
          data.endTime = this.recurringForm.controls.monthOccurence.value.endTimeMonth;
          break;
        case '1':
          data.occurences = parseInt(this.recurringForm.controls.monthOccurence.value.occurencesMonth, 10);
          break;
        case '2':
          data.occurences = null;
          data.endTime = null;
          break;
        default:
          break;
      }
    }

    return data;
  }

  initForm() {
    const recurrType = this.modalData.id ? this.modalData.recurringPeriod : 0;
    const monthlyType = this.modalData.id && recurrType === 1 && this.modalData.dateFrequency === 0 ? '1' : '0';

    this.recurringForm = this.fb.group({
      recurringPeriod: [this.modalData.id ? this.modalData.recurringPeriod.toString() : '0'],
      weekOccurence: this.fb.group({
        periodRepeatanceWeek: [(this.modalData.id && recurrType === 0) ?
          this.modalData.periodRepeatance : this.userDefaults.week.periodRepeatanceWeek,
        [Validators.min(1), Validators.max(5)]],
        dayOfWeekRepeatanceWeek: this.buildDays(),
        endDateTypeWeek: [(this.modalData.id && recurrType === 0) ? (typeof (this.modalData.endTime) === 'string' ? '0' :
          !this.modalData.occurences ? '2' : '1') : '0'],
        startWeek: [(this.modalData.id && recurrType === 0) ? this.modalData.start : new Date().toISOString()],
        endTimeWeek: [(this.modalData.id && recurrType === 0) ?
          (!this.modalData.endTime ? new Date().toISOString() :
            this.modalData.endTime) : new Date().toISOString()],
        occurencesWeek: [(this.modalData.id && recurrType === 0 && this.modalData.occurences !== 0) ?
          this.modalData.occurences : this.userDefaults.week.occurence,
        [Validators.min(1), Validators.max(999)]]
      }),
      monthOccurence: this.fb.group({
        monthlyRepeatTypeMonth: [(this.modalData.id && this.modalData.dateFrequency === 0) ? '1' : '0'],
        dateFrequencyMonth: [(this.modalData.id && recurrType === 1 && this.modalData.dateFrequency !== 0) ?
          this.modalData.dateFrequency : 1,
        [Validators.min(1), Validators.max(31)]],
        dayOfWeekRepeatanceMonth: [(this.modalData.id && recurrType === 1) ?
          (this.modalData.dayOfWeekRepeatance === 0 ? this.getWeekOfMonth(new Date()).toString() :
            this.modalData.dayOfWeekRepeatance.toString()) :
          this.getWeekOfMonth(new Date()).toString()],
        dayFrequencyMonth: [(this.modalData.id && recurrType === 1) ?
          this.modalData.dayFrequency[0].toString() : this.todaysDay.toString()],
        periodRepeatanceMonthA: [(this.modalData.id && recurrType === 1 && monthlyType === '0') ? this.modalData.periodRepeatance : 1,
        [Validators.min(1), Validators.max(99)]],
        periodRepeatanceMonthB: [(this.modalData.id && recurrType === 1 && monthlyType === '1') ? this.modalData.periodRepeatance : 1,
        [Validators.min(1), Validators.max(99)]],
        endDateTypeMonth: [(this.modalData.id && recurrType === 1) ?
          (typeof (this.modalData.endTime) === 'string' ? '0' : !this.modalData.occurences ? '2' : '1')
          : '0'],
        startMonth: [(this.modalData.id && recurrType === 1) ? this.modalData.start : new Date().toISOString()],
        endTimeMonth: [(this.modalData.id && recurrType === 1) ?
          (!this.modalData.endTime ? new Date().toISOString() : this.modalData.endTime) :
          new Date().toISOString()],
        occurencesMonth: [(this.modalData.id && recurrType === 1 && this.modalData.occurences !== 0) ?
          this.modalData.occurences : this.userDefaults.month.occurence,
        [Validators.min(1), Validators.max(999)]]
      })
    });

    this.minDateWE$.next(this.recurringForm.get('weekOccurence')['controls'].startWeek.value);
    const monthW = new Date(this.recurringForm.get('weekOccurence')['controls'].startWeek.value).getMonth();
    const newDateW = new Date(new Date(this.recurringForm.get('weekOccurence')['controls'].startWeek.value)
      .setMonth(monthW + this.userDefaults.week.endDate));
    let wd = '';
    if (this.modalData.id && recurrType === 0) {
      wd = this.modalData.endTime ? this.modalData.endTime : newDateW.toISOString();
    } else {
      wd = newDateW.toISOString();
    }

    this.minDateWED$.next(wd);
    this.recurringForm.get('weekOccurence')['controls'].endTimeWeek.setValue(wd);

    this.minDateME$.next(this.recurringForm.get('monthOccurence')['controls'].startMonth.value);
    const month = new Date(this.recurringForm.get('monthOccurence')['controls'].startMonth.value).getMonth();
    const newDate = new Date(new Date(this.recurringForm.get('monthOccurence')['controls'].startMonth.value)
      .setMonth(month + this.userDefaults.month.endDate));
    let d = '';
    if (this.modalData.id && recurrType === 1) {
      d = this.modalData.endTime ? this.modalData.endTime : newDate.toISOString();
    } else {
      d = newDate.toISOString();
    }

    this.minDateMED$.next(newDate.toISOString());
    this.recurringForm.get('monthOccurence')['controls'].endTimeMonth.setValue(d);

    this.recurringForm.valueChanges.subscribe((res) => {
      if (res.recurringPeriod === '0') {
        if (res.weekOccurence.periodRepeatanceWeek === '') {
          this.recurringForm.setErrors({ 'time': false });
        }

        if (res.weekOccurence.endDateTypeWeek === '1' && res.weekOccurence.occurencesWeek === '') {
          this.recurringForm.setErrors({ 'time': false });
        }
        if (res.weekOccurence.endDateTypeWeek) {
          this.endActiveDateWeekly = parseInt(res.weekOccurence.endDateTypeWeek, 0);
        }

        setTimeout(() => {
          const days = this.getSelectedDays();
          if (days.length === 0 && !this.recurringForm.getError('day')) {
            this.recurringForm.setErrors({ 'day': true });
          }
        });

      } else {
        if (res.monthOccurence.monthlyRepeatTypeMonth === '0' &&
          (res.monthOccurence.dateFrequencyMonth === '' || res.monthOccurence.periodRepeatanceMonthA === '')) {
          this.recurringForm.setErrors({ 'time': false });
        }

        if (res.monthOccurence.monthlyRepeatTypeMonth === '1' && res.monthOccurence.periodRepeatanceMonthB === '') {
          this.recurringForm.setErrors({ 'time': false });
        }

        if (res.monthOccurence.endDateTypeMonth === '1' && res.monthOccurence.occurencesMonth === '') {
          this.recurringForm.setErrors({ 'time': false });
        }

        if (res.monthOccurence.monthlyRepeatTypeMonth) {
          this.repeatTypeMonth = parseInt(res.monthOccurence.monthlyRepeatTypeMonth, 0);
        }

        if (res.monthOccurence.endDateTypeMonth) {
          this.endActiveDateMonthly = parseInt(res.monthOccurence.endDateTypeMonth, 0);
        }
      }
    });

    if (recurrType === 0 && !this.modalData.id) {
      setTimeout(() => {
        const days = this.getSelectedDays();
        if (days.length === 0) {
          this.recurringForm.setErrors({ 'day': true });
        }
      });
    }
  }

  getWeekOfMonth(date) {
    const adjustedDate = date.getDate() + date.getDay();
    const prefixes = ['0', '1', '2', '3', '4', '5'];
    return (parseInt(prefixes[0 | adjustedDate / 7], 10) + 1);
  }

  onWeekDayChange() {
    const days = this.getSelectedDays();
    if (days.length === 0) {
      this.recurringForm.setErrors({ 'day': true });
    } else if (this.recurringForm.errors && this.recurringForm.errors.day) {
      this.recurringForm.setErrors(null);
    }
  }

  get days() {
    return this.recurringForm.controls.weekOccurence.get('dayOfWeekRepeatanceWeek');
  }

  buildDays() {
    const arr = this.weekDays.map(day => {
      return this.fb.control(day.selected);
    });
    return this.fb.control(arr);
  }

  getSelectedDays() {
    const selectedDays: number[] = [];
    this.recurringForm.controls.weekOccurence.value.dayOfWeekRepeatanceWeek.forEach((day, i) => {
      if (day.value) {
        const d = i === 6 ? 0 : i + 1;
        selectedDays.push(d);
      }
    });
    return selectedDays;
  }

  onCloseModal(data) {
    this.dialogRef.close(data);
  }

  deleteRecurrence() {
    this.deletePopupIsActive = true;
    this.openDeleteConfirmPopup();
  }

  openDeleteConfirmPopup() {

    const alertData: ConfirmPopup = {
      type: ConfirmPopupType.confirm,
      text: 'Are you sure you want to delete this recurring order?',
      buttons: {
        accept: 'Yes',
        cancel: 'Do Not Delete'
      }
    };

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      data: alertData,
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close('delete');
        this.myOrdersActions.deleteRecurringDetail(this.modalData);
      } else {
        this.deletePopupIsActive = false;
      }
    });
  }

  submit() {
    const isEditMode = this.modalData.id ? true : false;
    this.myOrdersActions.createRecurringOrder({ data: this.prepareToSubmit(), isEditMode });
  }

  onDateChange(ev, recurrType: string, startOrEnd: string) {
    switch (recurrType) {
      case 'week':
        if (startOrEnd === 'start') {
          this.recurringForm.get('weekOccurence')['controls'].startWeek.setValue(ev);
          const startDay = ev;
          const endDay = this.recurringForm.get('weekOccurence')['controls'].endTimeWeek.value;
          this.minDateWE$.next(ev);
          if (startDay > endDay) {
            this.minDateWED$.next(ev);
          }
        } else {
          this.recurringForm.get('weekOccurence')['controls'].endTimeWeek.setValue(ev);
        }
        break;
      case 'month':
        if (startOrEnd === 'start') {
          this.recurringForm.get('monthOccurence')['controls'].startMonth.setValue(ev);
          this.minDateME$.next(ev);
          const startDay = ev;
          const endDay = this.recurringForm.get('monthOccurence')['controls'].endTimeMonth.value;
          if (startDay > endDay) {
            this.minDateMED$.next(ev);
          }
        } else {
          this.recurringForm.get('monthOccurence')['controls'].endTimeMonth.setValue(ev);
        }
        break;
      default:
        break;
    }
  }

  /*onDateChange(eventValue: any, recurrenceType: string, startOrEnd: string) {
    let occurrenceFormGroup;
    
    switch (recurrenceType) {
      case 'week':
        occurrenceFormGroup = this.recurringForm.get('weekOccurrence');
        break;
      case 'month':
        occurrenceFormGroup = this.recurringForm.get('monthOccurrence');
        break;
      default:
        return; // Exit if recurrenceType doesn't match
    }
  
    const startDateControlName = startOrEnd === 'start' ? 'start' + recurrenceType.charAt(0).toUpperCase() + recurrenceType.slice(1) : null;
    const endDateControlName = `endTime${recurrenceType.charAt(0).toUpperCase()}${recurrenceType.slice(1)}`;
  
    // Set the start or end date based on the startOrEnd argument
    if (startDateControlName) {
      occurrenceFormGroup.get(startDateControlName).setValue(eventValue);
      this.updateMinDate(eventValue, occurrenceFormGroup, endDateControlName, recurrenceType);
    } else {
      occurrenceFormGroup.get(endDateControlName).setValue(eventValue);
    }
  }

  updateMinDate(startDate: any, formGroup: AbstractControl, endDateControlName: string, recurrenceType: string) {
    const minDateSubject = recurrenceType === 'week' ? this.minDateWE$ : this.minDateME$;
    const minEndDateSubject = recurrenceType === 'week' ? this.minDateWED$ : this.minDateMED$;
  
    minDateSubject.next(startDate);
  
    const endDate = formGroup.get(endDateControlName).value;
    if (startDate > endDate) {
      minEndDateSubject.next(startDate);
    }
  }*/
}
