import { BackVersionService } from './../../services/back-version.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux-ivy/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../app.config.service';
import { NotificationDto } from '../../models/notification.model';
import { ShipperProfileDto } from '../../models/dto';
import { AlertActions } from '../../../core/actions/alert.actions';
import { AlertType } from '../../../core/models/alert.model';
import { BuilderActions } from '../../../builder/actions/builder.actions';
import { IAppState } from '../../../store/model';
import { SearchOrderActions } from 'app/home/actions/search-order.actions';
import { MyOrdersActions } from 'app/home/actions/my-orders.actions';
import { SortDirection } from 'app/home/model/my-orders.model';
import { HomeActions } from 'app/home/actions/home.actions';
import { ShipperStatuses } from 'app/shipper/model/model';
import { Observable } from 'rxjs';
import { LatLng } from 'app/core/models/map.model';
import { MapActions } from 'app/core/actions/map.actions';

// @TODO Clean this component for prod
const ENV_LOCAL = 'local';
const ENV_DEV = 'dev';
const ENV_QA = 'qa';
const ENV_UAT = 'uat';

@Component({
    selector: 'tuya-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

    @Input() loggedIn: boolean;
    @Input() adminProfile: ShipperProfileDto;
    @Input() notificationList: Array<NotificationDto> = [];
    @Input() shipperEntityLocation: LatLng;
    @Output() logout = new EventEmitter();

    @Input() shipperStatus: ShipperStatuses;
    @Input() currentRouter: string;
    @Input() showSuspendAlert: boolean;

    @select(['home', 'searchOrders', 'searchQuery'])
    readonly searchQuery$: Observable<string>;

    version: string;
    backVersion: string;
    showInHeader = false;
    isChanged = false;
    translator;
    searchQuery = '';

    constructor(private router: Router, public configService: ConfigService,
        private alertActions: AlertActions,
        private builderActions: BuilderActions,
        private translate: TranslateService,
        private mapActions: MapActions,
        private homeActions: HomeActions,
        private myOrdersActions: MyOrdersActions,
        private ngRedux: NgRedux<IAppState>,
        private searchOrderActions: SearchOrderActions,
        private backVersionService: BackVersionService) {
        this.translator = translate;
    }

    ngOnInit() {
        this.showInHeader = this.configService.getConfiguration().configuration &&
            (this.configService.getConfiguration().configuration.toLowerCase() === ENV_LOCAL ||
                this.configService.getConfiguration().configuration.toLowerCase() === ENV_DEV ||
                this.configService.getConfiguration().configuration.toLowerCase() === ENV_QA ||
                this.configService.getConfiguration().configuration.toLowerCase() === ENV_UAT);
        if (!this.showInHeader) return;            
        
        this.backVersionService.getBackVersion().subscribe(version => { this.backVersion = version.currentBuild; });
        this.version = this.configService.getConfiguration().buildNumber;
        if (this.version) {
            const lastIndex = this.version.includes('-') ? this.version.indexOf('-') + 1 : Math.max(this.version.length - 8, 0);
            this.version = this.version.slice(lastIndex);
        }
    }

    onClear() {
        const payload = {
            pageSize: 10,
            currentPageNumber: 1,
            sortField: 'CreationDateUtc',
            sortDirection: SortDirection.Desk,
            orderFilters: { 1: true, 2: true, 3: true, 4: true },
            hasExceptions: false
        };
        this.myOrdersActions.resetPagination();
        this.searchOrderActions.resetSearchFilter(true);
        this.searchOrderActions.resetOrderSearch();
        this.homeActions.loadPage(payload);
    }

    onSearch(ev: string) {
        this.myOrdersActions.resetPagination();
        this.searchOrderActions.updateProgressBar(0);
        this.searchOrderActions.searchForAnOrder(ev);
        this.searchOrderActions.updateSearchQuery(ev);
        this.searchOrderActions.closeFilter();
        this.homeActions.clearAllRowInfo();
    }

    showCreateButton() {
        return this.loggedIn &&
            this.shipperStatus.isEmailConfirmed &&
            (this.shipperStatus.hasValidCreditCard || this.shipperStatus.isUnderTerms);
    }

    showMyOrdersButton() {
        return this.shipperStatus &&
            this.shipperStatus.isEmailConfirmed &&
            this.shipperStatus.hasOrders;
    }

    showSearchBox() {
        return this.loggedIn &&
            this.shipperStatus.hasOrders &&
            this.currentRouter === '/orders';
    }

    onClickDropdown() {
        this.searchOrderActions.closeFilter();
    }

    createNewShipment() {
        if (this.router.url !== this.ngRedux.getState().router && this.router.url.indexOf('/builder') > -1) {
            // prompt the exit modal and reset the builder, do not navigate
            this.alertActions.show({
                type: AlertType.Cancellable,
                message: this.translator.instant('validation.notSaved'),
                callback: this.confirmationCallback
            });
        } else {
            if (this.shipperEntityLocation && this.shipperEntityLocation.lat) {
                this.mapActions.setCenter(this.shipperEntityLocation);
            }
            setTimeout(() => {
                this.router.navigate(['/builder']);
                this.builderActions.onBuilderInit({ useDefault: true });
            });
        }
    }

    confirmationCallback = (accepted: boolean) => {
        return new Promise<boolean>((res, rej) => {
            if (accepted) {
                this.builderActions.onBuilderInit({ useDefault: true });
            }
            res(accepted);
        });
    }

    onClickIcon() {
        this.isChanged = !this.isChanged;
        if (this.ngRedux.getState().home['searchOrders'].searchQuery !== '') {
            this.searchOrderActions.updateSearchQuery('');
        }

        const payload = {
            pageSize: 10,
            currentPageNumber: 1,
            sortField: 'CreationDateUtc',
            sortDirection: SortDirection.Desk,
            orderFilters: { 1: true, 2: true, 3: true, 4: true },
            hasExceptions: false
        };
        this.myOrdersActions.resetPagination();
        this.searchOrderActions.resetSearchFilter(true);
        this.homeActions.loadPage(payload);

        // call when there is no orders displayed
        /*if (this.ngRedux.getState().home?.myOrders?.ordersTable?.currentOrdersId.length === 0) {
        }*/
    }
}
