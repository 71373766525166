import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { select, select$ } from '@angular-redux-ivy/store';
import { Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { getAddress, getContinueNotAllowed, ShipmentSelectors } from '../../selectors/shipment.selectors';
import { ShipmentType } from '../../../core/models/shipment.model';
import { Stop, StopType } from '../../models/order.model';
import { ShipmentActions } from '../../actions/shipment.actions';
import { AddressDto, StateDto } from '../../../core/models/dto';
import { UIActions } from '../../actions/ui.actions';
import { WizardStep } from '../../models/ui.model';
import { OrderSelectors } from '../../selectors/order.selectors';
import { AlertActions } from '../../../core/actions/alert.actions';


@Component({
  selector: 'tuya-shipment-initial-page',
  templateUrl: './shipment-initial-page.component.html',
  styleUrls: ['./shipment-initial-page.component.scss']
})
export class ShipmentInitialPageComponent implements AfterViewInit, OnDestroy, OnInit {

    StopType: typeof StopType = StopType;
    WizardStep: typeof WizardStep = WizardStep;

    @select(['shipment', 'ui', 'isLoading'])
    readonly isLoading$: Observable<boolean>;
    @select(OrderSelectors.orderDeliveryTypeSelector)
    orderDeliveryType$: Observable<number>;
    // ToDO: remove after addressLine with zipcode will be fixed
    @select$(['auth', 'currentUser'], getAddress)
    physicalAddressDto$: Observable<AddressDto>;
    @select (['core', 'states'])
    statesList$: Observable<StateDto[]>;
    @select(ShipmentSelectors.wizardStepSelector)
    wizardStep$: Observable<number>;
    @select$(['shipment', 'order'], getContinueNotAllowed)
    continueNotAllowed$: Observable<boolean>;
    @select(OrderSelectors.firstStopSelector)
    firsStop$;


    wizardStep = new BehaviorSubject<number>(0);

    private fullAddressString: string;
    private orderDeliveryType = new BehaviorSubject<number>(0);
    private statesList = new BehaviorSubject<StateDto[]>([]);
    private _initFirstStopSubscription: Subscription;

    constructor(private shipmentActions: ShipmentActions,
                private uiActions: UIActions,
                private alertActions: AlertActions) {

        // Initial page should always reset shipment state.
        this.shipmentActions.reset();

        this.orderDeliveryType$.subscribe(this.orderDeliveryType);
        this.wizardStep$.subscribe(this.wizardStep);
        this.statesList$.subscribe(this.statesList);

        // Action bar steps validation
        this.continueNotAllowed$.subscribe(notAllowed => {
            this.uiActions.setWizardStepContinueAllowed(WizardStep.Initial, !notAllowed);
          });
    }

    ngOnInit() {
        // This clears out any existing alerts so they do not pop up as a modal
        this.alertActions.hide();
    }

    ngAfterViewInit() {
        this.uiActions.setDisableStateForRefreshOrder(false);
        // Use ngAfterViewInit to prevent services loading on page reload
        // Load address object if user's physicalAddress loaded
        this._initFirstStopSubscription = this.physicalAddressDto$.subscribe(address => {
            this.onInitFirstStop(address);
        });
    }

    /**
     * @description We have to unsubscribe, because we select auth store;
     */
    ngOnDestroy() {
        if (this._initFirstStopSubscription) {
            this._initFirstStopSubscription.unsubscribe();
        } else {
            this._initFirstStopSubscription = null;
        }
    }

    onInitFirstStop(address: AddressDto) {
        if (address.addressLine) {
            // ToDO: remove after addressLine with zipcode will be fixed
            const state = this.statesList.getValue().find((stateItem: StateDto) => stateItem.id === address.stateId);
            this.fullAddressString = `${address.addressLine} ${address.country || ''}${address.city || ''}`
            + ', ' + `${state.shortName || ''} ${address.postalCode || ''}`;
            // ToDo: ends
            this.shipmentActions.initFirstStop(this.fullAddressString);
        }
    }

    onShipmentTypeUpdate(t: ShipmentType) { this.shipmentActions.setNewShipmentType(t); }
    onPickupStopSuiteNumberSave(stop: Stop) {
        this.shipmentActions.stopSuiteNumberSave(stop.address.suiteNumber);
    }
}
