import { Component, Input, TemplateRef, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { get, filter } from 'lodash-es';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { PoolsActions } from '../../../core/actions/pools.actions';
import { DriversSelectors, combineShipperAndDrviverPools } from '../../../core/selectors/pools.selector';
import { OrderStatuses, OrderStatusName, AssignmentsDto, VehicleTypeDto } from '../../../core/models/order.dto';
import { OrderStatusId, PoolDto, DriverDto, CombinedPoolsDTO, DriverPoolDTO } from '../../../core/models/dto';

import { MyOrdersActions } from '../../actions/my-orders.actions';
import { ShipperAssessment } from '../../model/driver-info.model';
import { OrderExpandedView } from '../../model/my-orders.model';

import { OfferType } from '../../../builder/models/order.model';
import { UserProfile } from '../../../auth/model/model';
import { BuilderActions } from '../../../builder/actions/builder.actions';
import { ShipmentEditSelectors } from '../../../shipment-edit/selectors/shipment-edit.selectors';
import { DriverAssignmentDataDto } from '../../../builder/models/assignments.model';
import { isEditOrderDisabled } from '../../../shipment-edit/utils/order.utils';
import { ShipperAdminActions } from '../../../admin/actions/shipper.admin.actions';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'tuya-expanded-order-view',
    templateUrl: './expanded-order-view.page.component.html',
    styleUrls: ['./expanded-order-view.page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ExpandedOrderViewPageComponent implements OnInit, OnDestroy {
    @Input() isShipperSuspended = false;
    @Input() recurrenceOrderDetail = null;
    @Output() onEditRecurringOrder = new EventEmitter<{ recurringId: number, orderId: number }>();

    @select(ShipmentEditSelectors.IsLoadingSelector)
    isEditLoading$: Observable<boolean>;
    @select(['auth', 'currentUser'])
    readonly currentUser$: Observable<any>;
    @select(['home', 'myOrders', 'ordersDetails', 'isLoading'])
    readonly isLoading$: Observable<boolean>;
    @select(['core', 'pools', 'pools'])
    pools$: Observable<PoolDto[]>;
    @select(['core', 'pools', 'driversPools'])
    driverPools$: Observable<DriverPoolDTO>;
    @select(DriversSelectors.driversSelector)
    drivers$: Observable<DriverDto[]>;
    @select(['admin', 'shipperAdmin', 'currentReoffer', 'assignments'])
    assignments$: Observable<AssignmentsDto>;
    @select(['admin', 'shipperAdmin', 'currentReoffer', 'isExtendable'])
    isExtendable$: Observable<boolean>;
    @select(['admin', 'shipperAdmin', 'currentReoffer', 'vehicleType'])
    vehicleType$: Observable<VehicleTypeDto>;
    @select(['admin', 'shipperAdmin', 'selectedShipper'])
    selectedShipperID$: any;
    @select(['admin', 'shipperAdmin', 'currentReoffer', 'offerType'])
    offerType$: Observable<OfferType>;
    @select(['shipper', 'shipperStatus'])
    shipperStatus$: Observable<OfferType>;
    @select(DriversSelectors.isDriversLoadingSelector)
    isDriversLoading$: Observable<boolean>;
    @select(DriversSelectors.isPoolsLoading)
    isPoolsLoading$: Observable<boolean>;

    componentCollapsed = false;
    isAdmin = false;
    shipperId: any;
    shipperStatus: any;
    pools: Array<PoolDto>;
    drivers: Array<DriverDto>;
    assignments: AssignmentsDto;
    isExtendable: boolean;
    offerType: OfferType;
    combinedPools$ = new BehaviorSubject<CombinedPoolsDTO[]>([]);
    driverPools: Array<{ id: any; name: string; }>;

    @Input()
    set orderItem(orderItem) {
        this._orderItem = orderItem;
        if (this._orderItem.assignments.hasAssignedDrivers && !this.componentCollapsed) {
            const id = this._orderItem.assignments.drivers[0].userAccountId;
            this.poolsActions.driverPoolsLoad(id);
            this.componentCollapsed = true;
        }
    }

    get orderItem(): OrderExpandedView {
        return this._orderItem;
    }

    /* For page order details */
    @Input() orderDetailsMode = false;

    public combinePools: Array<CombinedPoolsDTO>;
    public orderStatuses = OrderStatuses;

    modalRef: BsModalRef;
    cancelComment = '';
    cancelModalConfirmDisabled = false;
    driverInfoBgColor = '';

    private driverAssignmentData: DriverAssignmentDataDto;
    private _orderItem: any = {};
    private modalConfig = {
        class: 'modal-sm',
        animated: false
    };
    private vehicleType: VehicleTypeDto;
    private unsubscriber = new Subject<void>();

    constructor(private poolsActions: PoolsActions,
        private ordersAction: MyOrdersActions,
        private modalService: BsModalService,
        private builderActions: BuilderActions,
        private shipperAdminActions: ShipperAdminActions,
        private router: Router,
        private cdr: ChangeDetectorRef) {
        if (this.getWindowPath() !== '/orders') {
            this.driverInfoBgColor = '#e7e7e7';
        }
    }

    ngOnInit() {
        this.subscribeToCurrentUser();
        this.subscribeToSelectedShipperID();
        this.subscribeToAssignments();
        this.subscribeToPools();
        this.subscribeToDriverPools();
        this.subscribeToDrivers();
        this.subscribeToIsExtendable();
        this.subscribeToOfferType();
        this.subscribeToVehicleType();
        this.subscribeToShipperStatus();
        this.subscribeToIsLoading();
    }

    private subscribeToIsExtendable(): void {
        this.isExtendable$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(isExtendable => this.isExtendable = isExtendable);
    }

    private subscribeToOfferType(): void {
        this.offerType$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(offerType => this.offerType = offerType);
    }

    private subscribeToVehicleType(): void {
        this.vehicleType$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(result => this.vehicleType = result);
    }

    private subscribeToShipperStatus(): void {
        this.shipperStatus$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(result => this.shipperStatus = result);
    }

    private subscribeToIsLoading(): void {
        this.isLoading$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(result => {
            setTimeout(() => {
                if (!result && this.getWindowPath() !== '/orders') {
                    this.driverInfoBgColor = 'rgba(34,94,172,0.5)';
                } else if (result && this.getWindowPath() !== '/orders') {
                    this.driverInfoBgColor = '#e7eaeb';
                }
                this.cdr.markForCheck();  // Mark the view to be checked in the next cycle
            });
        });
    }

    private subscribeToCurrentUser(): void {
        this.currentUser$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe((data: UserProfile) => {
            if (data) {
                this.isAdmin = get(data, 'adminProfile.isAdmin') ?? false;
            }
        });
    }

    private subscribeToSelectedShipperID(): void {
        this.selectedShipperID$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe((data: any) => {
            this.shipperId = get(data, 'userAccountId', null);
        });
    }

    private subscribeToDrivers(): void {
        this.drivers$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(drivers => this.handleDrivers(drivers));
    }

    private handleDrivers(drivers: any): void {
        this.drivers = drivers;
    }

    private subscribeToDriverPools(): void {
        this.driverPools$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(res => this.handleDriverPools(res));
    }

    private handleDriverPools(res: any): void {
        if (Array.isArray(res) && res[0]) {
            this.driverPools = res[0].pools;
            this.cobinePools(res[0].driveId);
        }
    }

    private subscribeToAssignments(): void {
        this.assignments$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(assignments => this.handleAssignments(assignments));
    }

    private handleAssignments(assignments: any): void {
        this.assignments = assignments;
    }

    private subscribeToPools(): void {
        this.pools$.pipe(
            takeUntil(this.unsubscriber)
        ).subscribe(pools => this.handlePools(pools));
    }

    private handlePools(pools: any): void {
        this.pools = pools;
    }

    ngOnDestroy() {
        this.unsubscriber.next();
        this.unsubscriber.unsubscribe();
    }

    onRecurringOrderEdit() {
        const data = { recurringId: this.recurrenceOrderDetail.recurrenceOrderId, orderId: this._orderItem.orderId };
        this.onEditRecurringOrder.emit(data);
    }

    cobinePools(driverId: number) {
        const combinedPools = combineShipperAndDrviverPools(this.pools, this.driverPools, driverId);
        this.combinedPools$.next(combinedPools);
    }

    public onCreatePool(value: string): void {
        this.poolsActions.createPool({
            name: value,
            driverId: this._orderItem.assignments.drivers[0].userAccountId
        });
    }

    onSendPools(pools: any[]): void {
        this.poolsActions.addDriverToPools({
            driverId: this._orderItem.assignments.drivers[0].userAccountId,
            orderId: this._orderItem.orderId,
            pools: pools
        });
    }

    onRemovePools(pools: any[]) {
        this.poolsActions.removeDriverFromPools({
            driverId: this._orderItem.assignments.drivers[0].userAccountId,
            orderId: this._orderItem.orderId,
            pools: pools
        });
    }

    public onUpdateRate({ rate, reasonId }: any): void {
        this.ordersAction.rateDriver(<ShipperAssessment>{
            driverId: this._orderItem.assignments.drivers[0].userAccountId,
            orderId: this._orderItem.orderId,
            reasonId,
            rate
        });
    }

    public cancelOrder() {
        this.cancelModalConfirmDisabled = true;
        const payload = {
            orderId: this._orderItem.orderId,
            comments: this.cancelComment || '',
            success: this.successCallback.bind(this),
            fail: this.failCallback.bind(this)
        };
        this.ordersAction.cancelOrder(payload);
    }

    // Edit Order (moved to Order Details)
    public editOrder() {
        if (!environment.production) console.debug('editOrderButtonClicked', this._orderItem.orderId);

        // DO NOT ALLOW Multistop Orders to be edited
        if (this.orderDetailsMode && this._orderItem.stopList.length <= 2)
            this.builderActions.onEditOrder(this._orderItem.orderId);
        else {
            // Redirect to Order Details
            this.router.navigate(['order-details',], { queryParams: { orderId: this._orderItem.orderId } });
            //if (this._orderItem.stopList.length > 2) alert('You cannot edit multi-stop orders.');
        }
    }

    getWindowPath() {
        return window.location.pathname;
    }

    onDriverOffer(driverAssignmentData) {
        this.driverAssignmentData = driverAssignmentData;
        this.shipperAdminActions.updateSelectedCandidates(driverAssignmentData.data);
        this.shipperAdminActions.updateOfferType(driverAssignmentData.type);
    }

    onSetExtendable(isExtendable: boolean) {
        this.isExtendable = isExtendable;
        this.shipperAdminActions.setReofferExtendable(isExtendable);
    }

    onDriverSearch(query: any) {
        const defaultVehicleType = { id: 1 };

        // Use the default vehicleType if this.vehicleType is undefined or missing an id
        const vehicleTypeToUse = (this.vehicleType && this.vehicleType.id) ? this.vehicleType : defaultVehicleType;

        this.poolsActions.loadDriverList(query.term, vehicleTypeToUse.id, this.shipperId);
    }

    submitReoffer() {
        const selectedCandidates = get(this.driverAssignmentData, 'data');

        const poolsList = get(selectedCandidates, 'driverPoolIds', []);

        const drivers = get(selectedCandidates, 'driverUserAccountIds', []);
        const driverList = this.getDriverObjectFromState(drivers);

        const payload = {
            orderId: this._orderItem.orderId,
            offerType: this.offerType,
            selectedCandidates: {
                drivers: driverList,
                pools: poolsList
            },
            isExtendable: this.isExtendable,
            success: this.reofferCallback.bind(this),
            fail: this.reofferCallback.bind(this)
        };

        this.shipperAdminActions.reofferOrder(payload);
    }

    reofferCallback() {
        this.shipperAdminActions.clearReoffer();
        this.modalRef.hide();
    }

    getDriverObjectFromState(driverIds: Array<number>) {
        return filter(this.drivers, driver => {
            return driverIds.indexOf(driver.userAccountId) !== -1;
        });
    }

    isReofferModalDisabled() {
        const driverType: number = get(this.driverAssignmentData, 'type', 1);
        const poolCount = get(this.driverAssignmentData, 'data.driverPoolIds.length', 0);
        const driverCount = get(this.driverAssignmentData, 'data.driverUserAccountIds.length', 0);
        const isDisabled = (driverType === OfferType.DriversPool && poolCount === 0) ||
            (driverType === OfferType.Exclusive && driverCount === 0);
        return isDisabled;
    }

    isReofferOrderDisabled() {
        // Shipper is suspended
        if (this.shipperStatus.suspended) return true;

        // the order already has a completed stop (items are out for delivery)
        this._orderItem.stopList.forEach((stopItem) => {
            if (stopItem.completedTimestamp) return true;
        });

        if (OrderStatuses[this.orderItem.statusId].name != OrderStatusName.Assigned) return true;
        return false;
    }

    isEditOrderDisabled() {
        return isEditOrderDisabled(this.orderItem.statusId) || this.shipperStatus.suspended;
    }

    isCancelDisabled() {
        const name = OrderStatuses[this.orderItem.statusId].name;
        return !(name === OrderStatusName.Assigned ||
            name === OrderStatusName.Offered ||
            name === OrderStatusName.Draft ||
            name === OrderStatusName.Released ||
            name === OrderStatusName.Scheduled ||
            name === OrderStatusName.FutureAssigned) ||
            this.shipperStatus.suspended;
    }

    isConfirmModalDisabled() {
        return (!this.cancelComment.length || this.cancelModalConfirmDisabled);
    }

    openReofferModal(template: TemplateRef<any>) {
        this.shipperAdminActions.editOrder(this._orderItem.orderId);
        this.modalRef = this.modalService.show(template, { animated: false, ignoreBackdropClick: true });
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.modalConfig);
    }

    successCallback() {
        this.cancelModalConfirmDisabled = false;
        this.modalRef.hide();
        this.cancelComment = '';
        this.orderItem.statusId = OrderStatusId.Canceled;
        this.ordersAction.updateOrderStatus(this.orderItem);
    }

    failCallback() {
        this.cancelModalConfirmDisabled = false;
        this.modalRef.hide();
        this.cancelComment = '';
    }

    confirm(): void {
        this.cancelOrder();
    }

    decline(): void {
        this.cancelComment = '';
        this.cancelModalConfirmDisabled = false;
        this.modalRef.hide();
    }

    cancelReoffer(): void {
        setTimeout(() => {
            this.shipperAdminActions.clearReoffer();
            this.modalRef.hide();
            this.cdr.markForCheck();  // Tell Angular to recheck the view
        });
    }
}
