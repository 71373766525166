import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { TermsOfServiceActions } from '../../actions/termsOfService.actions';
import { TERMS, TermsOfService } from '../../models/termsOfService.model';
import { ITermsOfServiceState } from '../../models/model';

@Component({
    selector: 'tuya-terms-of-service-container',
    templateUrl: './terms-container.component.html',
    styleUrls: ['./terms-container.component.scss']
})
export class TermsContainerComponent implements OnInit {

    @Input() userType = 'Shipper';

    @select(['core', 'tos'])
    readonly termsOfService$: Observable<ITermsOfServiceState>;
    @ViewChild('termsOfService', {static: true}) termsOfServiceRef;
    @ViewChild('privacyPolicy', {static: true}) privacyPolicyRef;

    termsOfServiceText: string;
    termsOfServiceEffectiveDate: Date;
    privacyPolicyText: string;
    privacyPolicyEffectiveDate: Date;
    modalRef: BsModalRef;
    termsOfService: TermsOfService;

    private modalConfig = {
        class: 'modal-lg',
        animated: false
    };

    constructor(private tosActions: TermsOfServiceActions,
                public modalService: BsModalService) {

    }

    ngOnInit() {
        this.termsOfService$.subscribe(state => {
            this.termsOfService = state;
            if (this.isTermsAvailable(TERMS.Terms) && state.terms !== null) {
                this.termsOfServiceText = state.terms.text;
                this.termsOfServiceEffectiveDate = state.terms.effectiveDate;
            }
            if (this.isTermsAvailable(TERMS.Privacy) && state.terms !== null) {
                this.privacyPolicyText = state.privacy.text;
                this.privacyPolicyEffectiveDate = state.terms.effectiveDate;
            }
        });

        if (!this.isTermsAvailable(TERMS.Terms)) {
            this.tosActions.loadTerms(this.userType);
        }
        if (!this.isTermsAvailable(TERMS.Privacy)) {
            this.tosActions.loadPrivacy(this.userType);
        }
    }

    public openModal(template: TemplateRef<any>) {
        if (this.isTermsAvailable()) {
            this.modalRef = this.modalService.show(template, this.modalConfig);
        }
    }

    public closeModal() {
        this.modalRef.hide();
    }

    private isTermsAvailable(id?: TERMS) {
        if (!id) {
            return this.isTermsAvailable(TERMS.Terms) && this.isTermsAvailable(TERMS.Privacy);
        }
        switch (id) {
            case TERMS.Terms:
                return this.termsOfService && this.termsOfService.terms !== null;
            case TERMS.Privacy:
                return this.termsOfService && this.termsOfService.privacy !== null;
            default:
                return this.isTermsAvailable(TERMS.Terms) && this.isTermsAvailable(TERMS.Privacy);
        }
    }


}
