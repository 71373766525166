import { Alert, AlertType } from '../../../core/models/alert.model';
import { AlertSelectors } from '../../../core/selectors/alert.selectors';
import {
    OnInit, AfterContentInit, ChangeDetectorRef, Component, Input, Output, EventEmitter, OnDestroy
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgRedux, select } from '@angular-redux-ivy/store';
import { catchError, map, Observable, Subscription, throwError } from 'rxjs';
import { StopDto, StopType } from '../../models/stop.model';
import { IAppState } from '../../../store/model';
import { AutocompleteItem } from '../../../core/models/autocomplete-item.model';
import { getAddressAutocompleteItems } from '../../selectors/builder.selectors';
import { AlertActions } from '../../../core/actions/alert.actions';
import { BuilderService } from '../../services/builder.service';
import { BuilderActions } from '../../actions/builder.actions';
import { transformAddressIntoStopDto } from '../../transforms/builder.transforms';

@Component({
    selector: 'tuya-autocomplete-address',
    templateUrl: 'autocomplete-address.container.html',
    styleUrls: ['./autocomplete-address.container.scss'],
    providers: [BuilderService]
})
export class AddressAutocompleteContainerComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() stopType: StopType;
    @Input() isOptionalStop = false;
    @Input() stopIndex = 0;
    @Input() stop: StopDto;
    @Input() isControlDisabled = false;
    @Input() stopId = null;
    @Input() selectedStopId = null; // delete when removing old shipment builder
    @Input() className = '';
    @Input() stopTypeId: number;
    @Input() addressContactForm: UntypedFormGroup;
    @Input() displayAddressSearchError = false;

    @Output() showAddressSearchError = new EventEmitter();
    @Output() hideAddressSearchError = new EventEmitter();
    @Output() onAddressSelect = new EventEmitter();

    public addressAutocompletFoundItems$: Observable<AutocompleteItem[]>;
    public addressAutoCompleteIsLoading: boolean;
    @select(AlertSelectors.alertSelector)
    alert$: Observable<Alert>;

    public AlertType: typeof AlertType = AlertType;
    public placeholder: string;
    public focusAndClear = false;

    private _subscription: Subscription = new Subscription();
    private SEARCH_START_LENGTH = 3;
    private itemsRes = [];

    constructor(private ngRedux: NgRedux<IAppState>,
                private alertActions: AlertActions,
                private builderService: BuilderService,
                private builderActions: BuilderActions,
                private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.stopType === StopType.Pickup) {
            this.placeholder = 'builderAddress.addressPlaceholderPickup';
        } else if (this.stopType === StopType.Delivery) {
            this.placeholder = 'builderAddress.addressPlaceholderDelivery';
        }
    }

    ngAfterContentInit() {
        this._subscription = this.alert$.subscribe(alert => {
            if (alert) {
                if (!this.selectedStopId || this.selectedStopId === this.stopId) {
                    this.focusAndClear = true;
                }
                this.cd.detectChanges();
            }
        });
    }

    onRequestSearchResults(searchTerm: string) {
        this.focusAndClear = false;
        const currentUser = this.ngRedux.getState().auth.currentUser;
        const shipperId = currentUser.shipperProfile['id'];
        const latitude = currentUser.shipperProfile.businessEntity.physicalAddress.latitude;
        const longitude = currentUser.shipperProfile.businessEntity.physicalAddress.longitude;
    
        if (searchTerm && searchTerm.length >= this.SEARCH_START_LENGTH) {
            this.addressAutoCompleteIsLoading = true;
            this.addressAutocompletFoundItems$ = this.builderService.getSearchAutoComplete(shipperId, searchTerm, latitude, longitude).pipe(
                map(data => transformAddressIntoStopDto(data)),
                map((data: StopDto[]) => this.filterAddressList(data)),
                map(data => this._getNameAddressListSuccess(data)),
                catchError(err => {
                    this.alertActions.show({ error: err, type: AlertType.BadRequest });
                    this.addressAutoCompleteIsLoading = false;
                    return throwError(err);
                })
            );
        }
    }

    onSelectStopItem(name) {
        if (!name || name === '') {
            this.addressContactForm.get('nameOrDescription').markAsUntouched();
            return false;
        }
        this.onAddressSelect.emit(name);
    }

    onClearSearchInput() {
        // clear current row's input
        if (!this.selectedStopId || this.selectedStopId === this.stopId) {
            this.alertActions.hide();
            this.focusAndClear = true;
        }
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    /**
     * Handle keydown event of address search input.
     * Clear address in state if address search field when entering a new search term.
     */
    onKeyDown(event: any) {
        // this.hideAddressSearchError.emit();
        // const newStop = { ...this.stop };
        // newStop.nameOrDescription = null;
        // newStop.address = getInitialAddressState();
        // if (event.keyCode === 8) {
        //     this.builderActions.onAddressSelect(newStop, this.stopIndex);
        // }
    }

    focusIn() {
        this.hideAddressSearchError.emit();
    }

    focusOut(noSearchResults: boolean) {
        // When address is selected in simple.autocomplete, onFocusOut() triggers before onSelectValue()
        // noSearchResults is used to prevent turning on error styling when an address is selected
        // If noSearchResults is not used, error styling flashes for a split second

        const noAddressInState = !this.stop.address.addressLine;
        const notLoading = !this.addressAutoCompleteIsLoading;
        if (noSearchResults && noAddressInState && notLoading) {
            this.showAddressSearchError.emit();
        }
    }

    private _getNameAddressListSuccess(data: StopDto[]): AutocompleteItem[] {
        this.itemsRes = data;
        if (!this.itemsRes.length) {
            this.showAddressSearchError.emit();
        }
        this.addressAutoCompleteIsLoading = false;
        return getAddressAutocompleteItems(data, this.ngRedux.getState().core.states);
    }

    private filterAddressList (data: StopDto[]) {
        return data.reduce((p, c) => {
            const index = p.findIndex(e =>
                (e.address.addressLine === c.address.addressLine)
                &&
                (e.nameOrDescription === c.nameOrDescription)
                &&
                (e.suiteNumber === c.address.country)
                &&
                (e.suiteNumber === c.address.stateId)
                &&
                (e.suiteNumber === c.address.suiteNumber)
                &&
                (e.suiteNumber === c.address.city));

            if (index < 0) {  return p.concat(c); }
            if (new Date(c.pickupNoEarlierThan).getTime() > new Date(p[index].pickupNoEarlierThan).getTime()) {
                p[index] = c;
            }
            return p;
        }, []);
    }

}
