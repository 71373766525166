import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DriverModalComponent } from '../../components/driver-modal/driver-modal.component';
import { DriverDTO, DriverTablePagination, VehicleEnum, ServiceArea, ServiceAreaName, DriverModalModeEnum } from '../../models/driverportal.model';
import { AdminDriverPortalActions } from 'app/admin/actions/admin.driver.portal.actions';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { SnackBarComponent } from 'app/admin/components/snack-bar/snack-bar.component';
import { SearchOrderActions } from 'app/home/actions/search-order.actions';
import { PopupComponent } from 'app/shared/components/popup/popup.component';
import { PopupTypeEnum } from 'app/core/models/alert.model';
import { ConfirmPopup, ConfirmPopupType } from 'app/shared/models/confirm-alert.model';
import { ConfirmPopupComponent } from 'app/shared/components/confirm-popup/confirm-popup.component';
import { ErrorStateMatcher } from '@angular/material/core';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'tuya-driver-portal',
  templateUrl: './driver-portal.component.html',
  styleUrls: ['./driver-portal.component.scss']
})
export class DriverPortalComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['name', 'driverCode', 'phoneNumber', 'email', 'serviceArea', 'vehicleType', 'status'];
  selection = new SelectionModel<DriverDTO>(true, []);
  pageIndex = 0;
  pageSize = 0;
  isChanged = false;
  vehicleEnum = VehicleEnum;
  serviceAreaEnum = ServiceArea;
  serviceAreaNameEnum = ServiceAreaName;

  searchQuery = '';

  timeoutRef: number;
  modalCloseDelay = 10000;

  driverPopupRef: any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @select(['admin', 'driverPortal', 'drivers'])
  readonly drivers$: Observable<DriverDTO[]>;
  @select(['admin', 'driverPortal', 'isLoading'])
  readonly isLoading$: Observable<boolean>;
  @select(['admin', 'driverPortal', 'pagination'])
  readonly pagination$: Observable<DriverTablePagination>;
  @select(['admin', 'driverPortal', 'driverModal', 'isActive'])
  readonly driverModalReact$: Observable<boolean>;
  @select(['admin', 'driverPortal', 'pagination', 'searchQuery'])
  readonly searchQuery$: Observable<string>;
  @select(['admin', 'driverPortal', 'showSnackBar'])
  readonly showSnackBar$: Observable<{show: boolean, message: string, subTitle?: string}>;

  @select(['admin', 'driverPortal', 'pagination', 'totalItemCount'])
  readonly totalItemCount$: Observable<number>;
  @select(['admin', 'driverPortal', 'pagination', 'currentPageNumber'])
  readonly currentPageNumber$: Observable<number>;
  @select(['admin', 'driverPortal', 'pagination', 'pageSize'])
  readonly pageSize$: Observable<number>;
  @select(['admin', 'driverPortal', 'pagination', 'isSearching'])
  readonly isSearching$: Observable<number>;

  @select(['home', 'searchOrders', 'progressBarVal'])
  readonly progressBarVal$: Observable<number>;

  constructor(public dialog: MatDialog,
              private searchOrderActions: SearchOrderActions,
              private adminDriverPortalActions: AdminDriverPortalActions,
              private _snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.getDrivers();
    this.driverModalReact$.subscribe(res => {
      if (!res && this.driverPopupRef) {
        this.driverPopupRef.close();
      }
    });

    this.showSnackBar$.subscribe(res => {
      if (res.show) {

        // this.openSnackBar(res.message);
        // this.adminDriverPortalActions.showSnackBar({show: false, message: ''});
        const data = {
          type: PopupTypeEnum.CreateUpdateOrder,
          title: res.message,
          subTitle: res.subTitle ? res.subTitle : ''
        };
        const dialogRef = this.dialog.open(PopupComponent, {
          data: data,
          autoFocus: false,
          panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe((response: any) => {
          clearInterval(this.timeoutRef);
        });

        this.closeSuccessModal();
      }
    });

    this.searchQuery$.subscribe(res => {
      this.searchQuery = res;
    });
  }

  closeSuccessModal() {
    this.timeoutRef = window.setTimeout(() => {
        this.dialog.closeAll();
    }, this.modalCloseDelay);
  }

  ngOnDestroy() {
    this.adminDriverPortalActions.setDriverPortalInitialStatus();
  }

  onUpdateVehicle(driver: DriverDTO) {
    let title = 'Edit Vehicle';

    if (!driver.vehicle) {
      title = 'Add Vehicle';
      this.adminDriverPortalActions.updateDriverModalMode(DriverModalModeEnum.AddVehicle);
    } else {
      this.adminDriverPortalActions.updateDriverModalMode(DriverModalModeEnum.UpdateVehicle);
    }
    this.onAddNewDriver(false, driver, title);
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: message,
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  getDrivers() {
    this.adminDriverPortalActions.getDrivers('all');
  }

  onClear() {
    this.adminDriverPortalActions.setDriverSearchQuery('');
    this.adminDriverPortalActions.getDrivers('all');
  }

  onSearch(ev: string) {
    const data = { pageSize: 10, currentPageNumber: 1 };
    this.searchOrderActions.updateProgressBar(0);
    this.adminDriverPortalActions.updateDriverTablePagination(data);
    this.adminDriverPortalActions.setDriverSearchQuery(ev);
    this.adminDriverPortalActions.getDrivers('search');
  }

  onResetPassword(driver) {
    const popupData: ConfirmPopup = {
      type: ConfirmPopupType.confirm,
      text: `Are you sure you want to Reset Password for <b>\"${driver.name}\"</b>`,
      buttons: {
        accept: 'Yes, Reset',
        cancel: 'Do Not Reset'
      }
    };

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      data: popupData,
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.adminDriverPortalActions.resetPassword({driverId: driver.id, driverEmail: driver.email});
      }
    });
  }

  // driver Id and current status (Acrive, Blocked)
  onStatusChange(dirverId: string, currentStatus: boolean) {
    this.adminDriverPortalActions.activateDiactDriverBy({id: dirverId, isActivate: !currentStatus});
  }

  onAddNewDriver(addDriver: boolean, driver: DriverDTO, title: string = 'Add New Driver') {
    if (addDriver) { this.adminDriverPortalActions.updateDriverModalMode(DriverModalModeEnum.AddDriver); }
    this.adminDriverPortalActions.driverModalReact(true);

    this.driverPopupRef = this.dialog.open(DriverModalComponent, {
      data: {driver, title},
      disableClose: true,
      autoFocus: false
    });

    this.driverPopupRef.afterClosed().subscribe(result => {
      if (this.dialog.openDialogs.length > 0) {
        this.adminDriverPortalActions.driverModalReact(false);
      }
    });
  }

  onPaginateChange(ev: any) {
    const data = { pageSize: ev.pageSize, currentPageNumber: ev.pageIndex + 1 };
    this.adminDriverPortalActions.updateDriverTablePagination(data);
    const getDriversType = this.searchQuery === '' ? 'all' : 'search';
    this.adminDriverPortalActions.getDrivers(getDriversType);
  }

}
