import { Component, OnInit, Renderer2, OnDestroy, Injectable, TemplateRef, ViewChild } from '@angular/core';
import { select, select$ } from '@angular-redux-ivy/store';
import { Router, ActivatedRoute } from '@angular/router';
import { first, Observable, takeUntil } from 'rxjs';
import { BuilderActions } from '../../actions/builder.actions';
import { BuilderSelectors, getAddress } from '../../selectors/builder.selectors';
import { AddressDto } from '../../models/address.model';
import { BuilderService } from '../../services/builder.service';
import { StopDto, pickupStopIndex, deliveryStopIndex } from '../../models/stop.model';
import { OrderItemDto } from '../../models/item.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OrderDto } from '../../../builder/models/order.model';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../app.config.service';
import { AssignmentsDto, ConfirmDriversAssignmentsDto } from '../../models/assignments.model';
import { transformToConfirmDriversAssignmentsObj } from '../../transforms/builder.transforms';
import { Alert, Popup, PopupTypeEnum } from '../../../core/models/alert.model';
import { AlertActions } from '../../../core/actions/alert.actions';
import { AlertSelectors } from '../../../core/selectors/alert.selectors';
import moment, { Moment } from 'moment';
import { AlertType } from '../../../core/models/alert.model';
import { formatDisplayedTime, checkIsDateInThePast, convertDateToToday } from '../../utils/time';
import { OrderEditMode } from '../../../core/models/order.dto';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TimeValidators } from '../../validators/time.validators';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleSubmitSelectionComponent } from 'app/builder/components/schedule-submit-selection/schedule-submit-selection.component';
import { OrderStatusId, ShipperProfileDto } from 'app/core/models/dto';
import { PopupComponent } from 'app/shared/components/popup/popup.component';
import { PriceChangeModalComponent } from 'app/builder/components/price-change-modal/price-change-modal.component';
import { environment } from 'environments/environment';

@Injectable()
@Component({
  selector: 'tuya-builder-input',
  templateUrl: './builder-input.component.html',
  styleUrls: ['./builder-input.component.scss'],
  providers: [BuilderService, CurrencyPipe]
})
export class BuilderInputComponent implements OnInit, OnDestroy {
  @select(BuilderSelectors.getStops)
  readonly stops$: Observable<StopDto[]>;
  @select(BuilderSelectors.getCurrentStep)
  readonly currentStep$: Observable<number>;
  @select$(['auth', 'currentUser'], getAddress)
  readonly address$: Observable<AddressDto>;
  @select(['auth', 'currentUser', 'adminProfile'])
  readonly adminProfile$: Observable<any>;
  @select(['auth', 'currentUser', 'shipperProfile'])
  readonly shipperProfile$: Observable<ShipperProfileDto>;
  @select(BuilderSelectors.getItems)
  readonly items$: Observable<OrderItemDto[]>;
  @select(BuilderSelectors.getIsLoading)
  readonly isLoading$: Observable<boolean>;
  @select(BuilderSelectors.getOrderDto)
  readonly order$: Observable<OrderDto>;
  @select(BuilderSelectors.getAssignmentsDto)
  readonly assignments$: Observable<AssignmentsDto>;
  @select(BuilderSelectors.getOrderCode)
  readonly orderCode$: Observable<string>;
  @select(AlertSelectors.alertSelector)
  readonly alert$: Observable<Alert>;
  @select(BuilderSelectors.getSubmitErrors)
  readonly submitErrors$: Observable<Array<string>>;
  @select(BuilderSelectors.getTimeSelections)
  readonly timeSelections$: Observable<Array<any>>;
  @select(BuilderSelectors.getOrderId)
  readonly orderId$: Observable<number>;

  @ViewChild('failureModal', { static: true }) public failureModal: TemplateRef<any>;

  @select(BuilderSelectors.getIsEditMode)
  readonly isEditMode$: Observable<boolean>;
  @select(BuilderSelectors.getIsDuplicateMode)
  readonly isDuplicateMode$: Observable<boolean>;
  @select(BuilderSelectors.getOrderEditMode)
  readonly orderEditMode$: Observable<number>;
  @select(BuilderSelectors.getOriginalPrice)
  readonly originalPrice$: Observable<number>;
  @select(BuilderSelectors.getIsEditOrderLoaded)
  readonly isEditOrderLoaded$: Observable<boolean>;
  @select(BuilderSelectors.getReferenceID)
  readonly orderReferenceId$: Observable<string>;
  @select(BuilderSelectors.getOrderDto)
  readonly orderDto$: Observable<OrderDto>;
  @select(BuilderSelectors.getWaitTimeValue)
  readonly builderWaitTimePassed$: Observable<boolean>;
  @select(BuilderSelectors.isScheduledOrder)
  readonly isScheduledOrder$: Observable<boolean>;
  @select(BuilderSelectors.getSubmitOrder)
  readonly getSubmitOrder$: Observable<boolean>;

  public addressSearchResult$: Observable<AddressDto[]>;

  materialLocked = false;
  nonMaterialLocked = false;
  driversLocked = false;
  currentStep: number;
  stops: StopDto[];
  stopCount: number;
  renderer;
  order: OrderDto;
  service: BuilderService;
  assignments: AssignmentsDto;
  orderCode: string;
  public isLoading: boolean;
  alert: Alert;
  successModalRef: BsModalRef;
  failureModalRef: BsModalRef;
  timeSelections: Array<number> = [];
  date: Date;
  easyStartTime: string;
  easyEndTime: string;
  easyStartTimeDisplay: string;
  easyEndTimeDisplay: string;
  easyDisplayFormat = 'hh:mm A';
  submitErrors;
  orderId: number;
  isEditMode = false;
  originalPrice: number;
  interval: number;
  translator;
  orderReferenceId: string;
  orderDto: OrderDto;
  timeoutRef: number; // Ref to timeout counter for 5-second countdown on order confirmation dialog.
  builderWaitTimePassed: boolean;
  restartTimer$ = new BehaviorSubject(false);
  isScheduledOrder = false;
  submitOrderNow = false;
  pickupDate = '';
  adminProfile: any;
  shipperProfile: ShipperProfileDto;
  isDuplicateMode = false;

  private unsubscribeSubscribers = new Subject<boolean>();

  // will be displayed in success modal
  private modalConfig = {
    class: 'modal-sm',
    animated: false
  };

  /**
   * Confirmation dialog auto-redirect timer length.
   */
  private modalCloseDelay = 5000;

  /**
   * Edit mode expiration time.
   */
  private sub: any;

  constructor(renderer: Renderer2,
    translate: TranslateService,
    private builderActions: BuilderActions,
    private dialog: MatDialog,
    private modalService: BsModalService,
    private http: HttpClient,
    private config: ConfigService,
    private router: Router,
    private alertActions: AlertActions,
    private _currencyPipe: CurrencyPipe,
    private route: ActivatedRoute) {
    this.translator = translate;
    this.renderer = renderer;
    this.builderActions = builderActions;
    this.currentStep$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.currentStep = result);
    this.stops$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => {
      this.stopCount = result.length;
      this.stops = result;
    });
    this.orderCode$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.orderCode = result);
    this.alert$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.alert = result);
    this.originalPrice$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.originalPrice = result);
    this.orderReferenceId$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.orderReferenceId = result);
    this.orderDto$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.orderDto = result);

    this.address$.pipe(takeUntil(this.unsubscribeSubscribers)).pipe(first()).pipe(switchMap(
      address => {
        const completeAddress = `${address.addressLine} ${address.city} ${address.postalCode}`;
        return this.service.getAddressOnly(completeAddress, false);
      }
    ));

    this.isLoading$.subscribe(result => this.isLoading = result);
    this.order$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => {
      this.order = result;
      if (result.orderItems.length > 0) {
        this.pickupDate = result.orderItems[0].stops[0].pickupNoEarlierThan;
      }
    });
    this.assignments$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.assignments = result);
    this.service = new BuilderService(this.http, this.config);
    this.submitErrors$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.submitErrors = result);
    this.timeSelections$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.timeSelections = result);
    this.orderId$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => this.orderId = result);
    this.isEditMode$.pipe(takeUntil(this.unsubscribeSubscribers)).pipe(first()).subscribe(mode => {
      this.isEditMode = mode;
    });
    this.isDuplicateMode$.pipe(takeUntil(this.unsubscribeSubscribers)).pipe(first()).subscribe(mode => this.isDuplicateMode = mode);

    this.orderEditMode$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(mode => {
      const materialLocked = (mode === OrderEditMode.PartiallyCompleted);
      const nonMaterialLocked = materialLocked || (mode === OrderEditMode.Other)
        || (mode === OrderEditMode.NoEdit);
      this.materialLocked = materialLocked && this.isEditMode;
      this.nonMaterialLocked = nonMaterialLocked && this.isEditMode;
      this.driversLocked = (mode === OrderEditMode.Accepted) && this.isEditMode;
    });
    this.isEditOrderLoaded$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => {
      if (result && !this.materialLocked) {
        this.checkStopDates();
      }
    });

    this.isScheduledOrder$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => {
      this.isScheduledOrder = result;
    });

    this.getSubmitOrder$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(result => {
      this.submitOrderNow = result;
    });

    this.builderWaitTimePassed$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe(res => this.builderWaitTimePassed = res);
    this.adminProfile$.subscribe(res => this.adminProfile = res);
    this.shipperProfile$.subscribe(res => this.shipperProfile = res);
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'builder');
    // @TODO Get order id from url param.
    this.sub = this.route.queryParams.subscribe(params => {
      this.builderActions.onBuilderInit({
        orderId: params.orderId || null,
        isDuplicateMode: params.isDuplicateMode || false
      });
    });

    this.restartTimer$.pipe(takeUntil(this.unsubscribeSubscribers)).subscribe();

    this.initEasyTimes();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'builder');
    if (this.sub && this.sub.unsubscribe) {
      this.sub.unsubscribe();
    }
    clearInterval(this.interval);

    // Clear timeout countdown for 5-second redirect to /orders after displaying order confirmation dialog.
    if (this.timeoutRef) clearTimeout(this.timeoutRef);

    // reset builder
    this.builderActions.onBuilderInit({ useDefault: true });

    this.unsubscribeSubscribers.next(true);
    this.unsubscribeSubscribers.unsubscribe();
  }

  initEasyTimes() {
    const pickupStartTime = this.stops[pickupStopIndex].pickupNoEarlierThan;
    const pickupEndTime = this.stops[pickupStopIndex].pickupNoLaterThan;

    // generate new easy times (for pickup stop) if one of the following conditions apply:
    // 1. is a new order
    // 2. page reloaded when order only has one stop
    // 3. page reloaded in pickup stop step, order has two stops, easy time window is selected, previous times no longer valid

    // isOneStopOrder covers conditions 1 and 2
    const isOneStopOrder = !this.stops[deliveryStopIndex];

    // needResetEasyTimes covers condition 3
    const currentStepIsPickupStop = this.currentStep === pickupStopIndex;
    const isEasyTimeWindowSelected = this.timeSelections[0] === 1;
    const isOldEasyTimeInvalid = !TimeValidators.startTimeIsNotInThePast(pickupStartTime);
    const needResetEasyTimes = currentStepIsPickupStop && !isOneStopOrder && isEasyTimeWindowSelected && isOldEasyTimeInvalid;

    if (isOneStopOrder || needResetEasyTimes) {
      this.generateEasyTimes();
      this.setTimesInState(0, this.easyStartTime, this.easyEndTime);
    } else {
      this.easyStartTime = pickupStartTime;
      this.easyEndTime = pickupEndTime;
    }

    this.setEasyDisplayTimes();
  }

  // Easy Stop Time: (10 minutes) & (4 hours and 10 minutes)
  generateEasyTimes() {
    this.easyStartTime = moment().add(10, 'minutes').toISOString();
    this.easyEndTime = moment().add(4, 'hours').add(10, 'minutes').toISOString();
  }

  // convert to 'h:mm A' format for Easy 4 hour display
  setEasyDisplayTimes() {
    this.easyStartTimeDisplay = formatDisplayedTime(this.easyStartTime, this.easyDisplayFormat);
    this.easyEndTimeDisplay = formatDisplayedTime(this.easyEndTime, this.easyDisplayFormat);
  }

  setTimesInState(stopIndex: number, startTime: string, endTime: string) {
    this.builderActions.onEditTime(stopIndex, 'pickupNoEarlierThan', startTime);
    this.builderActions.onEditTime(stopIndex, 'pickupNoLaterThan', endTime);
  }

  openSuccessModal(props) {
    const data: Popup = {
      type: PopupTypeEnum.CreateUpdateOrder,
      title: props.title,
      subTitle: props.subTitle,
      titleProps: props.titleProps,
      subTitleProps: props.subTitleProps
    };

    const dialogRef = this.dialog.open(PopupComponent, {
      data: data,
      autoFocus: false,
      panelClass: 'custom-dialog-container'
    });
    dialogRef.updatePosition({ top: '150px' });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigateByUrl('/orders');
    });
  }

  openFailureModal() {
    this.failureModalRef = this.modalService.show(this.failureModal, this.modalConfig);
  }

  onCloseSuccessModal() {
    this.successModalRef.hide();
    this.router.navigateByUrl('/orders');
  }

  onCloseFailureModal() {
    this.failureModalRef.hide();
  }

  checkStopDates() {
    const pickupStop = this.stops[pickupStopIndex];
    const isPickupDateInThePast = checkIsDateInThePast(pickupStop.pickupNoEarlierThan);

    const deliveryStop = this.stops[deliveryStopIndex];
    let isDeliveryDateInThePast = false;
    if (deliveryStop) isDeliveryDateInThePast = checkIsDateInThePast(deliveryStop.pickupNoEarlierThan);

    if (isPickupDateInThePast) {
      // convert pickupdate times to today's date at the same time, then set to state
      const newPickupStartTime = convertDateToToday(pickupStop.pickupNoEarlierThan);
      const newPickupEndTime = convertDateToToday(pickupStop.pickupNoLaterThan);
      this.setTimesInState(pickupStopIndex, newPickupStartTime, newPickupEndTime);

      // set delivery stop times to the new pickup stop times
      if (isDeliveryDateInThePast) this.setTimesInState(deliveryStopIndex, newPickupStartTime, newPickupEndTime);
    }
  }

  isSubmitButtonDisabled() {
    // num steps = num stops + 2
    // submitStep = num steps - 1 (for 0 index)
    const submitStep = this.stops.length + 1;
    return !(this.currentStep === submitStep);
  }

  sessionExpired() {
    this.builderActions.setNavPromptNotRequired();
    this.alertActions.show({
      type: AlertType.Error,
      message: this.translator.instant('builderModalStatus.edit-expired')
    });
    this.router.navigate(['/']);
    clearInterval(this.interval);
  }

  submitOrder(showLoader: boolean) {
    if (this.isEditMode && !this.isDuplicateMode) {
      if (!environment.production) console.debug(this.isEditMode);
      this.submitOrderEditMode(showLoader);
    } else {
      this.confirmOrder();
    }
  }

  submitOrderEditMode(showLoader: boolean) {
    if (showLoader) {
      this.builderActions.displaySpinner();
    }
    const orderCode = this.order.code;

    this.order.orderItems.forEach(item => item['id'] < 0 ? delete item['id'] : item['id']);

    this.service.createOrder(this.order).subscribe(
      (response) => {
        this.builderActions.onPutOrderConfirmSuccess(orderCode);

        let popupText = {};
        if (response.isScheduledOrder) {
          popupText = {
            title: 'builderModalStatus.scheduled-title-edit',
            subTitle: 'builderModalStatus.scheduled-description-text-edit',
            titleProps: { code: orderCode },
            subTitleProps: { code: orderCode }
          };
        } else {
          popupText = {
            title: 'builderModalStatus.success-title',
            subTitle: 'builderModalStatus.success-update-text',
            subTitleProps: { code: orderCode }
          };
        }

        this.onSuccessResponse(popupText);
      },
      error => {
        console.error(error);
        this.alertActions.show({ type: AlertType.BadRequest, error: error });
        this.builderActions.hideSpinner();
      },
      () => this.builderActions.hideSpinner()
    );
  }

  private isConfirmingOrder: boolean = false;
  confirmOrder() {
    if (this.isConfirmingOrder) return;
    this.isConfirmingOrder = true;

    this.builderActions.displaySpinner();

    // General Offers
    this.builderActions.displaySpinner();
    let confirmDriversAssignmentsObj: ConfirmDriversAssignmentsDto = {
      orderId: this.orderId,
      driverUserAccountIds: [],
      driverPoolIds: [],
      isPriceChangeAllowed: this.order.isPriceChangeAllowed
    };

    // for exclusive and limited offers
    // this.assignments isn't initialized unless exclusive or limited offer is selected
    if (this.assignments) {
      confirmDriversAssignmentsObj = transformToConfirmDriversAssignmentsObj(this.assignments);
    }

    if (this.isScheduledOrder) {
      confirmDriversAssignmentsObj.submitOrder = this.submitOrderNow;
    }

    this.service.getConfirmOrderId(confirmDriversAssignmentsObj).subscribe(
      result => {
        this.builderActions.hideSpinner();
        this.builderActions.onPutOrderConfirmSuccess(result.orderCode);
        this.order.code = result.orderCode;
        
        let popupText = {};
        if (!this.order.isScheduledOrder) {
          popupText = {
            title: 'builderModalStatus.success-title',
            subTitle: 'builderModalStatus.success-submit-text',
            subTitleProps: { code: result.orderCode }
          };
        } else if (result.orderCode) {
          popupText = {
            title: 'builderModalStatus.scheduled-title',
            subTitle: 'builderModalStatus.scheduled-description-text',
            titleProps: { code: result.orderCode },
            subTitleProps: { code: result.orderCode }
          };
        }

        this.onSuccessResponse(popupText);
      },
      error => {
        console.error(error);
        this.alertActions.show({ type: AlertType.BadRequest, error: error });
        this.builderActions.hideSpinner();
        this.isConfirmingOrder = false;
      },
      () => {
        this.builderActions.hideSpinner();
      }
    );
  }

  onSuccessResponse(popupText: any) {
    this.openSuccessModal(popupText);

    this.timeoutRef = window.setTimeout(() => {
      this.dialog.closeAll();
      this.isConfirmingOrder = false; // Reset flag when dialog is closed
    }, this.modalCloseDelay);
  }

  isValidTime() {
    // If materialLocked user is not allowed to change times.
    if (this.materialLocked) return true;

    const now = moment();
    let i = 0;
    while (i < this.stopCount) {
      if (!TimeValidators.startTimeIsNotInThePast(this.stops[i].pickupNoEarlierThan) ||
        !TimeValidators.startTimeIsGreaterThanEndTime(this.stops[i].pickupNoEarlierThan, this.stops[i].pickupNoLaterThan)) {
        this.builderActions.setCurrentStep(this.currentStep, i);
        this.builderActions.onSubmitError(i, 'timeNoLongerValidOnNext');
        return false;
      }
      i++;
    }

    return true;
  }

  submitForm() {
    if (this.isScheduledOrder && ((this.order.statusId === OrderStatusId.Draft && this.isScheduledOrder) ||
      this.order.statusId === OrderStatusId.Scheduled) ||
      ((this.order['status'] === OrderStatusId.Draft && this.isScheduledOrder) ||
        this.order['status'] === OrderStatusId.Scheduled)) {
      this.openScheduledSelectionModal();
    } else if (!this.isValidTime()) {
      return false;
    } else if (!this.builderWaitTimePassed && !this.adminProfile) {
      this.submitOrder(true);
    } else {
      this.optimizePrice();
    }
  }

  optimizePrice() {
    this.builderActions.displaySpinner();

    delete this.order['status'];
    this.order.orderItems.forEach(item => {
      if (item['id'] < 0) {
        delete item['id'];
      }
    });
    this.service.updateOrderPrice(this.order).subscribe(
      result => {
        this.builderActions.hideSpinner();
        this.checkPriceChange(result, false);
      },
      error => {
        console.error(error);
        this.alertActions.show({ type: AlertType.BadRequest, error: error });
        this.builderActions.hideSpinner();
      }
    );
  }

  checkPriceChange(result: any, showLoader: boolean) {
    // show price change modal if non-zero original price, price has changed, and edit mode
    if (this.originalPrice && (result.total !== this.originalPrice)) {
      this.builderActions.hideSpinner();
      this.builderActions.builderWaitTimePassed(true);

      if (this.adminProfile && this.adminProfile.isAdmin) {
        this.adminPriceChange(result);
        return false;
      }

      this.alertActions.show({
        type: AlertType.NewPrice,
        buttonText: { ok: 'Submit' },
        message: this.translator.instant('builderModalStatus.price-change', {
          originalPrice: this._currencyPipe.transform(this.originalPrice, 'USD', true),
          newPrice: this._currencyPipe.transform(result.total, 'USD', true)
        }),
        callback: (accepted) => {
          return new Promise<boolean>((res, rej) => {
            if (accepted) {
              if (this.isValidTime()) {
                this.builderActions.onPutOrderSuccess(result, true);
                this.builderActions.updateInitPrice(false);
                this.submitOrder(true);
              }
            }
            res(accepted);
          });
        }
      });
    } else {
      this.submitOrder(false);
    }
  }

  adminPriceChange(result: OrderDto) {
    const data = {
      price: { prev: this.originalPrice, current: result.total },
      shipper: { name: `${this.shipperProfile.businessEntity.name}`, phone: `${this.shipperProfile.phoneNumber}` }
    };
    const dialogRef = this.dialog.open(PriceChangeModalComponent, {
      width: '547px',
      height: '555px',
      data: data
    });

    dialogRef.afterClosed().subscribe(res => {
      if (this.isValidTime() && res) {
        this.builderActions.allowOrderPriceChange(res === '1' ? false : true);
        if (res !== '1') {
          this.builderActions.onPutOrderSuccess(result, true);
          this.builderActions.updateInitPrice(false);
        } else {
          this.order.total = this.originalPrice;
        }
        this.submitOrder(true);
      }
    });
  }

  openScheduledSelectionModal() {
    const dialogRef = this.dialog.open(ScheduleSubmitSelectionComponent, {
      width: '528px',
      data: { submitNow: this.submitOrderNow },
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (this.adminProfile) {
          this.checkPriceChange(this.order, false);
        } else {
          this.submitOrder(true);
        }
      }
    });
  }
}