<ng-template #template [ngSwitch]="(alert | async)?.type">

    <section
        [ngClass]="{
            'alert--badRequest': (alert | async)?.type === AlertType.BadRequest,
            'alert--error': (alert | async)?.type === AlertType.Error,
            'alert--cancellable': (alert | async)?.type === AlertType.Cancellable,
            'alert--notInMsa': (alert | async)?.type === AlertType.NotInMsa,
            'alert--newPrice': (alert | async)?.type === AlertType.NewPrice,
            'alert--firebase': (alert | async)?.type === AlertType.Firebase,
            'alert--timeout': (alert | async)?.type === AlertType.Timeout,
            'alert--adminPanel': (alert | async)?.type === AlertType.AdminPanel,
            'alert--suspended': (alert | async)?.type === AlertType.Suspended
        }"
        [ngSwitch]="(alert | async)?.type">

        <!-- Not in MSA - {type: AlertType.NotInMsa} -->
        <div class="alert__body alert__body--notInMsa" *ngSwitchCase="AlertType.NotInMsa">
            <p class="alert__message">
                <span *ngIf="(alert | async)?.message">{{ (alert | async)?.message }} something</span>
                <span *ngIf="!(alert | async)?.message"> {{'tuya-alert.notInMsa' | translate}}</span>
            </p>
        </div>

        <!-- Bad HTTP Request (our backend, takes HTTP error obj) - {type: AlertType.BadRequest, error: error} -->
        <div class="alert__body alert__body--badRequest" *ngSwitchCase="AlertType.BadRequest">
            <div class="alert__header" *ngIf="(alert | async)?.error">
                <p class="alert__message">
                    {{ (alert | async)?.error?.statusText || ('tuya-shipment.network-error-modal.server-error' | translate) }}
                    <span *ngIf="(alert | async)?.error?.status">- {{(alert | async)?.error.status}}</span>
                </p>
            </div>
            <div class="alert__details" *ngIf="hasErrorDetails; else defaultServerErrorBody">
                <p *ngIf="(alert | async)?.error?.url" class="alert__url">{{ (alert | async)?.error.url }}</p>
                <ul *ngFor="let errorDetail of (alert | async)?.error?.error?.errors" class="alert__errorList">
                    <li class="alert__errorList__item" *ngFor="let key of listErrorKeyValues(errorDetail)">{{ key }}: {{ errorDetail[key] }}</li>
                </ul>
            </div>
        </div>

        <div class="alert__body alert__body--badRequest" *ngSwitchCase="AlertType.Suspended">
            <div class="alert__header">
                <p class="alert__message">
                   {{ 'tuya-alert.suspend-title' | translate }}
                </p>
            </div>
            <div class="alert__details">
                <p class="alert__message" [innerHTML]="'tuya-alert.suspend-content' | translate"></p>
            </div>
        </div>

        <!-- Firebase Error (takes translated message) - {type: AlertType.Firebase, message: message}-->
        <div class="alert__body" *ngSwitchCase="AlertType.Firebase">
            <div class="alert__header">
                <span class="alert__message">{{ ('tuya-login.error.firebaseError' | translate) }}</span>
            </div>

            <div class="alert__details">
                <p class="alert__message" *ngIf="(alert | async)?.message as message">
                    {{ message }}
                </p>
            </div>
        </div>

        <!-- Timeout Error (does not take error obj) - {type: AlertType.Timeout}-->
        <div class="alert__body" *ngSwitchCase="AlertType.Timeout">
            <p class="alert__message">{{ 'tuya-alert.timeout-error-message' | translate }}</p>
        </div>

        <div class="alert__body" *ngSwitchCase="AlertType.NewPrice">
            <div class="alert__timeContainer">
                <div class="timer">{{ 'tuya-alert.timerTxt' | translate }}:&nbsp;<span id="time">{{ min }}:{{ sec }}</span></div>
            </div>

            <p class="alert__message" *ngIf="(alert | async)?.message as message">
                {{ message }} here something
            </p>
        </div>
   
        <div class="alert__body" *ngSwitchCase="AlertType.Unknown">
            <div class="alert__header">
                <span class="alert__message">{{ 'tuya-alert.unknownError' | translate }}</span>
            </div>
            <p class="alert__message">{{ 'tuya-alert.unknownErrorMessage' | translate }}</p>
        </div>

        <!-- Default Text Error - {type: (AlertType.Error || AlertType.Cancellable), message: 'already translated string to display'} -->
        <div class="alert__body" *ngSwitchDefault>
            <p class="alert__message">{{ (alert | async)?.message }} show</p>
        </div>

        <div [ngSwitch]="(alert | async)?.type">
            <!-- Button Set - {type: AlertType.Cancellable, message: 'already translated string', callback: (function to run)}-->
            <ng-container *ngSwitchCase="AlertType.Cancellable">
                <div class="alert__buttonContainer">
                    <button type="button" class="btn btn-ok" (click)="executeCallback(true)">
                        {{ (alert | async)?.buttonText?.ok || ('tuya-alert.ok' | translate) }}
                    </button>
    
                    <button type="button" class="btn btn-secondary btn-cancel" (click)="executeCallback(false)">
                        {{ (alert | async)?.buttonText?.cancel || ('tuya-alert.cancel' | translate) }}
                    </button>
                </div>
            </ng-container>

             <!-- Summary Page Price Change -->
             <ng-container *ngSwitchCase="AlertType.NewPrice">
                <div class="alert__buttonContainer">             
                    <button type="button" class="btn btn-secondary" style="margin-right: 10px;" (click)="executeCallback(false)">
                        {{ (alert | async)?.buttonText?.cancel || ('tuya-alert.cancel' | translate) }}
                    </button>
                    
                    <button type="button" class="btn"(click)="executeCallback(true)">
                        {{ (alert | async)?.buttonText?.ok || ('tuya-alert.submit' | translate) }}
                    </button>
                </div>
            </ng-container>
    
            <!-- Admin Panel alert buttons suspend/unsuspend -->
            <ng-container *ngSwitchCase="AlertType.AdminPanel">
                <div class="alert__buttonContainer">
                    <button type="button" [className]="(alert | async)?.checked ? 'btn btn-danger' : 'btn'" (click)="executeCallback(true)">
                        <span *ngIf="!(alert | async)?.checked">{{ (alert | async)?.buttonText?.ok || ('tuya-alert.unsuspend' | translate) }}</span>
                        <span *ngIf="(alert | async)?.checked">{{ (alert | async)?.buttonText?.ok || ('tuya-alert.suspend' | translate) }}</span>
                    </button>
                    
                    <button type="button" class="btn btn-secondary" (click)="executeCallback(false)">
                        {{ (alert | async)?.buttonText?.cancel || ('tuya-alert.cancel' | translate) }}
                    </button>
                </div>
            </ng-container>
            
            <!-- Suspended OK button -->
            <ng-container *ngSwitchCase="AlertType.Suspended">
                <div class="alert__buttonContainer">
                    <button type="button" class="btn btn-default alert__button" (click)="executeCallback(true)">
                        {{ (alert | async)?.buttonText?.ok || ('tuya-alert.ok' | translate) }}
                    </button>
                </div>
            </ng-container>
    
            <!-- Default OK button -->
            <ng-container *ngSwitchDefault>
                <div class="alert__buttonContainer">
                    <button type="button" class="btn btn-default alert__button" (click)="executeCallback(true)">
                        {{ (alert | async)?.buttonText?.ok || ('tuya-alert.ok' | translate) }}
                    </button>
                </div>
            </ng-container>
        </div>

    </section>

</ng-template>

<ng-template #defaultServerErrorBody>
    <div class="alert__header">
        <span class="alert__message">
            {{ 'tuya-shipment.network-error-modal.server-error' | translate }}
        </span>
    </div>
    <div class="alert__details">
        <p>{{ 'tuya-shipment.network-error-modal.text1' | translate }}</p>
        <p>{{ 'tuya-shipment.network-error-modal.text2' | translate }}</p>
        <p>{{ 'tuya-shipment.network-error-modal.text3' | translate }}</p>
    </div>
</ng-template>
