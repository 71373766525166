import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';

import { OrdersSearchRequestDto, OrdersRespDto } from '../../core/models/dto';

export type HomeAction = Action<any, any>;

export const HOME_SET_PAGE_NUMBER = '[HOME] Set my orders page number';
export const HOME_SET_PAGE_SIZE = '[HOME] Set my orders page size';

export const HOME_LOAD_PAGE = '[HOME] Load page request';
export const HOME_LOAD_PAGE_SUCCESS = '[HOME] Page loaded successfully';
export const HOME_LOAD_PAGE_FAILURE = '[HOME] Page load failed';

export const HOME_LOAD_ROW_INFO = '[HOME] Expanded row info load';
export const HOME_LOAD_ROW_INFO_SUCCESS = '[HOME] Expanded row info load successfully';
export const HOME_LOAD_ROW_INFO_FAILURE = '[HOME] Expanded row info load failed';

export const HOME_COLLAPSE_ROW_INFO = '[HOME] Expanded row info collapse';
export const HOME_COLLAPSE_ROW_INFO_SUCCESS = '[HOME] Expanded row info collapse successfully';
export const HOME_COLLAPSE_ROW_INFO_FAILURE = '[HOME] Expanded row info collapse failed';

export const HOME_CLEAR_ROW_INFO = '[HOME] Expanded row info clear for all';

@Injectable()
export class HomeActions {
    @dispatch()
    loadPage = (req: OrdersSearchRequestDto): HomeAction => ({
        type: HOME_LOAD_PAGE,
        payload: req
    })
    loadPageSucceeded = (data: OrdersRespDto): HomeAction => ({
        type: HOME_LOAD_PAGE_SUCCESS,
        payload: data
    })
    loadPageFailed = (error: any): HomeAction => ({
        type: HOME_LOAD_PAGE_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    setPageNumber = (num: number): HomeAction => ({
        type: HOME_SET_PAGE_NUMBER,
        payload: num
    })
    @dispatch()
    setPageSize = (num: number): HomeAction => ({
        type: HOME_SET_PAGE_SIZE,
        payload: num
    })

    @dispatch()
    loadRowInfo = (req: {id: number, collapsed: boolean}): HomeAction => ({
        type: HOME_LOAD_ROW_INFO,
        payload: req
    })
    @dispatch()
    loadRowInfoSucceeded = (data: any): HomeAction => ({
        type: HOME_LOAD_ROW_INFO_SUCCESS,
        payload: data
    })
    loadRowInfoFailed = (error: any): HomeAction => ({
        type: HOME_LOAD_ROW_INFO_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    deleteRowInfo = (req: number): HomeAction => ({
        type: HOME_COLLAPSE_ROW_INFO,
        payload: req
    })

    @dispatch()
    clearAllRowInfo = (): HomeAction => ({
        type: HOME_CLEAR_ROW_INFO,
        payload: null
    })

}
