import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../models/redux.action';

export type MapAction = Action<any, any>;

export const SET_CENTER = '[MAP] Set center';

@Injectable()
export class MapActions {
    @dispatch()
    setCenter = (latLng): MapAction => ({
        type: SET_CENTER,
        payload: latLng
    })
}

