import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { select } from '@angular-redux-ivy/store';
import { Observable, takeUntil } from 'rxjs';
import { AuthActions } from '../../actions/auth.actions';
import { User } from 'app/auth/model/model';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    model: any = {};
    emailPattern = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$/;
    isUserNotValid = new BehaviorSubject<boolean>(false);
    showPassword = false;
    passwordInputType = 'password';
    user: User = { name: '', rememberMe: false };

    @ViewChild('passwordInput', { static: true }) passwordInput: ElementRef;

    @select(['auth', 'error'])
    readonly currentUserError$: Observable<boolean>;
    @select(['auth', 'isLoading'])
    readonly isLoading$: Observable<boolean>;

    private unsubscriber$ = new Subject();

    constructor(private authActions: AuthActions) {
        // Initialize this.user with a meaningful default
        this.user = {
            name: '',
            rememberMe: false
        };
    
        // Initialize this.model with a default structure
        this.model = {
            email: '',
            password: ''
        };

        try {
            const localDataString = localStorage.getItem('user');
            if (localDataString) {
                try {
                    const localData: User = JSON.parse(localDataString);
                    this.user = { ...this.user, ...localData }; // Merge with defaults to ensure all fields are initialized
                    this.model.email = this.user.name;
                } catch (parseError) {
                    console.error('Error parsing user data from local storage:', parseError);
                    // If parsing fails, reset local storage with default user
                    localStorage.setItem('user', JSON.stringify(this.user));
                }
            } else {
                // If no user data in local storage, initialize it with the default user
                localStorage.setItem('user', JSON.stringify(this.user));
            }
        } catch (storageError) {
            console.error('Error accessing local storage:', storageError);
        }
    }
    
    ngOnInit() {
        this.currentUserError$.pipe(takeUntil(this.unsubscriber$)).subscribe(this.isUserNotValid);
    }

    ngOnDestroy() {
        this.unsubscriber$.next(true);
        this.unsubscriber$.unsubscribe();
    }

    login() {
        localStorage.setItem('user', JSON.stringify({ name: this.model.email, rememberMe: this.user.rememberMe }));    
        this.authActions.getPermissionFCM({ username: this.model.email.trim(), password: this.model.password.trim() })
    }    

    togglePassword(): boolean {
        this.passwordInputType = this.showPassword ? 'password' : 'text';
        return this.showPassword = !this.showPassword;
    }

    onRememberMe() {
        this.user.rememberMe = !this.user.rememberMe;
    }
}
