import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgReduxModule } from '@angular-redux-ivy/store';
import { NgReduxRouterModule } from '@angular-redux-ivy/router';
import { NgReduxFormModule } from '@angular-redux-ivy/form';

import { StoreModule } from './store/module';
import { appRoutes } from './routes';
import { AppComponent } from './app.component';
import { CoreModule } from './core/module';
import { TranslateModule } from '@ngx-translate/core';

import selectLocale from '../environments/navigator';
import { AuthModule } from './auth/module';
import { HomeModule } from './home/module';
import { RegisterModule } from './register/module';
import { ShipmentModule } from './shipment/module';
import { ShipperModule } from './shipper/module';
import { ConfigService } from './app.config.service';
import { ApiWithAuthInterceptor } from './core/interceptors/api-with-auth.interceptor';
import { ApiWithAdminInterceptor } from './admin/interceptors/api-with-admin.interceptor';
import { ProfilesModule } from './profiles/module';
import { BackgroundColorDirective } from './directives/background-color.directive';
import { SharedModule } from './shared/module';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { ConfigLoader } from './app.config.loader.factory';
import { TrackingModule } from './tracking/module';
import { ShipmentEditModule } from './shipment-edit/module';
import { AdminModule } from './admin/admin.module';
import { BuilderModule } from './builder/builder.module';
import { NetworkTimeoutInterceptor } from './core/interceptors/network-timeout.interceptor';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { initializeApp as firebaseInitializeApp } from 'firebase/app';
import { StripeModule } from 'stripe-angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsConfigService } from './google-maps-config.service';

firebaseInitializeApp(environment.firebase);

export function googleMapsInitializer(configService: GoogleMapsConfigService): () => Promise<any> {
  return () => configService.loadScript();
}

@NgModule({ declarations: [AppComponent, BackgroundColorDirective],
    bootstrap: [AppComponent], imports: [StoreModule,
        BrowserModule,
        FormsModule,
        NgReduxModule,
        NgReduxRouterModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        RouterModule.forRoot(appRoutes, {}),
        NgReduxFormModule,
        TranslateModule.forRoot(),
        CoreModule,
        AuthModule,
        ProfilesModule,
        HomeModule,
        TrackingModule,
        RegisterModule,
        ShipmentModule,
        ShipmentEditModule,
        ShipperModule,
        SharedModule,
        TypeaheadModule.forRoot(),
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        AdminModule,
        BuilderModule,
        BrowserAnimationsModule,
        StripeModule.forRoot(""),
        GoogleMapsModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigLoader,
            deps: [ConfigService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: googleMapsInitializer,
            deps: [GoogleMapsConfigService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiWithAuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiWithAdminInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useFactory: selectLocale,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NetworkTimeoutInterceptor,
            multi: true
        },
        ConfigService,
        GoogleMapsConfigService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
