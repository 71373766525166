import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';
import { ShipmentType } from '../../core/models/shipment.model';
import { AddressDto, BoxSizeDto } from '../../core/models/dto';
import { OrderItemVo, OrderVo, Stop } from '../models/order.model';

export type ShipmentAction = Action<any, any>;

export const CREATE_SHIPMENT = '[SHIPMENT] Create';
export const OPEN_SHIPMENT = '[SHIPMENT] Open new';
export const OPEN_SHIPMENT_SUCCESS = '[SHIPMENT] Open new Success';
export const SET_SHIPMENT_TYPE = '[SHIPMENT] Set new shipment type';
export const INIT_FIRST_STOP = '[SHIPMENT] load first stop data';

export const SHIPMENT_DRAFT_CREATE = '[SHIPMENT] New shipment draft create request';
export const SHIPMENT_DRAFT_CREATE_SUCCESS = '[SHIPMENT] New shipment draft was created';
export const SHIPMENT_DRAFT_CREATE_FAILURE = '[SHIPMENT] New shipment draft create failed';

export const SHIPMENT_ORDER_ADD_REFERENCE_ID = '[SHIPMENT] Add reference ID to order';
export const SHIPMENT_ORDER_ADD_STOP = '[SHIPMENT] Add stop to order';
export const SHIPMENT_ORDER_UPDATE_STOP_ADDRESS = '[SHIPMENT] Update stop address';
export const SHIPMENT_ORDER_REMOVE_STOP_ADDRESS = '[SHIPMENT] Remove stop address';
export const SHIPMENT_ORDER_REMOVE_STOP = '[SHIPMENT] Remove stop from order';
export const SHIPMENT_ORDER_REMOVE_FIRST_STOP = '[SHIPMENT] Remove first stop from order';
export const SHIPMENT_ORDER_PICKUP_STOP_SUITE_NUMBER_ADD = '[SHIPMENT] Add to pickup order suite number';
export const SHIPMENT_ORDER_CURRENT_STOP_SELECT = '[SHIPMENT] select current order stop';

export const SHIPMENT_ORDER_DELETE = '[SHIPMENT] Order delete';
export const SHIPMENT_ORDER_DELETE_SUCCESS = '[SHIPMENT] Order delete success';
export const SHIPMENT_ORDER_UPDATE = '[SHIPMENT] Order update';
export const SHIPMENT_ORDER_UPDATE_SUCCESS = '[SHIPMENT] Order was updated successful';
export const SHIPMENT_ORDER_UPDATE_FAILURE = '[SHIPMENT] Order not updated';
export const SHIPMENT_ORDER_STOP_UPDATE = '[SHIPMENT] Order stop update';
export const SHIPMENT_ORDER_STOP_NAME_UPDATE = '[SHIPMENT] Order stop name update';
export const SHIPMENT_ORDER_ITEMS_UPDATE = '[SHIPMENT] Order items update';
export const SHIPMENT_ORDER_SAVE_TO_STATE = '[SHIPMENT] Order convert and save to state';
export const SHIPMENT_ADD_MISSED_DEFAULT_ITEM_TO_STOP = '[SHIPMENT] add default for stop if it\'s missed';
export const SHIPMENT_ORDER_INITIAL_PICKUP_CONTACTS_SAVED = '[SHIPMENT] Save contacts for the initial stop';
export const SHIPMENT_ORDER_INITIAL_PICKUP_DATE_SAVED = '[SHIPMENT] Save date for the initial stop';
export const SHIPMENT_ORDER_INITIAL_PICKUP_DETAILS_SUBMITTED = '[SHIPMENT] Date and contactas was saved => submit next step';
export const SHIPMENT_SET_STEP = '[SHIPMENT] New shipment set step';
export const SHIPMENT_DRIVER_TYPE_CREATE = '[SHIPMENT] Driver type was created';
export const NEW_CONFIRM_ORDER_CREATE = '[SHIPMENT] New confirm order create request';
export const NEW_CONFIRM_ORDER_CREATE_SUCCESS = '[SHIPMENT] New confirm order was created';
export const NEW_CONFIRM_ORDER_CREATE_FAILURE = '[SHIPMENT] New confirm order create failed';
export const NEW_SHIPMENT_READY_TO_CONFIRM = '[SHIPMENT] Shipment updated, ready to confirm';

export const OPEN_ORDER_CONFIRM = '[SHIPMENT] Open Order Confirm';
export const SHIPMENT_RESET = '[SHIPMENT] Shipment reset';
export const SHIPMENT_MANUAL_ORDER_RESET = '[SHIPMENT] Shipment reset invoked by user';

export const SHIPMENT_REROUTE = '[SHIPMENT] Shipment reroute to another shipment-page by wizardStep';

@Injectable()
export class ShipmentActions {

    shipmentReroute = (step: number): ShipmentAction => ({
        type: SHIPMENT_REROUTE,
        payload: step
    })

    @dispatch()
    manualOrderReset = (): ShipmentAction => ({
        type: SHIPMENT_MANUAL_ORDER_RESET,
        payload: null
    })

    @dispatch()
    reset = (): ShipmentAction => ({
        type: SHIPMENT_RESET,
        payload: null
    })

    @dispatch()
    openNew = (): ShipmentAction => ({
        type: OPEN_SHIPMENT,
        payload: null
    })

    @dispatch()
    openNewSuccess = (): ShipmentAction => ({
        type: OPEN_SHIPMENT_SUCCESS,
        payload: null
    })

    @dispatch()
    setNewShipmentType = (shipmentType: ShipmentType): ShipmentAction => ({
        type: SET_SHIPMENT_TYPE,
        payload: shipmentType
    })

    @dispatch()
    initFirstStop = (fullAddressString: string): ShipmentAction => ({
        type: INIT_FIRST_STOP,
        payload: fullAddressString
    })

    // first step
    @dispatch()
    newShipmentDraftCreate = (): ShipmentAction => ({
        type: SHIPMENT_DRAFT_CREATE,
        payload: null
    })

    newShipmentDraftCreateSucceeded = (orderVo: OrderVo): ShipmentAction => ({
        type: SHIPMENT_DRAFT_CREATE_SUCCESS,
        payload: orderVo
    })
    newShipmentDraftCreateFailed = (error: any): ShipmentAction => ({
        type: SHIPMENT_DRAFT_CREATE_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    shipmentOrderAddReferenceID = (refId?: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_ADD_REFERENCE_ID,
        payload: refId
    })

    @dispatch()
    shipmentOrderDelete = (orderId?: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_DELETE,
        payload: orderId
    })
    shipmentOrderDeleteSuccess = (): ShipmentAction => ({
        type: SHIPMENT_ORDER_DELETE_SUCCESS,
        payload: null
    })

    @dispatch()
    shipmentOrderUpdate = (isUpdateSelectedStop = false, isSavedStopChosen = false): ShipmentAction => ({
        type: SHIPMENT_ORDER_UPDATE,
        payload: {isUpdateSelectedStop, isSavedStopChosen}
    })
    shipmentOrderUpdateSucceeded = (orderVo: OrderVo, isUpdateSelectedStop: boolean, isSavedStopChosen = false): ShipmentAction => ({
        type: SHIPMENT_ORDER_UPDATE_SUCCESS,
        payload: { orderVo, isUpdateSelectedStop, isSavedStopChosen }
    })
    shipmentOrderUpdateFailed = (error: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_UPDATE_FAILURE,
        payload: null,
        error
    })

    shipmentOrderSaveToState = (orderVo: OrderVo): ShipmentAction => ({
        type: SHIPMENT_ORDER_SAVE_TO_STATE,
        payload: orderVo
    })

    @dispatch()
    shipmentAddMissedDefaultItemToStops = (defaultItem: BoxSizeDto, stopIds = []): ShipmentAction  => ({
        type: SHIPMENT_ADD_MISSED_DEFAULT_ITEM_TO_STOP,
        payload: {defaultItem, stopIds}
    })


    @dispatch()
    selectCurrentStop = (stopId: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_CURRENT_STOP_SELECT,
        payload: stopId
    })

    @dispatch()
    addStop = (stopTemplate: Stop, typeId: any, isFirstStop?: boolean, stopId?: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_ADD_STOP,
        payload: { typeId: typeId, stopTemplate: stopTemplate, isFirstStop: (isFirstStop || false), stopId }
    })

    @dispatch()
    updateStopAddress = (addr: AddressDto, stopId: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_UPDATE_STOP_ADDRESS,
        payload: { addr: addr, stopId: stopId }
    })

    @dispatch()
    removeStopAddress = (stopId: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_REMOVE_STOP_ADDRESS,
        payload: stopId
    })

    @dispatch()
    removeStop = (stopId: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_REMOVE_STOP,
        payload: stopId
    })

    @dispatch()
    removeFirstStop = (): ShipmentAction => ({
        type: SHIPMENT_ORDER_REMOVE_FIRST_STOP,
        payload: {}
    })

    @dispatch()
    stopSuiteNumberSave = (suiteNumber: string): ShipmentAction => ({
        type: SHIPMENT_ORDER_PICKUP_STOP_SUITE_NUMBER_ADD,
        payload: suiteNumber
    })

    // second step

    @dispatch()
    updateOrderStop = (stop: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_STOP_UPDATE,
        payload: stop
    })

    @dispatch()
    saveOrderStopName = (stop: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_STOP_NAME_UPDATE,
        payload: stop
    })

    @dispatch()
    updateOrderItems = (items: OrderItemVo[], targetStopId: any, currentStopId: any): ShipmentAction => ({
        type: SHIPMENT_ORDER_ITEMS_UPDATE,
        payload: { items, targetStopId, currentStopId }
    })

    // third step

    @dispatch()
    newShipmentReadyToConfirm = (): ShipmentAction => ({
        type: NEW_SHIPMENT_READY_TO_CONFIRM,
        payload: null,
    })

    @dispatch()
    newOrderConfirm = (data: object): ShipmentAction => ({
        type: NEW_CONFIRM_ORDER_CREATE,
        payload: data
    })

    newOrderConfirmSucceeded = (orderCode: string): ShipmentAction => ({
        type: NEW_CONFIRM_ORDER_CREATE_SUCCESS,
        payload: orderCode
    })

    newOrderConfirmFailed = (error: any): ShipmentAction => ({
        type: NEW_CONFIRM_ORDER_CREATE_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    newDriverType = (assignmentData: Object): ShipmentAction => ({
        type: SHIPMENT_DRIVER_TYPE_CREATE,
        payload: assignmentData
    })
}
