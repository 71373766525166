import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';

export const GET_ALL_ORDER_EVENTS = '[ORDER EVENTS] Get all order events';
export const GET_ALL_ORDER_EVENTS_SUCCESS = '[ORDER EVENTS] Get all order events successfully';
export const GET_ALL_ORDER_EVENTS_FAILURE = '[ORDER EVENTS] Get all order events failed';


export type OrderEventsAction = Action<any, any>;

@Injectable()
export class OrderEventsActions {
    @dispatch()
    getAllOrderEvents = (orderId: any): OrderEventsAction => ({
        type: GET_ALL_ORDER_EVENTS,
        payload: orderId
    })

    getAllOrderEventsSucceeded = (data: any): OrderEventsAction => ({
        type: GET_ALL_ORDER_EVENTS_SUCCESS,
        payload: data
    })

    getAllOrderEventsFailed = (error: any): OrderEventsAction => ({
        type: GET_ALL_ORDER_EVENTS_FAILURE,
        payload: {errorMessageKey: error.errorMessageKey},
        error
    })


}
