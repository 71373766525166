import { Injectable } from '@angular/core';
import { Action } from '../../core/models/redux.action';
import { dispatch } from '@angular-redux-ivy/store';

export const LOAD_TRACKING_INFO = '[Tracking] Load tracking info';
export const LOAD_TRACKING_INFO_SUCCEEDED = '[Tracking] Load tracking info succeeded';
export const LOAD_TRACKING_INFO_FAILED = '[Tracking] Load tracking info failed';
export type TrackingAction = Action<any, any>;

@Injectable()
export class TrackingActions {
    @dispatch()
    loadTrackingInfo = (query): TrackingAction => ({
        type: LOAD_TRACKING_INFO,
        payload: query
    })

    @dispatch()
    loadTrackingInfoSucceeded = (data): TrackingAction => ({
        type: LOAD_TRACKING_INFO_SUCCEEDED,
        payload: data
    })

    loadTrackingInfoFailed = (error: any): TrackingAction => ({
        type: LOAD_TRACKING_INFO_FAILED,
        payload: null,
        error
    })
}
