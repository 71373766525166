import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { OrderEventTitle, StopEventTitle, OrderEventActorRole, OrderEventActionId, EventType, EventItem, StopTypeId, DepartureTypeId, OrderEventAdmin } from '../../model/order-events.model';
import { StopEventType as StopEventActionId } from '../../../core/models/dto';
import { ActivatedRoute } from '@angular/router';
import { isEditOrderDisabled } from '../../../shipment-edit/utils/order.utils';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState } from '../../../store/model';

@Component({
    selector: 'tuya-event-main',
    templateUrl: './event-main.component.html',
    styleUrls: ['./event-main.component.scss'],
    providers: [DatePipe]

})
export class EventMainComponent implements OnInit {
    @Input() eventItem: EventItem;
    @Input() isShipperSuspended: boolean;
    @Input() statusId: number;

    // make titles & enums available to HTML
    EventTypes = EventType;
    OrderEventTitles = OrderEventTitle;
    OrderEventActions = OrderEventActionId;
    StopEventTitles = StopEventTitle;
    StopEventActions = StopEventActionId;
    DepartureTypes = DepartureTypeId;
    StopTypes = StopTypeId;
    OrderEventActorRoles = OrderEventActorRole;
    AdminRole = OrderEventAdmin;

    // for item count text
    translator;
    itemUnitSingular: String = '';
    itemUnitPlural: String = '';
    itemText: String = '';

    // for edit links
    orderId: number;

    expanded = false;
    //base64Image: SafeResourceUrl;
    _apiPrefix: string;

    constructor(translate: TranslateService,
        private domSanitizer: DomSanitizer,
        private _datePipe: DatePipe,
        private route: ActivatedRoute,
        private ngRedux: NgRedux<IAppState>) {
        this.translator = translate;
        this.route.queryParams.subscribe(params => {
            this.orderId = params['orderId'];
        });

    }
    ngOnInit() {
        // init the signature image
        //this.base64Image = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + this.eventItem.signature);

        // create item strings if Stop Completed
        let numItems: Number;

        if (this.eventItem && this.eventItem.eventTypeId === EventType.StopEvent &&
            this.eventItem.eventActionId === StopEventActionId.Complete) {
            numItems = this.countItems() || 1;
            // fetch singular/plural first
            this.translator.get(['tuya-my-orders.stop-event-text.package', 'tuya-my-orders.stop-event-text.packages'])
                .subscribe((res: object) => {
                    this.itemUnitSingular = res['tuya-my-orders.stop-event-text.package'];
                    this.itemUnitPlural = res['tuya-my-orders.stop-event-text.packages'];
                    // plug singular/plural into phrase
                    this.translator.get(
                        ['tuya-my-orders.stop-event-text.arrival-complete-pickup',
                            'tuya-my-orders.stop-event-text.arrival-complete-delivery'],
                        {
                            num: numItems,
                            units: numItems === 1 ? this.itemUnitSingular : this.itemUnitPlural,
                            location: this.eventItem.title
                        }
                    )
                        .subscribe((res2: object) => {
                            if (this.eventItem.stopTypeId === StopTypeId.Pickup) {
                                this.itemText = res2['tuya-my-orders.stop-event-text.arrival-complete-pickup'];
                            } else {
                                this.itemText = res2['tuya-my-orders.stop-event-text.arrival-complete-delivery'];
                            }
                        });
                });
        }
    }

    isEditOrderDisabled() {        
        return isEditOrderDisabled(this.statusId) || this.isShipperSuspended;
    }

    countItems(): number {
        let count = 0;
        if (this.eventItem && this.eventItem.items && this.eventItem.items.length) {
            this.eventItem.items.forEach((itemSize) => {
                count += itemSize.quantity;
            });
        }
        return count;
    }

    getCreatedContent(): string {
        if (this.eventItem.isCreatedOnMobile) {
            return 'tuya-my-orders.order-info.is-simpified';
        } else {
            return 'tuya-my-orders.order-info.not-simplified';
        }
    }

    getIcon(): any {
        switch (this.eventItem.eventActionId) {
            case OrderEventActionId.OfferAccepted: {
                return { 'accepted-icon': true };
            }
            case OrderEventActionId.OfferCreated: {
                return { 'created-icon': true };
            }
            case OrderEventActionId.OfferCancelled: {
                return { 'cancel-icon': true };
            }
            case OrderEventActionId.OfferReleased: {
                return { 'up-icon': true };
            }
            case OrderEventActionId.OfferConvertedGeneral: {
                return { 'arrows-icon': true };
            }
            case OrderEventActionId.CallToAction: {
                return { 'attention-icon': true };
            }
            case OrderEventActionId.ReOffered: {
                return { 'admin-re-offer-icon': true };
            }
            case OrderEventActionId.OrderScheduled:
            case OrderEventActionId.ScheduledOrderAccepted: {
                return { 'scheduled-icon': true };
            }         
            default:
                return {};
        }
    }

    getStopTypeIcon(): any {
        switch (this.eventItem.stopTypeId) {
            case StopTypeId.Pickup: {
                if (this.eventItem.eventActionId == OrderEventActionId.ItemException)
                    return { 'exception-icon': true };
                else
                    return { 'pickup-icon': true };
            }
            case StopTypeId.Delivery: {
                if (this.eventItem.eventActionId == OrderEventActionId.ItemException)
                    return { 'exception-icon': true };
                else
                    return { 'delivery-icon': true };
            }
        }
    }

    expandable(): boolean {
        // if Stop Attempted
        if (this.eventItem.eventTypeId === EventType.StopEvent &&
            this.eventItem.eventActionId === StopEventActionId.Departure) {
            return true;
        }
        // if Stop Completed
        if (this.eventItem.eventTypeId === EventType.StopEvent &&
            this.eventItem.eventActionId === StopEventActionId.Complete) {
            return true;
        }
        // Item Exeption
        if (this.eventItem.eventTypeId === EventType.StopEvent &&
            this.eventItem.eventActionId === StopEventActionId.ItemException) {
            return true;
        }        
        return false;
    }

    toggleExpanded(): void {
        this.expanded = !this.expanded;
    }

    getDateRange(): string {
        // shorten the date range to DATE: TIME1 - TIME2 if same day
        let dateRange: string;
        const startRangeDate = new Date(this.eventItem.pickupNoEarlierThan);
        const endRangeDate = new Date(this.eventItem.pickupNoLaterThan);

        if (startRangeDate.getDate() === endRangeDate.getDate()) {
            dateRange = this._datePipe.transform(this.eventItem.pickupNoEarlierThan, 'M/dd/yyyy')
                + ', ' + this._datePipe.transform(this.eventItem.pickupNoEarlierThan, 'shortTime')
                + ' - ' + this._datePipe.transform(this.eventItem.pickupNoLaterThan, 'shortTime');
        } else {
            dateRange = this._datePipe.transform(this.eventItem.pickupNoEarlierThan, 'M/dd/yyyy, h:mm a')
                + ' - ' + this._datePipe.transform(this.eventItem.pickupNoLaterThan, 'M/dd/yyyy, h:mm a');
        }
        return dateRange;
    }
}
