﻿import {
    Component,
    OnInit,
    ComponentFactoryResolver,
    ViewContainerRef,
    ChangeDetectorRef,
    OnDestroy
} from '@angular/core';
import { NgRedux, select } from '@angular-redux-ivy/store';
import { Observable, takeUntil } from 'rxjs';
import { IAppState } from '../../../store/model';
import { UserProfile } from '../../../auth/model/model';
import { HomeComponent } from '../../components/home.component';
import { ShipperWelcomeComponent } from '../../components/shipper/welcome.component';
import { AuthActions } from '../../../auth/actions/auth.actions';
import { ShipperStatuses } from '../../../shipper/model/model';
import { Subject } from 'rxjs';

@Component({
    template: ''
})

export class HomePageComponent implements OnInit, OnDestroy {
    // ToDo: add logic to render different components in Home Page
    component: HomeComponent | ShipperWelcomeComponent = HomeComponent;

    @select(['auth', 'currentUser'])
    readonly currentUser$: Observable<UserProfile>;
    @select(['shipper', 'shipperStatus'])
    readonly shipperStatus$: Observable<ShipperStatuses>;

    private unsubscibeShipperStatus$ = new Subject();

    constructor(private ngRedux: NgRedux<IAppState>,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private ref: ChangeDetectorRef,
        private authActions: AuthActions) {
    }

    ngOnInit() {
        this.currentUser$.subscribe(value => {
            if (value) {
                this.renderComponent();
                this.ref.markForCheck();
            }
        });

        this.shipperStatus$
            .pipe(takeUntil(this.unsubscibeShipperStatus$))
            .subscribe(shipperStatus => {
                const { auth } = this.ngRedux.getState();

                if (!shipperStatus || !auth.loggedIn) return;

                this.renderComponent();
            });
    }

    ngOnDestroy() {
        this.unsubscibeShipperStatus$.next(true);
        this.unsubscibeShipperStatus$.unsubscribe();
    }

    private renderComponent() {
        let componentFactory, componentRef;
        const state = this.ngRedux.getState();
        const currentUser = state.auth.currentUser;        
        if (!currentUser || !currentUser.shipperProfile) {
            console.error('Shipper profile is null or undefined.');
            return;
        }

        const shipperStatus = state.shipper['shipperStatus'];
    
        this.component = ShipperWelcomeComponent;
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component as any);
        this.viewContainerRef.clear();
        componentRef = this.viewContainerRef.createComponent(componentFactory);
    
        Object.assign(componentRef.instance, {
            username: currentUser.shipperProfile.firstName || '',
            isEmailConfirmed: shipperStatus.isEmailConfirmed,
            canCreateShipment: shipperStatus.canCreateShipment,
            hasOrders: shipperStatus.hasOrders,
            suspended: shipperStatus.suspended,
            isUnderTerms: shipperStatus.isUnderTerms,
            hasValidCreditCard: shipperStatus.hasValidCreditCard,
            onVerficationEmailRequest: () => { this.authActions.requestVerificationEmail(); }
        });
    }
    

    /*private renderComponent() {
        let componentFactory, componentRef;
        const state = this.ngRedux.getState();
        const currentUser = state.auth.currentUser;
        const shipperStatus = state.shipper['shipperStatus'];

        // TODO: add logic to detect if user is Shipper or not
        this.component = ShipperWelcomeComponent;
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component as any);
        this.viewContainerRef.clear();
        componentRef = this.viewContainerRef.createComponent(componentFactory);
        Object.assign(componentRef.instance, {
            username: currentUser.shipperProfile ? currentUser.shipperProfile.firstName : '',
            isEmailConfirmed: shipperStatus.isEmailConfirmed,
            canCreateShipment: shipperStatus.canCreateShipment,
            hasOrders: shipperStatus.hasOrders,
            suspended: shipperStatus.suspended,
            isUnderTerms: shipperStatus.isUnderTerms,
            hasValidCreditCard: shipperStatus.hasValidCreditCard,
            onVerficationEmailRequest: () => { this.authActions.requestVerificationEmail(); }
        });
    }*/
}
