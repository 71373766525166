import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { first, Observable } from 'rxjs';
import { AlertActions } from 'app/core/actions/alert.actions';
import { AlertType, Alert } from 'app/core/models/alert.model';
import { locale } from '../i18n/builder.en';
import { AuthGuard } from 'app/auth/guards/auth.guard';
import { NgRedux, select } from '@angular-redux-ivy/store';
import { IAppState } from 'app/store/model';
import { ProfilesActions } from 'app/profiles/actions/profiles.actions';
import { AuthActions } from 'app/auth/actions/auth.actions';

@Injectable()
export class CanActivateBuilderGuard implements CanActivate {
  @select(['shipper', 'shipperStatus', 'canCreateShipment'])
  readonly canCreateShipment$: Observable<boolean>;

  constructor(private alertActions: AlertActions,
              private profileActions: ProfilesActions,
              private authActions: AuthActions,
              private ngRedux: NgRedux<IAppState>,
              private authGuard: AuthGuard) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

      const alert: Alert = {
        message: locale.data.canNavigate.message,
        type: AlertType.Cancellable,
        callback: (accepted: boolean) => {
          return new Promise<boolean>((resolve, reject) => {
            if (accepted) {
              this.profileActions.goToCompanyProfile();
              this.profileActions.changeManageCompanyTab(3);
            }
            resolve(accepted);
          });
        }
      };

      return new Observable<boolean>(observer => {
        this.authGuard.canActivate(route).pipe(first()).subscribe((data: boolean) => 
        {
          const shipperStatus = this.ngRedux.getState().shipper['shipperStatus'];
          if (!data || shipperStatus.suspended) {
            observer.next(false);
          } else {
            if (shipperStatus.canCreateShipment) {
              observer.next(true);
            } else {
              if (shipperStatus.hasOrdsers) {
                this.authActions.goToPageOrders();
              } else {
                this.authActions.goToHome();
              }
              observer.next(false);
            }
          }
        });
      });
  }
}
