import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { StopType } from '../../models/stop.model';
import { StopDto } from '../../../core/models/dto';
import { BuilderActions } from '../../actions/builder.actions';
import { OrderItemDto } from '../../models/item.model';
import {
  phoneNumberLengthValidator,
  optionalValidator,
  emailTldValidator,
} from '../../validators/phone.email.validators';
import {
  timeInputRequiredValidator,
  timeWindowStartValidator,
  minTimeWindowValidator,
  isTimeFormValid  
} from '../../validators/time.validators';

import { formatDisplayedTime } from '../../utils/time';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import moment, { Moment } from 'moment';
@Component({
  selector: 'tuya-builder-stop',
  templateUrl: './builder-stop.component.html',
  styleUrls: ['./builder-stop.component.scss']
})
export class BuilderStopComponent implements OnInit, OnChanges {
  @Input() stopType: number;
  @Input() stopIndex: number;
  @Input() stop: StopDto;
  @Input() currentStep: number;
  @Input() items: OrderItemDto[];
  @Input() timeSelections: Array<number>;
  @Input() submitErrors: object;
  @Input() setTimesInState: Function;
  @Input() easyStartTime: string;
  @Input() easyEndTime: string;
  @Input() easyStartTimeDisplay: string;
  @Input() easyEndTimeDisplay: string;
  @Input() isEditMode = false;
  @Input() materialLocked = false;
  @Input() nonMaterialLocked = false;
  @Input() isScheduledOrder = false;
  @Input() pickupDate = '';
  @Input() vehicleType = null;

  /**
   * Passed into components to distinguish between pickup and delivery stops.
   */
  idkey: string;
  stopForm: UntypedFormGroup;
  public StopType;
  dateDropdownOptions: any[];
  public stopTypeText: string;
  public stopTypeTextCapitalized: string;

  customDisplayFormat = 'hh:mm a';
  timeFormatString = 'HH:mm:ss';
  dateFormatString = 'YYYY-MM-DD';
  date: Date;

  stopItemsForVehicleSelection$ = new BehaviorSubject([]);

  constructor(private builderActions: BuilderActions,
    private formBuilder: UntypedFormBuilder,
    private translator: TranslateService) {
    this.StopType = StopType;
    this.dateDropdownOptions = [];
  }

  ngOnInit() {
    switch (this.stopType) {
      case StopType.Pickup: {
        this.idkey = 'pickup';
        this.stopTypeText = this.translator.instant('stopType.pickup');
        this.stopTypeTextCapitalized = this.translator.instant('stopType.pickupCapitalized');
        break;
      }
      case StopType.Delivery: {
        this.idkey = 'delivery';
        this.stopTypeText = this.translator.instant('stopType.delivery');
        this.stopTypeTextCapitalized = this.translator.instant('stopType.deliveryCapitalized');
        break;
      }
      case StopType.Return: {
        this.idkey = 'return';
        break;
      }
      case StopType.DeliveryWithReturn: {
        this.idkey = 'deliverywithreturn';
        break;
      }
    }
    this.initDateDropdown();
    this.initStopForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue) {
      const a = changes.items.currentValue;
      const b = [];
      for (let i = 0; i < a.length; i++) {
        const item = {
          sizeTypeId: a[i].sizeTypeId,
          piecesCount: a[i].piecesCount,
          weight: a[i].weight,
          referenceId: a[i].referenceId
        };
        b.push(item);
      }
      this.stopItemsForVehicleSelection$.next(b);
    }
  }

  onEditStop(event) {
    const stopKey = event.target.dataset.key;
    this.stop[stopKey] = event.target.value;

    // turn off isEmailTracking toggle if invalid email or empty email
    if (stopKey === 'contactEmail' &&
      (this.stopForm.controls.addressContactForm['controls'].contactEmail.invalid || !event.target.value)
    ) {
      this.stop.isEmailTrackingLink = false;
    }
    this.builderActions.onEditStop(this.stop, this.stopIndex);
  }

  getItemQuantity(): number {
    let itemSum = 0;
    for (let i = 0; i < this.items.length; i++) {
      itemSum += this.items[i].piecesCount;
    }
    return itemSum;
  }

  getTotalWeight(): number {
    let weightSum = 0;
    for (let i = 0; i < this.items.length; i++) {
      weightSum += (this.items[i].weight * this.items[i].piecesCount);
    }
    return weightSum;
  }

  // adds up all items with the same sizeType as the argument
  getSizeTotal(sizeType: number): number {
    let sizeSum = 0;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].sizeTypeId === sizeType) {
        sizeSum += (this.items[i].piecesCount);
      }
    }
    return sizeSum;
  }

  // checks to see if there are any items with a size greater than the argument
  getSizeTotalGreaterThan(sizeType: number): number {
    let sum = 0;
    for (let i = sizeType; i < 5; i++) {
      sum += this.getSizeTotal(i + 1);
    }
    return sum;
  }

  initDateDropdown() {
    const labelFormat = 'MM/DD/YYYY';

    if (this.materialLocked) { // for in-progress orders in edit mode
      // only add the saved date to the date dropdown
      const date = moment(this.stop.pickupNoEarlierThan);
      this.dateDropdownOptions.push(date.format(labelFormat));
    } else {
      // init date dropdown normally with today and tomorrow's dates
      this.dateDropdownOptions.push(new Date().toISOString());
    }
  }

  initStopForm() {
    // Define address contact form group
    const addressContactForm = this.formBuilder.group({
      suiteNumber: [this.stop.address.suiteNumber, Validators.maxLength(10)],
      nameOrDescription: [
        this.stop.nameOrDescription,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(75)
        ]
      ],
      contactName: [
        this.stop.contactName,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50)
        ]
      ],
      contactPhoneNumber: [this.stop.contactPhoneNumber, phoneNumberLengthValidator()],
      contactEmail: [
        this.stop.contactEmail,
        [
          Validators.maxLength(250),
          optionalValidator([Validators.email, emailTldValidator()])
        ]
      ]
    });

    // Define time form controls
    const timeFormControls = {
      date: this.formBuilder.control(new Date().toISOString()),
      pickupNoEarlierThan: [
        formatDisplayedTime(this.easyStartTime, this.customDisplayFormat),
        timeInputRequiredValidator()
      ],
      pickupNoLaterThan: [
        formatDisplayedTime(this.easyEndTime, this.customDisplayFormat),
        timeInputRequiredValidator()
      ]
    };

    // Create the time form group with controls
    const timeForm = this.formBuilder.group(timeFormControls);

    // Add validators to the time form group
    timeForm.setValidators([
      runValidatorsInOrder([timeWindowStartValidator(), minTimeWindowValidator()])
    ]);

    // Define item selector form group
    const itemSelectorForm = this.formBuilder.group({
      itemList: this.formBuilder.array([]),
      itemsDescription: [this.stop.itemsDescription, Validators.maxLength(250)]
    });

    // Define instructions form group
    const instructionsForm = this.formBuilder.group({
      pickupNotes: [this.stop.notes, Validators.maxLength(500)],
      deliveryNotes: [this.stop.notes, Validators.maxLength(500)]
    });

    // Combine all form groups into the main form group
    this.stopForm = this.formBuilder.group({
      addressContactForm: addressContactForm,
      timeForm: timeForm,
      itemSelectorForm: itemSelectorForm,
      instructionsForm: instructionsForm
    });

    // Custom validator function to run validators in a specific order
    function runValidatorsInOrder(validators: ValidatorFn[]): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        for (const validator of validators) {
          const validationError = validator(control);
          if (validationError !== null) return validationError; // Return the first validation error encountered          
        }

        return null; // Return null if all validators pass
      };
    }
  }

  get isStopFormInvalid() {
    const hasAddress = this.stop.address.addressLine;
    const addressContactFormValid = this.stopForm.controls['addressContactForm'].valid;
    // timeForm check allows startTimeBeforeCurrentTime error so the user is presented with a notification when "Next" is clicked
    let timeFormValid = isTimeFormValid(this.stopForm.controls['timeForm'], this.timeSelections[0]);
    const itemSelectorFormValid = this.stopForm.controls['itemSelectorForm'].valid;
    const instructionsFormValid = this.stopForm.controls['instructionsForm'].valid;

    // do not disable next button in edit mode if no changes were made
    if (this.isEditMode && !this.stopForm.dirty && this.stopForm.valid) {
      return false;
    }

    // ignore time errors if order is in progress
    if (this.materialLocked) {
      timeFormValid = true;
    }

    // if all variables are true, returns false to enable button
    return !(hasAddress && addressContactFormValid && timeFormValid && itemSelectorFormValid && instructionsFormValid);
  }
}
