import { Component, Input } from '@angular/core';
import {StopStatusId, StopTypeId} from '../../../core/models/dto';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'tuya-expanded-order-info',
  templateUrl: './expanded-order-info.component.html',
  styleUrls: ['./expanded-order-info.component.scss']
})
export class ExpandedOrderInfoComponent {
  @select(['home', 'myOrders', 'ordersDetails', 'isLoading'])
  readonly isLoading$: Observable<boolean>;

  StopTypeId: typeof StopTypeId = StopTypeId;
  @Input() currentOrderInfo: any | undefined;

  constructor() {}

  isStopCompleted(stopStatus: number): boolean {
      return (stopStatus === StopStatusId.Completed || stopStatus === StopStatusId.CompletedWithException);
  }

  getClassName(stopType: number) {
      return {
          'picked-up' : stopType === StopTypeId.PickedUp,
          'delivered': stopType === StopTypeId.Delivered || stopType === StopTypeId.DeliveredWithReturn,
          'combo': stopType === StopTypeId.DeliveredWithPickUp
      };
  }

}
