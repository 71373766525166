<tuya-terms-of-service (tosTermsClick)="openModal(termsOfService)"
    (tosPrivacyClick)="openModal(privacyPolicy)"></tuya-terms-of-service>
<ng-template #termsOfService let-termsOfService="termsOfService">
    <div class="modal-header">
        <button title="close" type="button" class="close-btn" (click)="closeModal()">
            <div class="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
            <i class="fa fa-close fa-lg"></i></button>
        <span class="title" [translate]="'tuya-tos-policy.tos'"></span>
    </div>
    <div class="modal-body" [innerHTML]="termsOfServiceText">
    </div>
    <div class="modal-footer">
        <span class="date">
            {{'tuya-tos-policy.effective-date' | translate}}
            {{termsOfServiceEffectiveDate | date: 'M/dd/yyyy'}}
        </span>
    </div>
</ng-template>

<ng-template #privacyPolicy>
    <div class="modal-header">
        <button title="close" type="button" class="close-btn" (click)="closeModal()">
            <div class="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
            <i class="fa fa-close fa-lg"></i></button>
        <span class="title" [translate]="'tuya-tos-policy.policy'"></span>
    </div>
    <div class="modal-body" [innerHTML]="privacyPolicyText">
    </div>
    <div class="modal-footer">
        <span class="date">
            {{'tuya-tos-policy.effective-date' | translate}}
            {{privacyPolicyEffectiveDate | date: 'M/dd/yyyy'}}
        </span>
    </div>
</ng-template>