import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';
import { CreditCardDto } from '../../core/models/payment.dto';


export type BillingAction = Action<any, any>;

export const GET_PAYMENT_METHODS = '[Billing] Get payment methods';
export const GET_PAYMENT_METHODS_SUCCESS = '[Billing] Get payment methods succeeded';
export const GET_PAYMENT_METHODS_FAIL = '[Billing] Get payment methods failed';
export const ADD_CREDIT_CARD = '[Billing] Add credit card';
export const ADD_CREDIT_CARD_SUCCESS = '[Billing] Add credit card success';
export const ADD_CREDIT_CARD_FAILURE = '[Billing] Add credit card failed';

export const OPEN_PAYMENT_MODAL = '[Billing] Open payment modal';
export const CLOSE_PAYMENT_MODAL = '[Billing] Close payment modal';
export const TURN_ON_VALIDATE_CC = '[Billing] Turn on validate CC';
export const TURN_OFF_VALIDATE_CC = '[Billing] Turn off validate CC';

@Injectable()
export class BillingActions {
    @dispatch()
    getPaymentMethods = (): BillingAction => ({
        type: GET_PAYMENT_METHODS,
        payload: null
    })

    getPaymentMethodsSucceeded = (creditCards: CreditCardDto[]): BillingAction => ({
        type: GET_PAYMENT_METHODS_SUCCESS,
        payload: creditCards
    })

    getPaymentMethodsFailed = (error): BillingAction => ({
        type: GET_PAYMENT_METHODS_FAIL,
        payload: null,
        error
    })

    @dispatch()
    addCreditCard = (creditCard: CreditCardDto): BillingAction => ({
        type: ADD_CREDIT_CARD,
        payload: creditCard
    })

    addCreditCardSucceeded = (creditCard: CreditCardDto): BillingAction => ({
        type: ADD_CREDIT_CARD_SUCCESS,
        payload: creditCard
    })

    addCreditCardFailed = (error: any): BillingAction => ({
        type: ADD_CREDIT_CARD_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    openPaymentModal = (): BillingAction => ({
        type: OPEN_PAYMENT_MODAL,
        payload: null
    })

    @dispatch()
    closePaymentModal = (): BillingAction => ({
        type: CLOSE_PAYMENT_MODAL,
        payload: null
    })

    @dispatch()
    turnOnValidateCC = (): BillingAction => ({
        type: TURN_ON_VALIDATE_CC,
        payload: null
    })

    @dispatch()
    turnOffValidateCC = (): BillingAction => ({
        type: TURN_OFF_VALIDATE_CC,
        payload: null
    })

}
