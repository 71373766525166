import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Alert, AlertType, AlertCallback } from '../../../core/models/alert.model';
import { AlertActions } from '../../../core/actions/alert.actions';

@Component({
    selector: 'tuya-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit, OnDestroy {
    min = '01';
    sec = '00';
    myinterval: any;

    public AlertType = AlertType;
    public hasErrorDetails: boolean;

    @Input() alert: Observable<Alert>;
    modalRef: BsModalRef;
    
    @ViewChild('template', {static: true}) template;
    callback: AlertCallback;

    private modalConfig = {
        class: 'modal-lg',
        animated: false
    };

    constructor(private modalService: BsModalService, private alertActions: AlertActions) {
        this.modalService.onHide.subscribe((reason: string | null) => {
            if (reason === 'backdrop-click') {
                this.executeCallback(false);
            }
        });
    }

    ngOnInit() {
        this.alert?.subscribe(data => {
            if (data !== null) {
                // if multiple alerts, hide previous alerts so only most recent is shown
                if (this.modalRef) {
                    this.modalRef.hide();
                }
                if (data.type === AlertType.Timeout || data.type === AlertType.Unknown) {
                    this.alertActions.rejectIncomingAlerts();
                }
                if (data.type === AlertType.NewPrice) {
                    this.startCountDown();
                }
                this.modalRef = this.modalService.show(this.template, this.modalConfig);
                this.hasErrorDetails = data && typeof data.error === 'object';
                this.callback = data.callback;
            }
        });
    }

    ngOnDestroy() {
        clearInterval(this.myinterval);
    }

    startCountDown() {
        this.min = '01';
        this.sec = '00';
        let seconds = 59;
        let timeStarted = false;
        this.myinterval = setInterval(() => {
            if (this.min !== '00') { this.min = '00'; }
            if (this.sec === '00' && timeStarted) {
                this.executeCallback(false);
            }
            this.sec = seconds < 10 ? `0${seconds}` : `${seconds}`;
            seconds = seconds - 1;
            timeStarted = true;
        }, 1000);
    }

    async executeCallback(accepted: boolean) {
        if (!this.callback) {
            this.closeModal();
            return;
        }
    
        try {
            await this.callback(accepted);
        } catch (err) {
            console.error(err);
        } finally {
            this.closeModal();
        }
    }

    closeModal() {
        clearInterval(this.myinterval);
        if (this.modalRef) {
            this.modalRef.hide();
            this.alertActions.hide(); // this resets alert and allows incoming alerts
        }
    }

    listErrorKeyValues(errorDetail) {
        // map all keys and filter if value is not null
        return Object.keys(errorDetail).filter(key => errorDetail[key]);
    }
}
