import { Injectable } from '@angular/core';
import { Action } from '../../core/models/redux.action';
import { Stop } from '../models/order.model';
import { dispatch } from '@angular-redux-ivy/store';

export type GeneralOfferAction = Action<any, any>;


export const START_PRICE_OPTIMIZATION_CREATE = '[SHIPMENT] Shipment request start optimization created';
export const START_PRICE_OPTIMIZATION_SUCCESS = '[SHIPMENT] Shipment request start optimization succeeded';
export const START_PRICE_OPTIMIZATION_FAILURE = '[SHIPMENT] Shipment request start optimization failed';
export const START_PRICE_OPTIMIZATION_TIMEFRAMES_FAILURE = '[SHIPMENT] Shipment request start optimization failed ' +
    'because of invalid timeframes';
export const CLEAR_PRICE_OPTIMIZATION_TIMEFRAMES_ERRORS = '[SHIPMENT] Clear Price optimisation timeframe errors';

@Injectable()
export class GeneralOfferActions {

    requestStartPriceOptimization = (orderId: any): GeneralOfferAction => ({
        type: START_PRICE_OPTIMIZATION_CREATE,
        payload: orderId
    })

    requestStartPriceOptimizationSucceeded = (price: number): GeneralOfferAction => ({
        type: START_PRICE_OPTIMIZATION_SUCCESS,
        payload: price
    })

    requestStartPriceOptimizationFailed = (error: any): GeneralOfferAction => ({
        type: START_PRICE_OPTIMIZATION_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    requestStartPriceOptimizationTimeframesFailed = (unservedStopIds: Array<any>,
                                                     stopList: Array<Stop>): GeneralOfferAction => ({
        type: START_PRICE_OPTIMIZATION_TIMEFRAMES_FAILURE,
        payload: { unservedStopIds, stopList }
    })

    @dispatch()
    clearPriceOptimizationTimeframesErrors = (): GeneralOfferAction => ({
        type: CLEAR_PRICE_OPTIMIZATION_TIMEFRAMES_ERRORS,
        payload: null
    })
}
