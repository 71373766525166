import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BuilderInputComponent } from '../../builder/containers/builder-input/builder-input.component';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { BuilderSelectors } from '../selectors/builder.selectors';
import { select } from '@angular-redux-ivy/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CanDeactivateBuilderGuard implements CanDeactivate<BuilderInputComponent> {
  @select (BuilderSelectors.getIsNavPromptRequired) isPromptRequired$: Observable<boolean>;

  translator;

  constructor(private translate: TranslateService) {
    this.translator = translate;
  }

  canDeactivate(
    component: BuilderInputComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const alertActions = new AlertActions();
      let isPromptRequired = true;

      alertActions.allowIncomingAlerts();

      this.isPromptRequired$.subscribe(
        result => isPromptRequired = result
      );

      return new Promise<boolean>((resolve, reject) => {

        /**
         * Resolve promise supplied to route guard.
         * @param res Boolean
         */
        const resolver = (res: boolean) => {
          resolve(res);
        };

        /**
         * Promise supplied to modal confirmation dialog.
         * @param accepted Boolean
         */
        const confirmationCallback = (accepted: boolean) => {
          return new Promise<boolean>((res, rej) => {
            res(accepted);
            resolver(accepted);
          });
        };

        if (!isPromptRequired) {
          resolver(true);
          return;
        }

        // Dialog when aborting shipment builder
        alertActions.show({
          type: AlertType.Cancellable,
          message: this.translator.instant('validation.notSaved'),
          callback: confirmationCallback
        });
      });
    }
}
