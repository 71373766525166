import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from './services/translation-loader.service';
import { AlertComponent } from './components/alert/alert.component';
import { TermsContainerComponent } from './containers/terms-container/terms-container.component';
import { TermsComponent } from './components/terms/terms.component';
import { HeaderComponent } from './components/header/header.component';
import { ExpandedComponent } from './components/expanded/expanded.component';

import { BsModalService } from 'ngx-bootstrap/modal';
//import { BaseRequestOptions, HttpModule } from '@angular/http';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AlertActions } from './actions/alert.actions';
import { MapActions } from './actions/map.actions';
import { TermsOfServiceActions } from './actions/termsOfService.actions';

import { CoreEpics } from './epics/core.epics';
import { PoolsEpics } from './epics/pools.epics';

import { locale as english } from './i18n/core.en';
import { NgReduxFormModule } from '@angular-redux-ivy/form';
import { TermsOfServiceService } from './services/termsOfService.service';
import { BusinessEntityService } from './services/business.entity.service';
import { ShipperService } from './services/shipper.service';
import { ItemsSizesService } from './services/items-sizes.service';
import { ItemsSizesActions } from './actions/items-sizes.actions';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { OrderSummaryBarComponent } from './components/order-summary-bar/order-summary-bar.component';
import { SelectModule } from './components/pool-select/select/select.module';
import { PoolsActions } from './actions/pools.actions';
import { PoolsService } from './services/pools.service';
import { StatesActions } from './actions/states.actions';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationActions } from './actions/notification.actions';
import { OrdersService } from './services/orders.service';
import { RouteSubstituteService } from './services/route-substitute.service';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { DirectivesModule } from '../directives/directives.module';
import { NotificationPanelContainerComponent } from './containers/notification-panel-container/notification-panel-container.component';
import { NotificationEpics } from './epics/notification.epics';
import { PushNotificationService } from './services/push-notification.service';
import { NotificationService } from './services/notification.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar-portable';
import { FormatNotificationTemplatePipe } from './pipes/formatNotificationTemplate.pipe';
import { PaymentService } from './services/payment.service';
import { PaymentFrameComponent } from './components/payment-frame/payment-frame.component';
import { BackVersionService } from './services/back-version.service';
import { LoadImageFromUrlPipe } from './pipes/loadImgFromUrl.pipe';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { SharedModule } from '../shared/module';
import { Page404Component } from './containers/page-404/page-404.component';

// Depends on custom declared module "*.json" in typings.d.ts
// import * as config from '../../assets/config/config.json';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { NgmatCoreModule } from './ngmat.core.module';
import { StripePaymentFrame } from './components/stripe-payment-frame/stripe-payment-frame.component';
import { StripeModule } from 'stripe-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

export const COMPONENTS = [
    AlertComponent,
    TermsContainerComponent,
    TermsComponent,
    ExpandedComponent,
    HeaderComponent,
    OrderSummaryBarComponent,
    NotificationListComponent,
    NotificationPanelContainerComponent,
    NotificationPanelComponent,
    FormatNotificationTemplatePipe,
    LoadImageFromUrlPipe,
    PaymentFrameComponent,
    StripePaymentFrame,
    Page404Component,
    AdminHeaderComponent,
    TooltipComponent
];


@NgModule({
    declarations: COMPONENTS,
    imports: [CommonModule,
        CollapseModule.forRoot(),
        ProgressbarModule.forRoot(),
        TooltipModule.forRoot(),
        TranslateModule, RouterModule,
        //HttpRequest, 
        NgReduxFormModule,
        FormsModule, ReactiveFormsModule,
        BsDropdownModule.forRoot(), SelectModule, DirectivesModule, SharedModule,
        PerfectScrollbarModule,
        NgmatCoreModule,
        StripeModule
    ],
    exports: [...COMPONENTS, TranslateModule, RouterModule, NgReduxFormModule],
    providers: [AlertActions, TermsOfServiceActions, MapActions,
        NotificationActions,
        TranslationLoaderService,
        CoreEpics,
        PoolsEpics,
        NotificationEpics,
        BsModalService,
        ItemsSizesService,
        TermsOfServiceService,
        ShipperService,
        PoolsService,
        BusinessEntityService,
        ItemsSizesActions,
        PoolsActions,
        StatesActions,
        //BaseRequestOptions,
        SelectModule,
        NotificationService,
        PushNotificationService,
        OrdersService,
        RouteSubstituteService,
        PaymentService,
        BackVersionService,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}
    ]
})

export class CoreModule {
    constructor(private translate: TranslateService,
                private translationLoader: TranslationLoaderService) {
        /* Config translation in root module */
        this.translate.addLangs(['en']);
        this.translate.setDefaultLang('en');
        this.translate.use('en');
        /* Load translations in each module/component as needed */
        this.translationLoader.loadTranslations(english);
    }
}
