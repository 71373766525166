import { Component, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';

import { WizardStep } from '../../models/ui.model';
import { ShipmentSelectors } from '../../selectors/shipment.selectors';
import { UIActions } from '../../actions/ui.actions';
import { DriverDto, PoolDto, PriceOptimizationDto } from '../../../core/models/dto';
import { PoolsActions } from '../../../core/actions/pools.actions';
import { ShipmentActions } from '../../actions/shipment.actions';
import { DriverAssignmentTypeId, OrderItemVo, Stop } from '../../models/order.model';
import { OrderEditMode, SelectedCandidatesDto, SummaryDto } from '../../../core/models/order.dto';
import { OrderSelectors } from '../../selectors/order.selectors';
import { OrderStopsActions } from '../../actions/order-stops.actions';
import { DriversSelectors } from '../../../core/selectors/pools.selector';
import { IAppState } from '../../../store/model';
import { ShipmentEditSelectors } from '../../../shipment-edit/selectors/shipment-edit.selectors';
import { CreditCardDto } from '../../../core/models/payment.dto';
import { PaymentActions } from '../../actions/payment.actions';
import { GeneralOfferActions } from '../../actions/general-offer.actions';
import { UISelectors } from '../../selectors/ui.selectors';

@Component({
  selector: 'tuya-shipment-summary-page',
  templateUrl: './shipment-summary-page.component.html',
  styleUrls: ['./shipment-summary-page.component.scss']
})
export class ShipmentSummaryPageComponent implements OnInit {

    WizardStep: typeof WizardStep = WizardStep;

    @select(ShipmentSelectors.wizardStepSelector)
    wizardStep$: Observable<number>;
    @select(OrderSelectors.isDirectOrderSelector)
    isDirectOrder$: Observable<boolean>;
    @select (['core', 'pools', 'pools'])
    pools$: Observable<PoolDto[]>;
    @select (DriversSelectors.driversSelector)
    drivers$: Observable<DriverDto[]>;
    @select (DriversSelectors.isDriversLoadingSelector)
    isDriversLoading$: Observable<boolean>;

    @select(OrderSelectors.orderDeliveryTypeSelector)
    orderDeliveryType$: Observable<number>;
    @select(OrderSelectors.orderStopListSelector)
    orderStopList$: Observable<Stop[]>;
    @select(OrderSelectors.orderVehicleTypeSelector)
    vehicleType$: Observable<string>;
    @select(OrderSelectors.selectedCandidatesSelector)
    selectedCandidates$: Observable<SelectedCandidatesDto>;
    @select(ShipmentEditSelectors.selectEditMode)
    orderEditMode$: Observable<OrderEditMode>;
    @select(OrderSelectors.isHasValidCreditCard)
    isHasValidCreditCard$: Observable<boolean>;
    @select(['shipment', 'ui', 'isLoading'])
    readonly isLoading$: Observable<boolean>;
    @select(['home', 'myOrders', 'ordersDetails', 'isLoading'])
    readonly isLoadingOrderDetails$: Observable<boolean>;

    // briefSummary
    @select(OrderSelectors.orderSummarySelector)
    orderSummary$: Observable<SummaryDto>;
    @select(OrderSelectors.orderItemListSelector)
    orderItemList$: Observable<OrderItemVo[]>;
    @select(['shipment', 'generalOffer', 'priceOptimizationData'])
    priceOptimizationData$: Observable<PriceOptimizationDto>;
    @select(OrderSelectors.orderTotalSelector)
    total$: Observable<number>;
    @select(ShipmentSelectors.driverAssignmentTypeSelector)
    driverAssignmentType$: Observable<DriverAssignmentTypeId>;
    @select(ShipmentSelectors.creditCardSelector)
    creditCard$: Observable<CreditCardDto>;
    @select(OrderSelectors.orderIsExtendableSelector)
    isExtendable$: Observable<boolean>;
    @select(ShipmentSelectors.milesSelector)
    miles$: Observable<number>;
    @select(UISelectors.paymentValidationSelector)
    isValidate$: Observable<boolean>;
    @select(['shipment', 'order', 'referenceId'])
    orderRefID$: Observable<any>;
    @select(['shipmentEdit', 'initialOrder', 'referenceId'])
    orderEditRefID$: Observable<any>;


    @select(OrderSelectors.orderCodeSelector) orderCode$;
    @select(OrderSelectors.orderIdSelector) orderId$;

    vehicleType;
    private driverAssignmentData: {type: '', data: {}};
    private isLoading;

    constructor(private uiActions: UIActions,
                private poolsActions: PoolsActions,
                private paymentActions: PaymentActions,
                private generalOfferActions: GeneralOfferActions,
                private shipmentActions: ShipmentActions,
                private orderStopsActions: OrderStopsActions,
                private ngRedux: NgRedux<IAppState>) {
        // this.isHasValidCreditCard$.subscribe(res => this.updateWizard(this.isAllowStep(this.driverAssignmentData)));
        this.isLoading$.subscribe( value => this.isLoading = value);
        this.vehicleType$.subscribe( value => {
            this.vehicleType = value;
        });
    }

    ngOnInit() {
        this.poolsActions.resetDriverList();
        const driverType = ShipmentSelectors.driverAssignmentTypeSelector(this.ngRedux.getState());
        if (driverType) {
            this.updateWizard(driverType === DriverAssignmentTypeId.Any);
        }
    }

    onPaymentOrderSkip() {
        // runs on ngOnInit of payment-order-component.ts due to emitter

        // OPTION 1: old action to allow pushing Submit btn to bypass CC entry:
        // this.uiActions.setWizardStepContinueAllowed(WizardStep.SummaryAndPaymentOrder, true);

        // OPTION 2: new action to bypass the CC entry screen and finalize order
        this.uiSetWizardStep(WizardStep.Confirmation);
    }

    onPaymentOrder(creditCard) {
        this.paymentActions.addCreditCard(creditCard);
        this.uiSetWizardStep(WizardStep.Confirmation);
    }

    onPaymentOrderErrors() {
        this.uiActions.setWizardStepContinueAllowed(WizardStep.SummaryAndPaymentOrder, false);
    }

    onDriverOffer(driverAssignmentData) {
        this.driverAssignmentData = driverAssignmentData;
        this.updateWizard(this.isAllowStep(driverAssignmentData));
        this.shipmentActions.newDriverType(driverAssignmentData);
    }

    onDriverSearch(query, minVehicleType) {
        this.poolsActions.loadDriverList(query, minVehicleType);
    }

    onNewOrderCreate() {
        this.shipmentActions.openNew();
    }

    uiSetWizardStep(step) {
        this.uiActions.setWizardStep(step);
    }

    onEditOrder() {
       this.uiSetWizardStep(WizardStep.Stops);
       this.generalOfferActions.clearPriceOptimizationTimeframesErrors();
    }

    onHidePriceOptimizationModal() {
        this.shipmentActions.shipmentOrderUpdate();
    }

    onSetExtendableProperty(isExtendable: boolean) {
        this.orderStopsActions.setOrderExtendable(isExtendable);
    }

    onSetIsDirectOrder(isDirect: boolean) {
        this.orderStopsActions.setIsDirectOrder(isDirect);
    }

    private updateWizard(isAssignmentsExist: boolean) {
        if (!isAssignmentsExist) {
            this.uiActions.setWizardStepContinueAllowed(WizardStep.SummaryAndDriverAssignment, false);
            return;
        }
        this.uiActions.setWizardStepContinueAllowed(WizardStep.SummaryAndDriverAssignment, true);
    }

    /*
    * @Info we should allow continue only if assigned any drivers or pools;
    * */
    private isAllowStep(assigned) {
        return assigned.type === DriverAssignmentTypeId.Any ||
            (assigned.type !== DriverAssignmentTypeId.Any &&
            ((assigned.data.driverUserAccountIds ? (assigned.data.driverUserAccountIds.length > 0) : false)
                || (assigned.data.driverPoolIds ? (assigned.data.driverPoolIds.length > 0) : false)));
    }
}
