import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { select } from '@angular-redux-ivy/store';

@Injectable()
export class ApiWithAdminInterceptor implements HttpInterceptor {

    @select(['auth', 'currentUser', 'adminProfile', 'isAdmin'])
    readonly isAdmin$: Observable<boolean>;
    @select(['admin', 'shipperAdmin', 'selectedShipper', 'userAccountId'])
    readonly userId$: Observable<any>;

    private whiteList = [
        { endpoint: 'Address/search', param: 'userId' },
        { endpoint: 'DriverProfile/Search', param: 'shipperId' },
        { endpoint: '/Payment?excludeDraft=true', param: 'shipperId' },
        { endpoint: '/AcctGateway/authorize', param: 'shipperUserId' },
        { endpoint: '/Payment/CreditCards', param: 'shipperId' }
    ];

    private isAdmin: boolean;
    private userId;

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let param = null;

        this.whiteList.forEach( whiteListItem => {
            if (request.url.includes(whiteListItem.endpoint)) {
                param = whiteListItem.param;
            }
        });

        this.isAdmin$.subscribe(result => this.isAdmin = result);
        this.userId$.subscribe(result => this.userId = result);

        if (this.isAdmin && this.userId && param) {
            return next.handle(request.clone({setParams: { [param]: this.userId }}));
        } else {
            return next.handle(request);
        }
    }
}
