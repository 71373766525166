import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { select } from '@angular-redux-ivy/store';
import { first, Observable } from 'rxjs';
import { IAuthState } from '../model/model';

@Injectable()
export class DriverHomeGuard implements  CanActivate {
    @select(['auth'])
    readonly auth$: Observable<IAuthState>;

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean>  {
        // prevent access of authorized shipper
        return new Observable<boolean>(observer => {
            this.auth$.pipe(first())
                .subscribe(auth => {
                    if (auth.loggedIn) {
                        observer.next(false);
                    } else {
                        observer.next(true);
                    }
                });
        });
    }
}
