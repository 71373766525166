import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, select$ } from '@angular-redux-ivy/store';
import { getActiveOrderDetails, MyOrdersSelectors } from '../../selectors/my-orders.selectors';
import { HomeActions } from '../../actions/home.actions';
import { OrderExpandedView } from '../../model/my-orders.model';
import { Observable, takeUntil } from 'rxjs';
import { OrderEventsActions } from '../../actions/order-events.action';
import { OrderEventsSelectors } from '../../selectors/order-events.selectors';
import { PoolsActions } from '../../../core/actions/pools.actions';
import { Subject } from 'rxjs';

@Component({
    selector: 'tuya-order-details',
    templateUrl: './order-details.page.component.html',
    styleUrls: ['./order-details.page.component.scss']
})
export class OrderDetailsPageComponent implements OnInit, OnDestroy {
    @select$(['home', 'myOrders'], getActiveOrderDetails) activeOrderInfo$: Observable<OrderExpandedView>;
    @select(['home', 'myOrders', 'ordersDetails', 'isLoading'])
    readonly isLoading$: Observable<boolean>;
    @select(['auth', 'currentUser', 'shipperProfile', 'suspended']) isShipperSuspended$: Observable<boolean>;
    @select(MyOrdersSelectors.CancelSuccessSelector) cancelSuccess$;
    @select(OrderEventsSelectors.GetOrderAllEventsSelector) orderEventsList$;
    @select(OrderEventsSelectors.ErrorMessageKey) errorMessageKey$: Observable<string>;

    @select(['home', 'myOrders', 'ordersDetails', 'recurringId'])
    readonly recurringId$: Observable<boolean>;

    private unsubscriber = new Subject<void>();

    private recurringId = null;

    private orderId;
    private cancelSubscribe: any;

    constructor(private route: ActivatedRoute,
        private homeActions: HomeActions,
        private poolsActions: PoolsActions,
        private ref: ChangeDetectorRef,
        private orderEventsActions: OrderEventsActions) {
    }

    ngOnInit() {
        this.recurringId$.pipe(takeUntil(this.unsubscriber)).subscribe((res: any) => {
            this.recurringId = res;
        });

        // this.poolsActions.loadPoolList();
        // why are we observing state.home.myOrders.ordersTable.ordersCommonInfo on the order details page?
        // and why is it called "cancelSuccess"?
        // we know this fires when updating the order status. when else does it run?
        this.cancelSubscribe = this.cancelSuccess$.subscribe(data => {
            if (data && this.orderId && !this.recurringId) {
                // refresh the order details and event log
                this.homeActions.loadRowInfo({ id: this.orderId, collapsed: true });
                this.orderEventsActions.getAllOrderEvents(this.orderId);
            }
        });
        // this runs on init of the page (or navigating to another order-details page) and loads the order details and event log
        this.route.queryParams.subscribe(params => {
            this.orderId = params.orderId;
            this.homeActions.loadRowInfo({ id: this.orderId, collapsed: true });
            this.orderEventsActions.getAllOrderEvents(this.orderId);
        });
    }

    ngOnDestroy() {
        this.unsubscriber.next();
        this.unsubscriber.unsubscribe();
        this.homeActions.deleteRowInfo(this.orderId);
        this.cancelSubscribe.unsubscribe();
        this.ref.detach();
    }
}
