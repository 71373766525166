import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, Injectable, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import moment, { Moment } from 'moment';
import { StopType } from 'app/shipment/models/order.model';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

// Please add a Injectable or Pipe or Directive or Component or NgModule decorator to the class
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      const day: string = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
      const month: string = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
      const year: number = date.getFullYear();
      return `${month}/${day}/${year}`;
    } else {
      const monthIndex: number = date.getMonth();
      return `${months[monthIndex]} ${date.getFullYear()}`;
    }
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { month: 'short' },
    dateA11yLabel: { month: 'short', day: 'short' },
    monthYearA11yLabel: { month: 'short' },
  }
};

@Component({
  selector: 'tuya-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{ provide: DateAdapter, useClass: AppDateAdapter },
  { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }]
})

export class DatePickerComponent implements OnInit, OnChanges {

  @Input() stopType: number;
  @Input() timeForm: UntypedFormGroup;
  @Input() disabled: boolean;
  @Input() currentStep: number;
  @Input() isScheduledOrder = false;
  @Input() pickupDate = '';

  @Output() onDateChange = new EventEmitter<string>();

  minDate = new Date();
  maxDate = new Date();

  // max date is now + 60 days
  maxAvailableDate = new Date().getTime() + (60 * 24 * 60 * 60 * 1000);
  // delivery stop max date for scheduled order
  fiveDays = 5 * 24 * 60 * 60 * 1000;
  // delivery stop max date for regular order
  oneDay = 1 * 24 * 60 * 60 * 1000;

  datePickerActive = false;
  imageName = 'calendar-icon-black';

  constructor() { }

  ngOnInit() {
    this.maxDate.setTime(this.maxAvailableDate);
  }

  ngOnChanges() {
    if (this.currentStep === 1 && this.stopType === StopType.Delivery) {
      this.minDate = new Date(this.pickupDate);

      const newMaxDate = new Date(this.minDate).getTime() + this.fiveDays;

      newMaxDate < this.maxAvailableDate ?
        this.maxDate.setTime(newMaxDate) :
        this.maxDate.setTime(this.maxAvailableDate);
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type === 'input') {
      const selectedDate = moment(event.value).format('YYYY-MM-DD');
      this.onDateChange.emit(selectedDate);
    }
  }

  onOpen() {
    this.updateDatePickerIcon(true, 'calendar-icon-white');

    if (this.currentStep === 1 && this.stopType === StopType.Delivery) {
      const newMaxDate = new Date(this.minDate).getTime() + this.fiveDays;
      if (this.maxAvailableDate > newMaxDate) {
        this.maxDate.setTime(newMaxDate);
      }
    }
  }

  onClose() {
    this.updateDatePickerIcon(false, 'calendar-icon-black');
  }

  updateDatePickerIcon(isActive: boolean, iconName: string) {
    this.datePickerActive = isActive;
    this.imageName = iconName;
  }

  // Function to set today's date if the input is empty
  setDefaultDate() {
    const dateControl = this.timeForm.get('date');
    if (dateControl && !dateControl.value) {
      const today = new Date();
      const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
      dateControl.setValue(formattedDate);
    }
  }
}
