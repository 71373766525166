import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';

import { DriverTablePagination, DriverResponse, DriverDTO, NewDriverDTO, VehicleDTO } from './../models/driverportal.model';

export type DriverPortalAction = Action<any, any>;

export const GET_DRIVERS = '[DRIVER PORTAL] Get drivers';
export const GET_DRIVERS_SUCCESS = '[DRIVER PORTAL] Get drivers success';
export const GET_DRIVERS_FAILURE = '[DRIVER PORTAL] Get drivers failure';

export const ACTIVATE_DIACT_DRIVER = '[DRIVER PORTAL] Activate/Diactivate driver';
export const ACTIVATE_DIACT_SUCCESS = '[DRIVER PORTAL] Activate/Diactivate success';
export const ACTIVATE_DIACT_FAILURE = '[DRIVER PORTAL] Activate/Diactivate failure';

export const DRIVER_RESET_PASSWORD = '[DRIVER PORTAL] Reset driver password';
export const DRIVER_RESET_PASSWORD_SUCCESS = '[DRIVER PORTAL] Reset driver password success';
export const DRIVER_RESET_PASSWORD_FAILURE = '[DRIVER PORTAL] Reset driver password failure';
export const UPDATE_PAGINATION_DRIVER_TABLE = '[DRIVER PORTAL] Update pagination';
export const RESET_PAGINATION = '[DRIVER PORTAL] Reset pagination';

export const ADD_NEW_DRIVER = '[DRIVER PORTAL] Add new driver';
export const ADD_NEW_DRIVER_SUCCESS = '[DRIVER PORTAL] Add new driver success';
export const ADD_NEW_DRIVER_FAILURE = '[DRIVER PORTAL] Add new driver failure';

export const DRIVER_MODAL_REACT = '[DRIVER PORTAL] Driver modal open | close';
export const DRIVER_SET_SEARCH_QUERY = '[DRIVER PORTAL] Set search query';

export const UPDATE_DRIVER_MODAL_MODE = '[DRIVER PORTAL] Update driver modal mode';

export const ADD_VEHICLE = '[DRIVER PORTAL] Add driver vehicle';
export const ADD_VEHICLE_SUCCESS = '[DRIVER PORTAL] Add driver vehicle success';
export const ADD_VEHICLE_FAILURE = '[DRIVER PORTAL] Add driver vehicle failure';

export const UPDATE_VEHICLE = '[DRIVER PORTAL] Update vehicle';
export const UPDATE_VEHICLE_SUCCESS = '[DRIVER PORTAL] Update vehicle success';
export const UPDATE_VEHICLE_FAILURE = '[DRIVER PORTAL] Update vehicle failure';

export const SHOW_SNACK_BAR = '[DRIVER PORTAL] Show | hyde snack bar';
export const SET_INIT_STATE = '[DRIVER PORTAL] Set initial state';

@Injectable()
export class AdminDriverPortalActions {

  @dispatch()
  addNewDriver = (data: NewDriverDTO): DriverPortalAction => ({
    type: ADD_NEW_DRIVER,
    payload: data
  })
  addNewDriverSuccess = (data: {newDriver: DriverDTO, driverId: number, vehicleId: number}): DriverPortalAction => ({
    type: ADD_NEW_DRIVER_SUCCESS,
    payload: data
  })
  addNewDriverFailure = (): DriverPortalAction => ({
    type: ADD_NEW_DRIVER_FAILURE,
    payload: null
  })

  @dispatch()
  getDrivers = (mode: string): DriverPortalAction => ({
      type: GET_DRIVERS,
      payload: mode
  })

  getDriversSuccess = (response: DriverResponse): DriverPortalAction => ({
    type: GET_DRIVERS_SUCCESS,
    payload: response
  })

  getDriversFailure = (): DriverPortalAction => ({
    type: GET_DRIVERS_FAILURE,
    payload: null
  })

  @dispatch()
  activateDiactDriverBy = (data: {id: string, isActivate: boolean}): DriverPortalAction => ({
      type: ACTIVATE_DIACT_DRIVER,
      payload: data
  })

  activateDiactSuccess = (data: {id: string, isActivate: boolean}): DriverPortalAction => ({
    type: ACTIVATE_DIACT_SUCCESS,
    payload: data
  })

  activateDiactFailure = (): DriverPortalAction => ({
    type: ACTIVATE_DIACT_FAILURE,
    payload: null
  })

  @dispatch()
  resetPassword = (data: {driverId: string, driverEmail: string}): DriverPortalAction => ({
      type: DRIVER_RESET_PASSWORD,
      payload: data
  })

  resetPasswordSuccess = (): DriverPortalAction => ({
    type: DRIVER_RESET_PASSWORD_SUCCESS,
    payload: null
  })

  resetPasswordFailure = (): DriverPortalAction => ({
    type: DRIVER_RESET_PASSWORD_FAILURE,
    payload: null
  })

  @dispatch()
  updateDriverTablePagination = (data: any): DriverPortalAction => ({
      type: UPDATE_PAGINATION_DRIVER_TABLE,
      payload: data
  })

  @dispatch()
  resetPagination = (): DriverPortalAction => ({
      type: RESET_PAGINATION,
      payload: null
  })

  @dispatch()
  driverModalReact = (reactAction: boolean): DriverPortalAction => ({
      type: DRIVER_MODAL_REACT,
      payload: reactAction
  })

  @dispatch()
  setDriverSearchQuery = (searchQuery: string): DriverPortalAction => ({
    type: DRIVER_SET_SEARCH_QUERY,
    payload: searchQuery
  })

  @dispatch()
  updateDriverModalMode = (modeType: number): DriverPortalAction => ({
    type: UPDATE_DRIVER_MODAL_MODE,
    payload: modeType
  })

  @dispatch()
  addDriverVehicle = (data: VehicleDTO): DriverPortalAction => ({
      type: ADD_VEHICLE,
      payload: data
  })
  addDriverVehicleSuccess = (response: VehicleDTO): DriverPortalAction => ({
    type: ADD_VEHICLE_SUCCESS,
    payload: response
  })
  addDriverVehicleFailure = (): DriverPortalAction => ({
    type: ADD_VEHICLE_FAILURE,
    payload: null
  })

  @dispatch()
  updateDriverVehicle = (data: VehicleDTO): DriverPortalAction => ({
      type: UPDATE_VEHICLE,
      payload: data
  })
  updateDriverVehicleSuccess = (response: VehicleDTO): DriverPortalAction => ({
    type: UPDATE_VEHICLE_SUCCESS,
    payload: response
  })
  updateDriverVehicleFailure = (): DriverPortalAction => ({
    type: UPDATE_VEHICLE_FAILURE,
    payload: null
  })

  @dispatch()
  showSnackBar = (data: {show: boolean, message: string, subTitle?: string}): DriverPortalAction => ({
      type: SHOW_SNACK_BAR,
      payload: data
  })

  @dispatch()
  setDriverPortalInitialStatus = (): DriverPortalAction => ({
      type: SET_INIT_STATE,
      payload: null
  })

}
