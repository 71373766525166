import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BuilderActions } from '../../actions/builder.actions';
import { select } from '@angular-redux-ivy/store';
import { BuilderSelectors } from '../../selectors/builder.selectors';
import { StopDto } from '../../../core/models/dto';
import { Observable } from 'rxjs';
import { BuilderService } from '../../services/builder.service';
import { OrderDto } from '../../models/order.model';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../app.config.service';
import { AlertActions } from '../../../core/actions/alert.actions';
import { TimeValidators } from '../../validators/time.validators';
import { isValidAddress } from '../../../builder/validators/address.validators';
import { AlertType } from '../../../core/models/alert.model';
import { OrderStatusId } from '../../../core/models/dto';
import { StopType } from '../../../shipment/models/order.model';
import { pickupStopIndex, deliveryStopIndex } from '../../models/stop.model';
import { AuthActions } from '../../../auth/actions/auth.actions';
import { ShipperActions } from 'app/shipper/actions/shipper.status.actions';
import moment, { Moment } from 'moment';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'tuya-builder-accordion',
    templateUrl: './builder-accordion.component.html',
    styleUrls: ['./builder-accordion.component.scss']
})
export class BuilderAccordionComponent implements OnInit {
    @select(BuilderSelectors.getOrderDto) order$: Observable<OrderDto>;
    @select(BuilderSelectors.getStops) stops$: Observable<StopDto[]>;
    @select(BuilderSelectors.getIsEditMode) isEditMode$: Observable<boolean>;
    @select(BuilderSelectors.getOrderEditMode) orderEditMode$: Observable<number>;
    @select(BuilderSelectors.getRoutificError) routificError$: Observable<boolean>;
    @select(BuilderSelectors.getSubmitErrors) submitErrors$: Observable<object>;
    @select(BuilderSelectors.getIsDuplicateMode) isDuplicateMode$: Observable<boolean>;
    @select(['auth', 'currentUser', 'adminProfile']) readonly adminProfile$: Observable<any>;

    @Input() expanded: boolean;
    @Input() currentStep: number;
    @Input() stepIndex: number;
    @Input() disabled: boolean;
    @Input() stop: StopDto;
    @Input() submitErrors: object;
    @Input() stopForm: UntypedFormGroup;
    @Input() materialLocked = false;

    private sub: any; // JAY the param binding didn't seem to work without this

    editMode = false;
    orderEditMode: number;
    routificError: boolean;
    isEditMode = false;
    isDuplicateMode = false;
    adminProfile: any;

    stopCount: number;        // Number of stops in order
    totalSteps: number;       // Number of stops + 1

    pickupStopStepIndex = 0;

    private order: OrderDto;
    private service: BuilderService;
    private stops: StopDto[];

    constructor(private builderActions: BuilderActions,
        private http: HttpClient,
        private config: ConfigService,
        private authActions: AuthActions,
        private shipperActions: ShipperActions,
        private alertActions: AlertActions,
        private route: ActivatedRoute) {

        this.stops$.subscribe(result => {
            this.stopCount = result.length;
            this.totalSteps = result.length + 1; // Only add 1, not 2, to match currentStep which starts with index of 0.
            this.stops = result;
        });

        this.order$.subscribe(result => this.order = result);

        this.isEditMode$.subscribe((result) => {
            this.editMode = result;
            this.isEditMode = result;
        });

        this.orderEditMode$.subscribe(result => this.orderEditMode = result);
        this.routificError$.subscribe(result => this.routificError = result);
        this.adminProfile$.subscribe(result => this.adminProfile = result);
        this.isDuplicateMode$.subscribe(result => this.isDuplicateMode = result);

        this.service = new BuilderService(this.http, this.config);
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.isDuplicateMode === 'true') {
              this.isDuplicateMode = true;
            }
          });
    }

    onNextButtonClick() {
        // if there is no order id this is a new order.

        if (this.order.id == null || this.isDuplicateMode) {
            if (this.isDuplicateMode){ 
                if (!environment.production) console.debug('ORDER - New (Duplicate)');
            }
            else {
                if (!environment.production) console.debug('ORDER - New');
            }

            this.postNewOrder();
        }
        else {
            if (!environment.production) console.debug('ORDER - Update');

            this.putOrderUpdate();
        }
    }

    onEditButtonClick() {
        this.isEditMode = true;
        this.builderActions.onUpdateOrderMode(true);
        this.builderActions.setCurrentStep(this.currentStep, this.stepIndex);
    }

    // TODO this validation routine will need to be modified for multi-stops
    validateBeforeRequest() {
        // ignore time errors if order is in progress
        if (this.materialLocked) { return true; }

        // if we're on a stop step, run time validations
        if (this.currentStep < this.stopCount) {
            // JAY: for some reason these values are null on the first time validation attempt
            if (this.stop.pickupNoEarlierThan === null) {
                this.stop.pickupNoEarlierThan = moment().add(10, 'minutes').toISOString();
            }
            if (this.stop.pickupNoLaterThan === null) {
                this.stop.pickupNoLaterThan = moment().add(4, 'hours').add(10, 'minutes').toISOString();
            }

            if (!TimeValidators.startTimeIsGreaterThanEndTime(this.stop.pickupNoEarlierThan, this.stop.pickupNoLaterThan)) {
                this.builderActions.onSubmitError(this.currentStep, 'endTimeEarlier');
                this.builderActions.hideSpinner();
                return false;
            }

            if (!TimeValidators.startTimeIsNotInThePast(this.stop.pickupNoEarlierThan)) {
                this.builderActions.onSubmitError(this.currentStep, 'timeNoLongerValidOnNext');
                this.builderActions.hideSpinner();
                return false;
            }

            // only run these validators for delivery stop because pickup stops have logic to auto-update delivery stop times
            if (this.stop.typeId === StopType.Delivery) {
                const pickupStartTime = this.stops[pickupStopIndex].pickupNoEarlierThan;
                const deliveryStartTime = this.stops[deliveryStopIndex].pickupNoEarlierThan;
                if (TimeValidators.pickupTimeIsAfterDeliveryTime(pickupStartTime, deliveryStartTime)) {
                    this.builderActions.onSubmitError(this.currentStep, 'deliveryStartBeforePickupStart');
                    this.builderActions.hideSpinner();
                    return false;
                }

                const pickupEndTime = this.stops[pickupStopIndex].pickupNoLaterThan;
                const deliveryEndTime = this.stops[deliveryStopIndex].pickupNoLaterThan;
                if (TimeValidators.pickupTimeIsAfterDeliveryTime(pickupEndTime, deliveryEndTime)) {
                    this.builderActions.onSubmitError(this.currentStep, 'deliveryEndBeforePickupEnd');
                    this.builderActions.hideSpinner();
                    return false;
                }
            }
        }
        return true;
    }

    generateOrderSummary(orderItems) {
        const result = {
            'items': 0,
            'lbs': 0,
            'miles': 0,
            'returns': 0,
            'stops': 0
        };
        orderItems.forEach(orderItem => {
            result.items += orderItem.piecesCount;
            result.lbs += orderItem.piecesCount * orderItem.weight;
        });
        return result;
    }

    generateResponse(data) {
        let response = {
            'assignments': null,
            'deliveryType': 1,
            'id': 0,
            'isCreatedOnMobile': false,
            'isDirect': false,
            'isExtendable': false,
            'offerType': 1,
            'orderItems': [],
            'referenceId': null,
            'shipperContactId': 0,
            'status': 0,
            'stops': [],
            'total': 0,
            'vehicleType': {}
        };
        response = Object.assign(response, { ...data });
        response.orderItems.forEach(item => {
            response.stops = item.stops;
            delete item.stops;
        });
        response['orderSummary'] = this.generateOrderSummary(response.orderItems);
        response['orderSummary'].stops = response.stops.length;

        return response;
    }

    // POST order to create new order.
    postNewOrder() {
        if (!this.validateBeforeRequest()) { return false; }

        // Create new object so we aren't mutating order in state.
        let newOrderObject = Object.assign({}, this.order);
        let stoperror = false;

        // delete newOrderObject.id;
        // remove the id property before making POST to /Order because id: null cause 'code: 9999' error
        if (this.stepIndex >= 2) {
            this.builderActions.displaySpinner();
            for (let i = 0; i < newOrderObject.orderItems.length; i++) {
                for (let s = 0; s < newOrderObject.orderItems[i].stops.length; s++) {
                    if (newOrderObject.orderItems[i].stops[s].id === null) {
                        delete newOrderObject.orderItems[i].stops[s].id;
                    }

                    const stop = newOrderObject.orderItems[i].stops[s];
                    if (!TimeValidators.startTimeIsNotInThePast(stop.pickupNoEarlierThan) ||
                        !TimeValidators.startTimeIsGreaterThanEndTime(stop.pickupNoEarlierThan, stop.pickupNoLaterThan)) {
                        this.builderActions.setCurrentStep(this.currentStep, stop.sequenceNumber);
                        this.builderActions.onSubmitError(stop.sequenceNumber, 'timeNoLongerValidOnNext');
                        stoperror = true;
                    }
                }
            }

            if (stoperror) {
                this.builderActions.hideSpinner();
                return false;
            }

            const editMode = (newOrderObject.assignments !== null && newOrderObject.assignments.orderId) ? true : false;

            if (this.isDuplicateMode) {
                newOrderObject = this.prepareForDuplicate(newOrderObject);
            }

            this.service.createDraftOrder(newOrderObject, editMode).subscribe(
                result => {
                    this.builderActions.onPostOrderSuccess(result);
                    this.builderActions.setCurrentStep(this.currentStep, this.currentStep + 1);
                    this.authActions.updateHasOrders(true); // auth->currentUser->shipperProfile->hasOrders
                    this.shipperActions.updateShipperStatusHasOrders(true); // shipper->shipperStatus->hasOrders
                },
                error => {
                    console.error(error);
                    this.alertActions.show({ type: AlertType.BadRequest, error: error });
                    this.builderActions.hideSpinner();
                },
                () => this.builderActions.hideSpinner()
            );
        } else {
            if (this.currentStep !== 1 && !this.isEditMode) {
                const a = { ...newOrderObject };
                a.orderItems.forEach(stopItem => {
                    for (let st = 0; st < stopItem.stops.length; st++) {
                        stopItem.stops[st]['id'] = null;
                    }
                });
                this.builderActions.onPostOrderSuccess(a);
            } else {
                const ordSum = this.generateOrderSummary(this.order.orderItems);
                this.builderActions.onSetSummaryField(ordSum);
            }
            if (this.isOrderValid(this.currentStep) === false) {
                this.alertActions.show({ type: AlertType.NotInMsa });
                return false;
            }

            this.builderActions.setCurrentStep(this.currentStep, this.currentStep + 1);
        }
    }

    prepareForDuplicate(order): OrderDto {
        const modifiedOrder = { ...order };

        for (const key in modifiedOrder) {
            if (key === 'statusId') {
                modifiedOrder[key] = 10;
            } else if (key === 'id' || key === 'recurringOrderId' || key === 'completionDateTime' || key === 'code') {
                modifiedOrder[key] = null;
                if (key === 'id') {
                    delete modifiedOrder[key]; // JAY
                }

            } else if (key === 'orderItems') {

                for (let i = 0; i < modifiedOrder[key].length; i++) {
                    delete modifiedOrder[key][i].id;
                    modifiedOrder[key][i].stopIds = [];
                    modifiedOrder[key][i].stopId = null;
                    modifiedOrder[key][i].orderId = null;
                    for (let j = 0; j < modifiedOrder[key][i]['stops'].length; j++) {

                        delete modifiedOrder[key][i]['stops'][j].id;
                        delete modifiedOrder[key][i]['stops'][j].address.id;
                        modifiedOrder[key][i]['stops'][j].orderId = null;
                        modifiedOrder[key][i]['stops'][j].addressId = null;
                        modifiedOrder[key][i]['stops'][j].statusId = 10;   // JAY Added
                        modifiedOrder[key][i]['stops'][j].status = 10;     // JAY Added

                        for (let k = 0; k < modifiedOrder[key][i]['stops'][j]['orderItems'].length; k++) {
                            delete modifiedOrder[key][i]['stops'][j]['orderItems'][k].id;
                            modifiedOrder[key][i]['stops'][j]['orderItems'][k].orderId = null;
                            modifiedOrder[key][i]['stops'][j]['orderItems'][k].stopIds = [];
                        }
                    }
                }

            } else if (key === 'addresses') {
                for (let i = 0; i < modifiedOrder[key].length; i++) {
                    delete modifiedOrder[key][i].id;
                }
            } else if (key === 'assignments') {
                modifiedOrder[key].orderId = null;
            }
        }
        return modifiedOrder;
    }

    isOrderValid(currStep: number): boolean {
        if (currStep === 1) {
            return this.service.orderHasMsaStop(this.stops);
        }
        return true;
    }

    /**
     * PUT order to update order.
     */
    putOrderUpdate() {
        if (!this.validateBeforeRequest()) { return false; }

        if (this.stepIndex < 2) {
            if (this.isOrderValid(this.currentStep) === false) {
                this.alertActions.show({ type: AlertType.NotInMsa });
            } else {
                this.builderActions.setCurrentStep(this.currentStep, this.currentStep + 1);
            }
            return false;
        }

        const orderObject = { ...this.order };
        let stoperror = false;

        orderObject.orderItems = orderObject.orderItems.map((item) => {
            if (item['id'] < 0) {
                delete item['id'];
            }

            item.stops = item.stops.filter((stop) => {
                // bandaide, remove when server response for edit includes type
                stop.type = stop.typeId;

                // Check stop tiumes again to ensure they have not expired.
                if (!TimeValidators.startTimeIsNotInThePast(stop.pickupNoEarlierThan) ||
                    !TimeValidators.startTimeIsGreaterThanEndTime(stop.pickupNoEarlierThan, stop.pickupNoLaterThan)) {
                    this.builderActions.setCurrentStep(this.currentStep, stop.sequenceNumber);
                    this.builderActions.onSubmitError(stop.sequenceNumber, 'timeNoLongerValidOnNext');
                    stoperror = true;
                }
                return (isValidAddress(stop.address) && stop.contactName && stop.nameOrDescription);
            });
            return item;
        });

        if (stoperror) {
            this.builderActions.hideSpinner();
            return false;
        }

        this.builderActions.displaySpinner();
        if (this.order.statusId === OrderStatusId.Draft || !this.editMode) {
            // clear time window error on PUT request
            // if Routific validation gets put on the OptimizePrice route, we can move this up higher in the method
            if (this.routificError) {
                this.builderActions.clearRoutificError();
                if (this.currentStep === this.pickupStopStepIndex) {
                    this.builderActions.removeDeliveryStop();
                }
            }
            this.service.createDraftOrder(orderObject, this.editMode).subscribe(
                result => {
                    result['statusId'] = result['status'];
                    delete result['status'];
                    this.builderActions.onPutOrderSuccess(result, false);
                    this.builderActions.setCurrentStep(this.currentStep, this.currentStep + 1);
                },
                error => {
                    console.error(error);
                    if (error.error && error.error.errors[0] && error.error.errors[0].code === '2303') {
                        this.builderActions.setRoutificError();
                        this.alertActions.show({ type: AlertType.Error, message: error.error.errors[0].message });
                    } else {
                        this.alertActions.show({ type: AlertType.BadRequest, error: error });
                    }
                    this.builderActions.hideSpinner();
                },
                () => this.builderActions.hideSpinner()
            );
        } else {
            this.service.updateOrderPrice(orderObject).subscribe(
                result => {
                    if (this.adminProfile) {
                        this.builderActions.onPutOrderSuccess(result, false, true);
                    } else {
                        this.builderActions.onPutOrderSuccess(result, false);
                    }
                    this.builderActions.updateInitPrice(false);
                    this.builderActions.setCurrentStep(this.currentStep, this.currentStep + 1);
                },
                error => {
                    console.error(error);
                    this.alertActions.show({ type: AlertType.BadRequest, error: error });
                    this.builderActions.hideSpinner();
                },
                () => this.builderActions.hideSpinner()
            );
        }
    }
}