import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ElementRef,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import { HighlightType, NotificationBucketItemDto } from '../../models/notification.model';
import { UIActions } from '../../../shipment/actions/ui.actions';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

export const DEFAULT_PAGE_NUMBER = 1;

@Component({
    selector: 'tuya-notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPanelComponent implements AfterViewInit, OnDestroy {
    private scrollSubscription: Subscription;

    @Input() set notificationList(notifications: Array<NotificationBucketItemDto>) {
        this._notificationList = notifications;
    }

    get notificationList() {
        return this._notificationList;
    }

    @Input() notificationTotalCount = 0;
    @Input() isLoading: boolean;
    @Input() errorMessageKey: string;
    @Output() loadNotificationBucket = new EventEmitter<number>();
    @Output() clearNotificationBucket = new EventEmitter<void>();

    HighlightType: typeof HighlightType = HighlightType;

    public isOpen = false;
    private pageNumber = DEFAULT_PAGE_NUMBER;
    private _notificationList: Array<NotificationBucketItemDto> = [];

    @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;

    constructor(private uIActions: UIActions, private changeDetectorRef: ChangeDetectorRef) { }

    ngAfterViewInit() {
        // Ensures subscription is only set once
        if (this.scrollContainer && this.scrollContainer.nativeElement) {
            this.scrollSubscription = fromEvent(this.scrollContainer.nativeElement, 'scroll')
                .pipe(debounceTime(100))
                .subscribe(() => this.handleScroll(this.scrollContainer.nativeElement));
        }
    }

    onScroll() {
        if (this.scrollContainer && this.scrollContainer.nativeElement) {
            const panel = this.scrollContainer.nativeElement;
            this.handleScroll(panel);
        } else {
            console.warn('scrollContainer is undefined. Scroll functionality will not work.');
        }
    }

    private handleScroll(panel: HTMLElement) {
        const top = panel.scrollTop;
        const offset = panel.clientHeight;
        const max = panel.scrollHeight;
    
        // A threshold of 20px before triggering the load event
        const threshold = 20;
    
        if (top + offset >= max - threshold && !this.isLoading) {
            this.onLoadNextSetOfNotifications();
        }
    }

    ngOnDestroy() {
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
    }

    onShowPanel() {
        this.loadNotificationBucket.emit(this.pageNumber);
        this.isOpen = true;
    }

    onHidePanel() {
        this.onClearList();
        this.isOpen = false;
    }

    onLoadNextSetOfNotifications() {
        if (this.isOpen && this.notificationList.length < this.notificationTotalCount) {
            this.pageNumber++;
            this.loadNotificationBucket.emit(this.pageNumber);
        }
    }

    onOffClick() {
        if (this.isOpen) {
            this.onHidePanel();
        }
    }

    onRefreshNotifications() {
        this.onClearList();
        this.loadNotificationBucket.emit(this.pageNumber);
    }

    onClearList() {
        this.pageNumber = DEFAULT_PAGE_NUMBER;
        this.clearNotificationBucket.emit();
    }

    goToOrderDetails(item: any) {
        this.onOffClick();
        if (item.orderId) {
            this.uIActions.goToOrderDetails({
                orderId: item.orderId,
                recurringId: item.recurringOrderId
            });
        }
    }
}