import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import {
    SHIPMENT_MANUAL_ORDER_RESET,
    ShipmentActions, SHIPMENT_ORDER_DELETE
} from '../actions/shipment.actions';
import { ShipmentService } from '../services/shipment.service';
import { Router } from '@angular/router';
import { UPDATE_LOCATION } from '@angular-redux-ivy/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';

@Injectable()
export class ShipmentOrderEpics {
    constructor(private shipmentActions: ShipmentActions,
                private shipmentService: ShipmentService,
                private alertActions: AlertActions,
                private router: Router) {
    }

    public createEpic() {
        return combineEpics(
            createManualOrderResetEpic(this.shipmentActions, this.router),
            createOrderDeleteEpic(this.shipmentActions, this.shipmentService, this.alertActions)
        );
    }
}

export function createManualOrderResetEpic(shipmentActions: ShipmentActions, router: Router) {
    return (action$, store) => action$.pipe(
        ofType(SHIPMENT_MANUAL_ORDER_RESET), // it should be only invoked when a user manually clicks on the order reset button.
        switchMap(() => {
            const basePath = '/shipment/initial';
            const addressLine = store.value.auth.currentUser.shipperProfile.businessEntity.physicalAddress.addressLine;
            const orderId = store.value.shipment.order.id;
            let actions = concat(
                of({ type: UPDATE_LOCATION, payload: basePath }),
                of(shipmentActions.reset()),
                of(shipmentActions.initFirstStop(addressLine))
            );

            // if order was saved to draft - remove it
            if (orderId) {
                actions = concat(actions, of(shipmentActions.shipmentOrderDelete(orderId)));
            }

            router.navigateByUrl('/home').then((navigated) => {
                if (navigated) {
                    router.navigateByUrl(basePath);
                }
            });

            return actions;
        })
    );
}


export function createOrderDeleteEpic(shipmentActions: ShipmentActions, service: ShipmentService,
                                      alertActions: AlertActions) {
    return (action$) => action$.pipe(
        ofType(SHIPMENT_ORDER_DELETE),
        switchMap((action: any) => {
            return service.deleteOrder(action.payload).pipe(
                map(data => {
                    return shipmentActions.shipmentOrderDeleteSuccess();
                })
                ,catchError(error =>  { return of(alertActions.show({message: error, type: AlertType.Error})); }));
        })
    );
}

