import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationBucketItemDto } from '../../models/notification.model';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { LoadNotificationsParamsDto } from '../../models/dto';
import { NotificationActions } from '../../actions/notification.actions';
import { NotificationSelectors } from '../../selectors/notification.selectors';
import { UIActions } from '../../../shipment/actions/ui.actions';

export const PAGE_SIZE = 10;

@Component({
    selector: 'tuya-notification-panel-container',
    templateUrl: './notification-panel-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPanelContainerComponent {
    @select(NotificationSelectors.NotificationBucketSelector)
    notificationBucket$: Observable<NotificationBucketItemDto[]>;

    @select(NotificationSelectors.TotalItemCountSelector)
    notificationTotalCount$: Observable<number>;

    @select(NotificationSelectors.IsLoadingSelector)
    isLoading$: Observable<number>;

    @select(NotificationSelectors.ErrorMessageKeySelector)
    errorMessageKey$: Observable<string>;

    constructor(private notificationActions: NotificationActions,
                private uIActions: UIActions) {
    }

    onLoadNotificationBucket(pageNumber: number) {
        const payload = <LoadNotificationsParamsDto>{
            pageSize: PAGE_SIZE,
            currentPageNumber: pageNumber,
            totalItemCountRequired: true
        };
        this.notificationActions.loadNotificationBucket(payload);
    }

    onClearNotificationBucket() {
        this.notificationActions.clearNotificationBucket();
    }

    onOpenOrderDetails(orderId: any) {
        this.uIActions.goToOrderDetails({orderId});
    }
}