import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DriverRate } from '../../model/driver-info.model';
import { RateReason } from '../../model/my-orders.model';

const rateLocalePath = 'tuya-my-orders.driver-rate.';
export const rateRange: Array<DriverRate> = [
    {id: 1, value: `${rateLocalePath}unacceptable`},
    {id: 2, value: `${rateLocalePath}poor`},
    {id: 3, value: `${rateLocalePath}fair`},
    {id: 4, value: `${rateLocalePath}good`},
    {id: 5, value: `${rateLocalePath}excellent`}
];

@Component({
    selector: 'tuya-rate-driver',
    templateUrl: './rate-driver.component.html',
    styleUrls: ['./rate-driver.component.scss']
})
export class RateDriverComponent implements OnInit {
    @Input() rate: number | undefined;
    @Input() reasonId: number | undefined;
    @Input() rateReasons: Array<RateReason>;
    @Input() isTimeToRateExceed: boolean;

    @Input()
    set isRateUpdated(isRateUpdated) {
        this._isRateUpdated = isRateUpdated;
        if (this._isRateUpdated) {
            this.isFeedbackHintVisible = true;
        }
    }

    @Output() updateRate = new EventEmitter();

    /* driver is only rated */
    public isDriverRated = false;

    public isFeedbackHintVisible = false;

    public GOOD_RATE = 4;
    public rateRange = rateRange;

    public shipperRate: number;
    public shipperReasonId: number;

    private _isRateUpdated: boolean | undefined;

    constructor() {
    }

    ngOnInit() {
        this.isDriverRated = this.rate !== 0 && this.rate < this.GOOD_RATE;

        this.shipperRate = this.rate || 0;
        this.shipperReasonId = this.reasonId || 0;
    }

    public onRateDriver(rate: number): void {
        this.isDriverRated = rate < this.GOOD_RATE;
        this.isFeedbackHintVisible = false;

        this.shipperRate = rate;
        this.shipperReasonId = 0;

        if (rate >= this.GOOD_RATE) {
            this.updateRate.emit({rate});
        }
    }

    public onCommentRate(reasonId: number): void {
        this.updateRate.emit({
            rate: this.shipperRate,
            reasonId: this.shipperRate < this.GOOD_RATE && reasonId ? reasonId : 0
        });
    }
}
