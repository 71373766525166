<div class="vehicle-selection-wrapper">

    <div class="title">
        Vehicle Selection
    </div>

    <p class="description">Please select the minimum vehicle required for this shipment</p>

    <mat-button-toggle-group name="vehicleButtons" (change)="onChange($event)">

        <mat-button-toggle *ngFor="let vehicle of (vehicles$ | async); trackBy: trackByFn" value="{{ vehicle.id }}"
            checked="{{ vehicle.isChecked }}" (change)="onChange($event)" [disabled]="vehicle.disabled">
            <!-- <mat-icon *ngIf="vehicle.isChecked">done</mat-icon> -->
            {{ vehicle.name }}
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>