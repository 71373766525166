import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { PasswordValidationVO } from './../../../auth/model/model';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { select } from '@angular-redux-ivy/store';
import { AuthActions } from '../../../auth/actions/auth.actions';

@Component({
    selector: 'tuya-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements AfterViewInit {
    model: any = {};

    @ViewChild('modalChangePassword', {static: true}) modal: any;
    @Input() validationResult: PasswordValidationVO | null;
    @Output() changePasswordSubmit = new EventEmitter<any>();
    @select(['auth', 'isChangePasswordModalShow'])
    readonly isChangePasswordModalShow$: Observable<boolean>;
    @ViewChild('changePasswordForm', {static: true}) changePasswordForm: NgForm;
    passwordPattern = /^([a-zA-Z\d-,.?@&!#'~*_;+]+\s*)+$/;
    upperCase = /[A-Z]+/;
    lowerCase = /[a-z]+/;
    digits = /\d+/;
    specialCharacters = /[-,.?@&!#'~*_;+]+/;
    isNewPasswordValid = true;

    private authActions;

    constructor(authActions: AuthActions) {
        this.authActions = authActions;
    }

    ngAfterViewInit() {
        this.isChangePasswordModalShow$.subscribe(data => {
            if (data) {
                this.showModal();
            } else {
                this.modal.hide();
            }
        });
    }
    showModal() {
        this.clearValidationResults(); // clear validation results (for case when modalChangePasswordHide() was not called)
        this.modal.show();
        this.changePasswordForm.resetForm();
    }
    isPasswordEmpty(password: string) {
        return (password === undefined || password === null) ? true : !password.length;
    }
    dispatcHHideModal() {
        this.authActions.hideModal();
    }
    clearValidationResults() {
        this.model = {};
        this.authActions.clearChangePasswordValidationResult();
    }
    modalChangePasswordHide() {
        this.modal.hide();
    }
    isPasswordValid(password, val = false) {
        return ((password.dirty || this.changePasswordForm.submitted) && (!val ? password.valid : password.invalid));
    }
    saveNewPassword() {
        this.changePasswordSubmit.emit(this.model);
    }
    isNewPasswordEasy(password: string) {
        return !(this.isNewPasswordValid || this.isPasswordEmpty(password));
    }
    isNewPasswordNotValid(password) {
        return ((password.dirty || this.changePasswordForm.submitted) && password.invalid || this.isNewPasswordEasy(password.value));
    }
    onNewPasswordChange() {
        if (this.model.newPassword) {
            this.isNewPasswordValid = this.upperCase.test(this.model.newPassword) && this.lowerCase.test(this.model.newPassword)
                && this.digits.test(this.model.newPassword) && this.specialCharacters.test(this.model.newPassword);
        }
    }
    // server errors for new and old passwords
    hasOldPasswordErrors() {
        return this.validationResult && this.validationResult.msgsOld && this.validationResult.msgsOld.length;
    }
    hasNewPasswordErrors() {
        return this.validationResult && this.validationResult.msgs && this.validationResult.msgs.length;
    }
    onFocusInOldPassword() {
        this.authActions.clearChangePasswordOldPasswordError();
    }
    onFocusInNewPassword() {
        this.authActions.clearChangePasswordNewPasswordError();
    }
}
