import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ManageCompanyTab } from '../../model/model';
import { CreditCardDto, CreditCardTypes } from '../../../core/models/payment.dto';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BillingActions } from '../../../profiles/actions/billing.actions';
import { select } from '@angular-redux-ivy/store';
import { BillingSelectors } from '../../selectors/billing.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'tuya-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent {

  @Input() currentManageCompanyTab: ManageCompanyTab;
  @Input() isLoading: boolean;
  @Input() suspended: boolean;
  @Input() public set paymentMethods(cards: CreditCardDto[]) {
    if (!cards) { return; }
    this._paymentMethods = cards.sort(this.compareCards);
  }

  @Output() paymentOrderSubmit = new EventEmitter<CreditCardDto>();
  @Output() validateCardStart = new EventEmitter<boolean>();

  @ViewChild('addCard', { static: true }) addCardModal;

  @select(BillingSelectors.getIsPaymentModalOpen) isPaymentModalOpen$: Observable<boolean>;
  @select(BillingSelectors.getIsGettingPaymentMethods) isGettingPaymentMethods$: Observable<boolean>;

  public cardTypes = CreditCardTypes;
  public manageCompanyTab = ManageCompanyTab;
  public showForm = true;
  public get paymentMethods(): CreditCardDto[] {
    return this._paymentMethods;
  }
  public PAYMENT_METHOD_DISPLAY_HEIGHT = 65;
  private _paymentMethods: CreditCardDto[] = [];
  private isFormTouched = false;

  constructor(private modalService: BsModalService,
    private billingActions: BillingActions) {
    this.isPaymentModalOpen$.subscribe(openModal => {
      if (openModal) {
        this.addNewCard();
      } else {
        this.closeModal();
      }
    });
  }

  public getExpirationDate(month: number, year: number) {
    const yearStr = String(year);
    return `${month}/${yearStr.substr(yearStr.length - 2)}`;
  }

  public getScrollHeight() {
    return this.paymentMethods.length * this.PAYMENT_METHOD_DISPLAY_HEIGHT;
  }

  public isModelChanged() {
    return this.isFormTouched;
  }

  public addNewCard() {
    this.modalService.show(this.addCardModal, { ignoreBackdropClick: true });
    this.isFormTouched = true;
    return;
  }

  public dispatchOpenModal() {
    this.billingActions.openPaymentModal();
  }

  public dispatchCloseModal() {
    this.billingActions.closePaymentModal();
  }

  public closeModal() {
    // This drove the "prevent close" dialog
    // if (this.modalService.getModalsCount() > 0) {
    //   this.modalService.hide(this.modalService.getModalsCount());
    //   this.isFormTouched = false;
    //   this.showForm = true;
    //   document.body.classList.remove('modal-open');
    // }
    this.showForm = true;
    this.modalService.hide();
    this.isFormTouched = false;
  }

  public cancelCreation() {
    this.showForm = false;
  }

  public continueCardCreation() {
    this.showForm = true;
  }

  public validateCard() {
    //this.billingActions.turnOnValidateCC();
    this.billingActions.getPaymentMethods();
    //this.closeModal();
    this.cancelCreation();
    this.dispatchCloseModal();
    this.closeModal();
  }

  public onPaymentFrameEvent(creditCard: CreditCardDto) {
    this.onPaymentOrderSubmit(creditCard);
  }

  public onPaymentOrderSubmit(creditCard: CreditCardDto) {
    this.paymentOrderSubmit.emit(creditCard);
  }

  displayButtonText() {
    //return this.paymentMethods.length ? 'tuya-company.payment.replace' : 'tuya-company.payment.add';
    // Always show add card
    return 'tuya-company.payment.add';
  }

  private compareCards(a, b) {
    return b.isDefault - a.isDefault;
  }
}
