<div class="rate-driver-component"
     [tooltip]="'tuya-my-orders.driver-info.rate-time-limit' | translate"
     [isDisabled]="!isTimeToRateExceed">
    <div class="shipper-assess" [ngClass]="{'rating-disabled': isTimeToRateExceed}">
        <div class="shipper-assess-header" [translate]="'tuya-my-orders.driver-info.rate-driver'"></div>
        <div class="rate-range">
            <span *ngFor="let r of rateRange" (click)="onRateDriver(r.id)"
                  [tooltip]="r.value | translate"
                  [ngClass]="{good: r.id <= shipperRate}">
            </span>
        </div>
    </div>
    <div class="rate-reason" *ngIf="isDriverRated">
        <select class="form-control reason" [ngModel]="shipperReasonId" name="reason" #reason="ngModel">
            <option hidden value="0"> {{'tuya-my-orders.driver-info.select-reason' | translate}}</option>
            <option *ngFor="let r of rateReasons" [ngValue]="r.id">{{r.name}}</option>
        </select>
        <span class="save-reason-btn"
              *ngIf="reason.value && reason.value.id !== 0"
              (click)="onCommentRate(reason.value)">
            </span>
    </div>
    <div *ngIf="isFeedbackHintVisible" class="feedback"
         [translate]="'tuya-my-orders.driver-info.feedback-updated'"></div>
</div>
