import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UIActions } from '../../../shipment/actions/ui.actions';
import { WizardStep } from '../../../shipment/models/ui.model';
import { ShipmentEditSelectors } from '../../selectors/shipment-edit.selectors';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState } from '../../../store/model';
import { ShipmentEditActions } from '../../actions/shipment-edit.actions';
import { OrderSelectors } from '../../../shipment/selectors/order.selectors';


@Component({
    selector: 'tuya-edit-confirm-page',
    templateUrl: './edit-confirm.page.html',
    styleUrls: ['./edit-confirm.page.scss']
})
export class EditConfirmPageComponent implements OnInit {
    @Input() newPrice: number;
    @Input() oldPrice: number;

    constructor(public bsModalRef: BsModalRef,
                private uiActions: UIActions,
                private shipmentEditActions: ShipmentEditActions,
                private ngRedux: NgRedux<IAppState>) {
    }

    ngOnInit() {
        const priceChange = ShipmentEditSelectors.selectOrderPriceChange(this.ngRedux.getState());
        this.newPrice = priceChange.newPrice;
        this.oldPrice = priceChange.oldPrice;
    }

    onEditConfirm() {
        // On confirmation proceed to step 4, which will send a confirm service request, the success callback of the request
        // will set the wizard to step 6 for the confirmation screen.
        this.uiActions.onWizardStepContinue(WizardStep.SummaryAndDriverAssignment);
        this.bsModalRef.hide();
    }

    onEditCancel() {
        const orderId = OrderSelectors.orderIdSelector(this.ngRedux.getState());
        this.shipmentEditActions.editDiscardOrder(orderId);
        this.bsModalRef.hide();
    }

    ifShowPriceMessage(): boolean {
        return this.oldPrice !== this.newPrice;
    }
}
