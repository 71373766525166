import moment, { Moment } from 'moment';
import { ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { parseInputTime, formatToIsoTime } from '../utils/time';
import { TimeRangeOptions } from '../models/stop.model';
import { environment } from 'environments/environment';

export function timeInputRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {        
        const parsedTime = parseInputTime(control.value);
        if (!parsedTime) return { 'invalidTime': true };

        const dateInput = control.value;
        if (dateInput === '' || dateInput === null || dateInput.includes('_')) return { 'invalidTime': true };
        return null;
    };
}

/*
    group.controls.date.value is an object
    due to race condition, it may be undefined during edit mode loading, causing error
    this ternary is to prevent error caused by group.controls.date.value === undefined
*/
export function timeWindowStartValidator(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
        const fromTime = parseInputTime(group.controls.pickupNoEarlierThan.value);
        if (!fromTime) return { 'startTimeBeforeCurrentTime': true };

        const selectedDate = (group.controls.date.value) ? group.controls.date.value : null;
        const formattedTime = formatToIsoTime(selectedDate, fromTime);
        const start = new Date(formattedTime);
        const startTime = start.getTime();

        const now = new Date().getTime();
        return (startTime > now) ? null : { 'startTimeBeforeCurrentTime': true };
    };
}

/*     
    group.controls.date.value is an object
    due to race condition, it may be undefined during edit mode loading, causing error
    this ternary is to prevent error caused by group.controls.date.value === undefined
 */
export function minTimeWindowValidator(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
        // date
        const currentDate = new Date();
        const selectedDate = group.controls.date.value ? new Date(group.controls.date.value) : currentDate;

        // from
        const fromTime = parseInputTime(group.controls.pickupNoEarlierThan.value);
        if (!fromTime) return { 'endBeforeStart': 'invalid time' };

        const fromTimeParts = fromTime.split(':').map(part => parseInt(part, 10));
        const startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        const start = new Date(startDate);
        start.setHours(fromTimeParts[0], fromTimeParts[1], 0, 0);

        // to
        const toTime = parseInputTime(group.controls.pickupNoLaterThan.value);
        if (!toTime) return { 'endBeforeStart': 'invalid time' };
        
        const toTimeParts = toTime.split(':').map(part => parseInt(part, 10));
        const endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        const end = new Date(endDate);
        end.setHours(toTimeParts[0], toTimeParts[1], 0, 0);

        const timeDifference = (end.getTime() - start.getTime()) / 1000 / 60; // minutes
        if (timeDifference < 0) return { 'endBeforeStart': `${timeDifference} minutes` };
        if (timeDifference < 30 && timeDifference >= 0) return { 'minTimeWindow': `${timeDifference} minutes` };
        return null;
    };
}

// allows startTimeBeforeCurrentTime error so the user is presented with a notification when "Next" is clicked
export function isTimeFormValid(timeForm: AbstractControl, timeSelection: number) {
    // ignore all timeForm errors if "easy" tinme window is selected
    if (timeSelection === TimeRangeOptions.EasyWindow) return true;

    const startTimeValid = timeForm.get('pickupNoEarlierThan').valid;
    const endTimeValid = timeForm.get('pickupNoLaterThan').valid;
    let noMinTimeWindowError = true;
    let noEndBeforeStartError = true;

    if (timeForm.errors) {
        if (timeForm.errors.minTimeWindow) { noMinTimeWindowError = false; }
        if (timeForm.errors.endBeforeStart) { noEndBeforeStartError = false; }
    }

    return startTimeValid && endTimeValid && noMinTimeWindowError && noEndBeforeStartError;
}

export const TimeValidators = (() => {
    const startTimeIsGreaterThanEndTime = (startTime: string, endTime: string): boolean => {
        if (!environment.production) console.debug('StartTime: ' + startTime + ' > EndTime: ' + endTime);
        return moment(startTime).isBefore(moment(endTime));
    };

    const startTimeIsNotInThePast = (startTime: string): boolean => {
        if (!environment.production) console.debug('StartTime: ' + startTime + ' > EndTime: ' + moment().toISOString());
        return moment(startTime).isAfter(moment());
    };

    const pickupTimeIsAfterDeliveryTime = (pickupTime: string, deliveryTime: string) => {
        if (!environment.production) console.debug('pickupTime: ' + pickupTime + ' > deliveryTime: ' + deliveryTime);
        return moment(pickupTime).isAfter(deliveryTime);
    };

    return {
        startTimeIsGreaterThanEndTime,
        startTimeIsNotInThePast,
        pickupTimeIsAfterDeliveryTime
    };
})();
