import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { StopDto } from '../../../core/models/dto';
import { StopType } from '../../models/stop.model';
import { BuilderActions } from '../../actions/builder.actions';
import { BuilderSelectors } from '../../selectors/builder.selectors';
import { Alert, AlertType } from '../../../core/models/alert.model';
import { AlertSelectors } from '../../../core/selectors/alert.selectors';

@Component({
  selector: 'tuya-address-contact',
  templateUrl: './address-contact.component.html',
  styleUrls: ['./address-contact.component.scss']
})
export class AddressContactComponent implements OnInit {
  @ViewChild('suiteNumber', {'static': false}) suiteNum: ElementRef;
  @Input() idKey: string;
  @Input() stopType: number;
  @Input() stop: StopDto;
  @Input() stopIndex: number;
  @Input() addressContactForm: UntypedFormGroup;

  @Input() materialLocked = false;
  @Input() nonMaterialLocked = false;

  @select(BuilderSelectors.getStops)
  stops$: Observable<StopDto[]>;
  // Select alert to clear fields on not in MSA alert. This is a work-around for change detection problems.
  @select(AlertSelectors.alertSelector)
  alert$: Observable<Alert>;

  addressNamePlaceholder: string;
  StopType;
  displayAddressSearchError = false;
  nameOrDescriptionPrevVal = null;
  editNamePrevVal = null;

  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(private builderActions: BuilderActions) {
    // Subscribe to the alert to work around change detection not working in the action dispatched by epic.
    this.alert$.subscribe( alert => {
      if (alert && alert.type === AlertType.NotInMsa) {
        this.addressContactForm.controls.nameOrDescription.setValue(null);
        this.addressContactForm.controls.suiteNumber.setValue(null);
      }
    });
  }

  ngOnInit() {
    this.StopType = StopType;
    if (this.stopType === StopType.Pickup) {
      this.addressNamePlaceholder = 'builderAddress.namePlaceholderPickup';
    } else if (this.stopType === StopType.Delivery) {
      this.addressNamePlaceholder = 'builderAddress.namePlaceholderDelivery';
    }
      
    this.editNamePrevVal = this.addressContactForm.controls.contactName.value;
    this.nameOrDescriptionPrevVal = this.addressContactForm.controls.nameOrDescription.value;    
  }

  ngAfterViewInit() {
    const nameOrDescription = this.addressContactForm.controls.nameOrDescription.value;
    if (nameOrDescription === null || nameOrDescription.trim() === '') {
      this.onShowAddressSearchError();
    }
  }

  onAddressSelectEvent(name) {
    this.suiteNum.nativeElement.focus();
    this.addressContactForm.controls.nameOrDescription.setValue(name);
    this.builderActions.onEditNameOrDescription(name, this.stopIndex);
    this.onHideAddressSearchError();
  }

  onEditNameOrDescription(event) {
    const newNameOrDescription = event.target.value;
    this.builderActions.onEditNameOrDescription(newNameOrDescription, this.stopIndex);
  }

  onEditPhoneNumber(event) {
    // Replace non-numeric characters in the string with nothing.
    const phoneNumber = event.target.value.replace(/[\(\) -]/g, '');
    this.builderActions.onEditPhoneNumber(phoneNumber, this.stopIndex);
  }

  onEditAddress(event) {
    const newStop = { ...this.stop };
    newStop.address[event.target.dataset.key] = event.target.value;

    this.builderActions.onEditStop(newStop, this.stopIndex);
  }

  onEditEmailAddress(event) {
    const newStop = { ...this.stop };
    const email = event.target.value;
    if ((this.addressContactForm['controls'].contactEmail.invalid || !email)) {
      newStop.isEmailTrackingLink = false;
    }
    newStop.contactEmail = email;
    this.builderActions.onEditStop(newStop, this.stopIndex);
  }

  onEditName(event) {
    const val = event.target.value;
    const letters = /^[a-zA-Z0-9]+$/;
    if (event.target && val.trim() !== '' && val[0].match(letters)) {
      this.editNamePrevVal = val;
      const newStop = {
        ...this.stop,
        contactName: val
      };
      this.builderActions.onEditStop(newStop, this.stopIndex);
    } else {
      this.editNamePrevVal = val.trim() === '' ? null : this.editNamePrevVal;
      this.addressContactForm.controls.contactName.setValue(this.editNamePrevVal);
    }
  }

  get nameOrDescription() {
    return this.addressContactForm.get('nameOrDescription');
  }

  get contactName() {
    return this.addressContactForm.get('contactName');
  }

  get contactPhoneNumber() {
    return this.addressContactForm.get('contactPhoneNumber');
  }

  get contactEmail() {
    return this.addressContactForm.get('contactEmail');
  }

  onShowAddressSearchError() {
    this.displayAddressSearchError = true;
  }

  onHideAddressSearchError() {
    this.displayAddressSearchError = false;
  }
}
