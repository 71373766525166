import { AuthenticationService } from '../services/authentication.service';
import {
    AuthActions,
    PASSWORD_RESET_REDIRECT,
    REQUEST_FORGOT_PASSWORD,
    REQUEST_RESET_PASSWORD,
    REQUEST_RESET_PASSWORD_SUCCESS
} from '../actions/auth.actions';
import { combineEpics, ofType } from 'redux-observable';
import { transformValidateAccountResult } from '../../register/transformations/validate-result.transformation';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { UPDATE_LOCATION } from '@angular-redux-ivy/router';
import { Injectable } from '@angular/core';
import { switchMap, map, exhaustMap, catchError } from 'rxjs/operators';
import { AuthUserFull } from '../model/model';

@Injectable()
export class ForgotPasswordEpics {
    constructor(private authenticationService: AuthenticationService,
                private authActions: AuthActions,
                private router: Router) {
    }

    public createEpic() {
        return combineEpics(createRequestForgotPassword(this.authenticationService, this.authActions),
            createRequestResetPasswordEpic(this.authenticationService, this.authActions),
            createRedirectPasswordResetEpic(this.router),
            createPasswordResedSuccessEpic(this.authActions)
        );
    }
}

export function createRequestResetPasswordEpic(authService: AuthenticationService,
                                               authActions: AuthActions) {
    return (action$, store) => action$.pipe(
        ofType(REQUEST_RESET_PASSWORD),
        switchMap((action: any) => {
            return authService.requestResetPassword(
                store.value.auth.passwordResetToken,
                action.payload).pipe(
                map((resp: AuthUserFull) => authActions.requestResetPasswordSucceeded(resp))
                ,catchError(resp => {
                    const result = transformValidateAccountResult(resp.error);
                    return of(authActions.requestResetPasswordFailed({
                        isValid: result.isValid,
                        msgs: result.passwordErrorMsg
                    }));
                }));
        })
    );
}

export function createPasswordResedSuccessEpic(authActions: AuthActions) {
    return action$ => action$.pipe(
        ofType(REQUEST_RESET_PASSWORD_SUCCESS),
        map((action) => {
            return authActions.loginRedirect();
        })
    );
}

export function createRedirectPasswordResetEpic(router: Router) {
    return action$ => action$.pipe(
        ofType(PASSWORD_RESET_REDIRECT),
        map((action) => {
            router.navigate(['/forgotPassword']);
            return {
                type: UPDATE_LOCATION,
                payload: '/forgotPassword',
            };
        })
    );
}

export function createRequestForgotPassword(authService: AuthenticationService,
                                            authActions: AuthActions) {

    return action$ => action$.pipe(
        ofType(REQUEST_FORGOT_PASSWORD),
        exhaustMap((action: any) => {
            return authService.requestForgotPassword(action.payload).pipe(
                map(() => {
                    return authActions.requestForgotPasswordSucceeded(action.payload);
                })
                ,catchError(error => of(authActions.requestResetPasswordFailed(error))));
        })
    );
}
