{
  "baseUrl": "https://qa.shippersolutions.com",
  "apiBaseUrl": "https://qa-api.shippersolutions.com",
  "openIdBaseUrl": "https://qa-login.shippersolutions.com",
  "apiKey": "ef629bb6-80e1-4990-a01e-1207355bb005",
  "creditCardAccountName": "tuyatech-cc-test",
  "payment2CPUrl": "https://qa.shippersolutions.com",
  "configuration": "qa",
  "paymentUri": "2cp",
  "buildNumber": "Release-1261",
  "Firebase_Client_apiKey": " AIzaSyDMnBNBsmsMcsYzMe72dS4HxoIHemDG6YE",
  "Firebase_Client_authDomain": "tuyadriver-qa.firebaseapp.com",
  "Firebase_Client_databaseURL": "https://tuyadriver-qa.firebaseio.com",
  "Firebase_Client_projectId": "tuyadriver-qa",
  "Firebase_Client_storageBucket": "tuyadriver-qa.appspot.com",
  "Firebase_Client_messagingSenderId": "700701594990",
  "Firebase_Client_appId": "1:700701594990:web:0c35009c5d742b6e479d7f",
  "driverAndroidApp": "https://play.google.com/store/apps/details?id=com.tuyatech.driverapp",
  "driverIosApp": "https://apps.apple.com/us/app/tuya-driver/id6453935721",
  "driverIosAppName": "skype-for-iphone",
  "driverIosAppId": "304878510",
  "mobileAppDomain": "https://link-qa.shippersolutions.com/",
  "mobileAppAndroidDriver": "tuya-driver://",
  "mobileAppAndroidShipper": "tuya-shipper://",
  "googleMapsKey": "AIzaSyBVnYzZOwi9rEtmqx9S1guF3uhfBnogDl8",
  "stripePubKey": "pk_test_51LrMExHGPITBllPaRvqQFVjKMaoLeB0dYgNWBYZiMLcNbrogopEeUc5JjNuXxNgJEGi6MDBBWRFj1774Z1NXQJ0z00FpHrOZ2O",
  "stripeIsLive": "False"
}