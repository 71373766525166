import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { Action } from '../../core/models/redux.action';
import { ShipperProfileDto } from '../../core/models/dto';


export type ShipperAction = Action<any, any>;

export const LOAD_SHIPPER_PROFILE = '[SHIPPER] Load Profile';
export const LOAD_SHIPPER_PROFILE_SUCCESS = '[SHIPPER]  Load Profile Success';
export const LOAD_SHIPPER_PROFILE_FAIL = '[SHIPPER]  Load Profile Fail';

export const UPDATE_CAN_CREATE_SHIPEMENT = '[SHIPPER] Update Shipper Status Can Create Shipment';
export const UPDATE_HAS_VALID_CC = '[SHIPPER] Update Shipper Has CC';

export const UPDATE_SHIPPER_STATUS_HAS_ORDERS = '[SHIPPER] Update Shipper Status Has Orders';
export const SET_INITIAL_STATE = '[SHIPPER] Set Initial Shipper Status';

@Injectable()
export class ShipperActions {
    @dispatch()
    loadProfile = (userAccountId?: any): ShipperAction => ({
        type: LOAD_SHIPPER_PROFILE,
        payload: userAccountId
    })

    loadProfileSucceeded = (shipperProfile: ShipperProfileDto): ShipperAction => ({
        type: LOAD_SHIPPER_PROFILE_SUCCESS,
        payload: shipperProfile
    })

    loadProfileFailed = (error: any): ShipperAction => ({
        type: LOAD_SHIPPER_PROFILE_FAIL,
        payload: null,
        error
    })

    @dispatch()
    updateCanCreateShipment = (canCreateShipment: boolean): ShipperAction => ({
        type: UPDATE_CAN_CREATE_SHIPEMENT,
        payload: canCreateShipment
    })

    @dispatch()
    updateHasValidCC = (hasValidCC: boolean): ShipperAction => ({
        type: UPDATE_HAS_VALID_CC,
        payload: hasValidCC
    })

    @dispatch()
    updateShipperStatusHasOrders = (hasOrders: boolean): ShipperAction => ({
        type: UPDATE_SHIPPER_STATUS_HAS_ORDERS,
        payload: hasOrders
    })

    @dispatch()
    setInitialState = (): ShipperAction => ({
        type: SET_INITIAL_STATE,
        payload: null
    })
}
