import { StopStatusId } from '../../core/models/dto';
import { getInitialAddressState, AddressDto } from './address.model';

export enum PickupDateType {
    Today = 1,
    Tomorrow = 2
}

export enum StopType {
    Pickup = 1,
    Delivery = 2,
    Return = 3,
    DeliveryWithReturn = 4
}

export enum TimeRangeOptions {
    EasyWindow = 1,
    CustomWindow = 2
}

export const pickupStopIndex = 0;
export const deliveryStopIndex = 1;

/**
 * DTO definition for PUT/POST and used in application state.
 *
 * @TODO: move this to /core.models/dto after we get rid of old shipment builder
 * and make all object models match new API model.
 */
export class StopDto {
    id?: number;
    sequenceNumber: number;
    type: number;
    typeId?: number;
    stopDtoType?: number;
    nameOrDescription: string;
    pickupDateTypeId: number;
    pickupNoEarlierThan: string;
    pickupNoEarlerThan?: string;
    pickupNoLaterThan: string;
    inMsa?: boolean;
    isEmailTrackingLink: boolean;
    isTextTrackingLink: boolean;
    isSignatureRequired: boolean;
    itemsDescription: string;
    notes: string;
    status?: StopStatusId;
    contactName: string;
    contactPhoneNumber: string;
    contactEmail: string;
    address?: AddressDto;
}

/**
 * Get inital stop state for new stops
 * @param options Key/value pairs to serve as default initial parameters for stop.
 */
export function getInitialStopState(options): StopDto {
    return {
        sequenceNumber: options.sequenceNumber || 0,
        type: options.type || StopType.Pickup,
        typeId: options.type || StopType.Pickup,
        nameOrDescription: options.nameOrDescription || null,
        pickupDateTypeId: options.pickupDateTypeId || PickupDateType.Today,
        pickupNoEarlierThan: options.pickupNoEarlierThan || null,
        pickupNoLaterThan: options.pickupNoLaterThan || null,
        inMsa: options.inMsa || false,
        isEmailTrackingLink: options.isEmailTrackingLink || false,
        isTextTrackingLink: options.isTextTrackingLink || false,
        isSignatureRequired: options.isSignatureRequired || false,
        itemsDescription: options.itemsDescription || null,
        notes: options.notes || null,
        status: options.status || StopStatusId.New,
        contactName: options.contactName || null,
        contactPhoneNumber: options.contactPhoneNumber || null,
        contactEmail: options.contactEmail || null,
        address: options.address || getInitialAddressState(),
    };
}
