import { Stop, StopType } from '../models/order.model';
import { ShipmentType } from '../../core/models/shipment.model';
// import { Guid } from '../../core/utils/guid';

export const DEFAULT_GUID = null;

export class StopHelper {

    static createNewStop(stopTemplate: Stop, stopType: number, newStopIndex: number): Stop {
        const guid = null,
            address = stopTemplate.address,
            isSavedStop = stopTemplate && stopTemplate.nameOrDescription;
        let stop = new Stop();

        address.id = guid;
        stop = {
            ...stop,
            typeId: stopType,
            addressId: guid,
            sequenceNumber: newStopIndex,
            address: address
        };

        if (isSavedStop) {
            stop = {
                ...stop,
                id: DEFAULT_GUID,
                nameOrDescription: stopTemplate.nameOrDescription,
                contactName: stopTemplate.contactName,
                contactPhoneNumber: stopTemplate.contactPhoneNumber,
                contactEmail: stopTemplate.contactEmail,
                isEmailTrackingLink: stopTemplate.isEmailTrackingLink,
                isTextTrackingLink: stopTemplate.isTextTrackingLink,
                isSignatureRequired: stopTemplate.isSignatureRequired
            };
        }

        return stop;
    }

    static setStopOnLastPositionByType(stopList: Array<Stop>, stopType: number): Array<Stop> {
        const stopListLength = stopList.length;

        stopList.filter(st => st.typeId === stopType)
            .map(item => item.sequenceNumber = stopListLength + 1); // set last sequenceNumber for stop

        return stopList;
    }

    static getFirstStop(state) {
        return state.shipment.order.stopList.filter(stop => stop.typeId === StopType.Pickup)
            .sort((a, b) => a.sequenceNumber - b.sequenceNumber)[0];
    }

    static getIsCollectionDelivery(state, copyStopList, stopIndex) {
        const removedStop = copyStopList[stopIndex];
        return state.deliveryType === ShipmentType.Collection && removedStop.typeId === StopType.Delivery;
    }

    static updateStopItemsCount(itemList, id, isCollectionDelivery) {
        // for Collection order Delivery stop - just remove id from Item because item related to Pickup stop,
        // in other case - remove whole item - because for Distribution order item related to Delivery stop
        if (isCollectionDelivery) {
            return itemList.map(item => {
                const index = item.stopIds.indexOf(id);
                if (index !== -1) {
                    item.stopIds.splice(index, 1);
                    return item;
                } else {
                    return;
                }
            }).filter(item => item !== undefined);
        } else {
            /*
            * We need to handle items when editing stop.
            * Set to default guid for removed stop, remove other items;
            * */
            let isExisted;
            return itemList.map((item) => {
                const index = item.stopIds.indexOf(id);
                if (index !== -1 && !isExisted) {
                    item.stopIds[index] = DEFAULT_GUID;
                    isExisted = true;
                    return item;
                } else if (index !== -1 && isExisted) {
                    item = undefined;
                    return;
                }
                return item;
            }).filter(item => item);
        }
    }
}
