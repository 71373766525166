import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { select } from '@angular-redux-ivy/store';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../app.config.service';
import { DriverDto, DriverPoolDTO, PoolDto } from '../../../core/models/dto';
import { PoolsService } from '../../../core/services/pools.service';
import { PoolsActions } from '../../../core/actions/pools.actions';
import { BuilderActions } from '../../actions/builder.actions';
import { BuilderSelectors } from '../../selectors/builder.selectors';
import { SearchSelectComponent } from '../../../shared/components/search-select/search-select.component';
import { ISearchResult } from '../../../shared/components/search-select/search-result.model';
import { OfferType } from '../../models/order.model';
import { VehicleTypeIds } from '../../../core/models/order.dto';
import { AssignmentsDto } from '../../models/assignments.model';
import { DriversSelectors } from '../../../core/selectors/pools.selector';
import { Observable } from 'rxjs';

@Component({
  selector: 'tuya-builder-driver-selection',
  templateUrl: './builder-driver-selection.component.html',
  styleUrls: ['./builder-driver-selection.component.scss']
})
export class BuilderDriverSelectionComponent implements OnInit {
  @ViewChild('exclusiveDriversSelect', {static: true}) exclusiveDriversSelect: SearchSelectComponent;
  @ViewChild('driverPoolsSelect', {static: true}) driverPoolsSelect: SearchSelectComponent;

  @select (BuilderSelectors.getOfferType) offerType$: Observable<number>;
  @select (BuilderSelectors.getDriverSearchResults) driverSearchResults$: Observable<Array<ISearchResult>>;
  @select (BuilderSelectors.getPoolSearchResults) poolSearchResults$: Observable<Array<ISearchResult>>;
  @select (BuilderSelectors.getPoolSelections) poolSelections$: Observable<Array<any>>;
  @select (BuilderSelectors.getDriverSelections) driverSelections$: Observable<Array<any>>;
  @select (BuilderSelectors.getIsExtendable) isExtendable$: Observable<boolean>;
  @select (BuilderSelectors.getAssignmentsDto) assignments$: Observable<AssignmentsDto>;
  @select (BuilderSelectors.getVehicleTypeId) vehicleTypeId$: Observable<number>;
  @select (DriversSelectors.getPools) poolsFromState$: Observable<Array<PoolDto>>;
  @select (['builder', 'ui', 'driverSelection', 'driver', 'pending']) driversPending$: Observable<boolean>;

  @Input() materialLocked = false;
  @Input() currentStep: number;
  @Input() stopCount: number;

  public VehicleTypes = VehicleTypeIds;
  public driverSearchResults: ISearchResult[];
  public poolSearchResults: ISearchResult[];
  public offerType: number;
  public poolSelections;
  public driverSelections;
  public offerTypeEnum;
  public isExtendable: boolean;
  public poolsList: any[]; // formatted pool list items
  public filteredPools: any[]; // filtered pool list items

  private service: PoolsService;
  private assignments: AssignmentsDto;
  private poolsFromState: Array<PoolDto>;
  private vehicleTypeId: number;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private builderActions: BuilderActions,
    private poolsActions: PoolsActions,
    private changeDetector: ChangeDetectorRef) {
    this.service = new PoolsService(this.http, this.config);
    this.offerTypeEnum = OfferType;
    this.offerType$.subscribe(result => this.offerType = result);
    this.driverSearchResults$.subscribe(result => this.driverSearchResults = result);
    this.poolSearchResults$.subscribe(result => this.poolSearchResults = result);
    this.poolSelections$.subscribe(result => this.poolSelections = result);
    this.driverSelections$.subscribe(result => this.driverSelections = result);
    this.isExtendable$.subscribe(result => this.isExtendable = result);
    this.assignments$.subscribe(result => this.assignments = result);
    this.poolsFromState$.subscribe(result => this.poolsFromState = result);
    this.vehicleTypeId$.subscribe(result => this.vehicleTypeId = result);
  }

  ngOnInit() {
    // update pools
    this.poolsActions.loadPoolList();
  }

  /**
   * Handle logic when search field is clicked
   * If pools search field, refresh poolsList to display all pools
   * @param inputText
   */
  onTextFocus(identifier: string) {
    if (identifier === 'pool') {
      this.poolsList = this.poolsFromState.map(pool => {
        return {
          id: pool.id,
          name: pool.name
        };
      });
      this.filteredPools = this.poolsList;
      this.builderActions.setDriverSelectSearchResult(this.filteredPools, 'pool');
    }
  }

  /**
   * Call delay, perform search.
   * @param event Value typed into input field.
   */
  onTypeEventHandler(event: String, identifier: string) {
    if (identifier === 'driver') {
      this.delay( () => {
        this.builderActions.onSetSearchTerm(event, identifier);
        const payload = {
          query: event,
          minVehicleType: this.vehicleTypeId || 0
        };
        if (event.length) {
          this.builderActions.onSetPending(true, identifier);
          this.makeDriverRequest(payload);
        } else {
          this.builderActions.setDriverSelectSearchResult([], 'driver');
          this.changeDetector.detectChanges();
        }
      }).bind(this)();
    }
    if (identifier === 'pool') {
      this.filterPools(event);
      this.builderActions.onSetSearchTerm(event, identifier);
    }
  }

  /**
   * Filter pools to display the ones that match input text
   * @param inputText
   */
  filterPools(inputText: String) {
    const text = inputText.toLowerCase();
    this.filteredPools = this.poolsList.filter(pool => {
      return pool.name.toLowerCase().includes(text);
    });
    this.builderActions.setDriverSelectSearchResult(this.filteredPools, 'pool');
  }

  /**
   * Make service request for driver search.
   * @param payload
   */
  makeDriverRequest(payload) {
    this.service.getDriverByQuery(payload).subscribe(
      results => {
        results = results || [];
        this.builderActions.setDriverSelectSearchResult(results, 'driver');
        this.changeDetector.detectChanges();
      },
      error => console.error(error),
      () => this.builderActions.onSetPending(false, 'driver')
    );
  }

  /**
   * Delay 300 milliseconds before executing callback. If this is called before
   * the 300ms is up, reset the timer. Intention is to not send requests with
   * every keystroke.
   */
  delay(callback) {
    return function() {
      const context = this;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(function () {
        callback.apply(context);
      }, 300);
    };
  }

  /**
   * Call builder action when drivers are selected.
   * @param drivers Array of drivers.
   */
  submitSelectedDrivers(drivers: DriverDto[]): void {
    this.builderActions.onSelectDrivers(drivers);
  }

  /**
   * Call builder action to add pools to state.
   * @param pools
   */
  submitSelectedPools(pools: DriverPoolDTO[]): void {
    this.builderActions.onSelectPools(pools);
  }

  onCheckboxToggle(event) {
    this.builderActions.onUpdateOrder({
      key: event.key,
      value: event.value
    });
  }

  /**
   * Handle toggling of driver selection radio input.
   */
  onUpdateDriverSelectionType(): void {
    this.builderActions.onUpdateOrder({
      key: 'offerType',
      value: this.offerType
    });
    switch (this.offerType) {
      case OfferType.Any: { // 1
        // General offer, clear drivers and pools.
        this.clearDrivers();
        this.clearPools();
        this.builderActions.onUpdateOrder({
          key: 'isExtendable',
          value: false
        });
        break;
      }
      case OfferType.Exclusive: { // 2
        // Exclusive offer, clear pools.
        this.builderActions.onUpdateOrder({
          key: 'isExtendable',
          value: true
        });
        this.clearPools();
        break;
      }
      case OfferType.DriversPool: { // 3
        this.builderActions.onUpdateOrder({
          key: 'isExtendable',
          value: true
        });
        // Limited offer, clear drivers.
        this.clearDrivers();
        break;
      }
    }
  }

  /**
   * Save selected drivers or pools to state.
   * @param selected
   * @param driversOrPool
   */
  onSelectDrivers(selected, driversOrPool) {
    if (driversOrPool === 'driver') {
      this.builderActions.onSelectDrivers(selected);
      this.builderActions.setDriverSelectSearchResult([], 'driver');
      this.exclusiveDriversSelect.clearText();
    } else if (driversOrPool === 'pool') {
      this.builderActions.onSelectPools(selected);
      this.builderActions.setDriverSelectSearchResult([], 'pool');
      this.driverPoolsSelect.clearText();
    }
  }

  /**
   * Update state when "Offer extendable" switch is toggled.
   * @param event
   */
  onOfferExtendableToggle(event) {
    this.builderActions.onUpdateOrder({key: 'isExtendable', value: event.target.checked});
  }

  /**
   * Event handler for search-select changing search results.
   */
  onChangeSearchResults(results, driversOrPool) {
    this.builderActions.setDriverSelectSearchResult(results, driversOrPool);
  }

  validateDriverSelection() {
    return (
      (this.offerType === OfferType.Any) ||
      (this.offerType === OfferType.Exclusive && this.assignments && this.assignments.drivers.length) ||
      (this.offerType === OfferType.DriversPool && this.assignments && this.assignments.pools.length)
    );
  }

  /**
   * Clear info in driver search-select.
   */
  private clearDrivers() {
    this.builderActions.onSelectDrivers([]);
    this.builderActions.setDriverSelectSearchResult([], 'driver');
    this.builderActions.onSetSearchTerm(null, 'driver');
    this.exclusiveDriversSelect.clearText();
  }

  /**
   * Clear info in pools search-select.
   */
  private clearPools() {
    this.builderActions.onSelectPools([]);
    this.builderActions.setDriverSelectSearchResult([], 'pool');
    this.builderActions.onSetSearchTerm(null, 'pool');
    this.driverPoolsSelect.clearText();
  }
}
