import { ProfileValidationResultVO } from './../../../register/model/model';
import { ProfilesSelectors } from './../../selectors/profiles.selectors';
import { PasswordValidationVO } from './../../../auth/model/model';
import { AuthActions } from './../../../auth/actions/auth.actions';
import { ProfilesActions } from './../../actions/profiles.actions';
import { ShipperProfileDto, BusinessEntityDto } from './../../../core/models/dto';
import { ProfileToEditVO } from '../../../register/model/model';
import { select } from '@angular-redux-ivy/store';
import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tuya-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  @select(ProfilesSelectors.profileToEditSelector)
  shipperProfilesInfo$: Observable<ProfileToEditVO>;
  @select(ProfilesSelectors.companyInfoToEditSelector)
  companyProfilesInfo$: Observable<BusinessEntityDto>;
  @select(ProfilesSelectors.profileValidateSelector)
  validateProfileErrors$: Observable<ProfileValidationResultVO>;
  @select(ProfilesSelectors.changePasswordValidationSelector)
  readonly changePasswordValidationResult$: Observable<PasswordValidationVO | null>;
  @select(ProfilesSelectors.isLoading)
  isLoading$: Observable<boolean>;
  @select(['shipper', 'shipperStatus', 'suspended'])
  readonly suspended$: Observable<boolean>;

  public model: ProfileToEditVO;
  public phoneMask =  ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phonePattern = /[0-9]{10}/;
  public namePattern = /^([a-zA-Z-.üöäÿïëßÄËÏÖÜŸ']+\s*)+$/;
  public isPhoneNumberValid = true;
  @ViewChild('shipperProfileForm', {static: true}) shipperForm;
  @ViewChild('cancel', {static: true}) modalCancel;

  private shipperProfilesInfo = new BehaviorSubject<ProfileToEditVO>(new ShipperProfileDto());
  private companyProfilesInfo = new BehaviorSubject<BusinessEntityDto>(new BusinessEntityDto());

  constructor(public modalService: BsModalService,
              private profilesActions: ProfilesActions,
              private authActions: AuthActions) {
     this.shipperProfilesInfo$.subscribe(this.shipperProfilesInfo);
     this.companyProfilesInfo$.subscribe(this.companyProfilesInfo);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.shipperForm.form.dirty && this.isModelChanged()) {
        return $event.returnValue = 'Do you want to reload this site? Changes you made may not be saved';
    }
  }

  ngOnInit() {
    this.model = {...this.shipperProfilesInfo.value};
    this.shipperProfilesInfo.subscribe(profile => { this.model = { ...profile }; });
  }

  isPhoneValid() {
    if (this.model.phoneNumber) {
      this.model.phoneNumber = this.model.phoneNumber.replace(/\D+/g, '');
      this.isPhoneNumberValid = this.phonePattern.test(this.model.phoneNumber);
    }
  }
  isPhoneEmpty() {
      return (typeof this.model.phoneNumber === 'undefined') ? true :
             !this.model.phoneNumber.length;
  }

  isModelChanged() {
    if (this.shipperProfilesInfo && this.shipperProfilesInfo.value && this.model) {
      return (this.shipperProfilesInfo.value.firstName !== this.model.firstName ||
        this.shipperProfilesInfo.value.lastName !== this.model.lastName ||
        this.shipperProfilesInfo.value.nickname !== this.model.nickname ||
        this.shipperProfilesInfo.value.phoneNumber !== this.model.phoneNumber ||
        this.shipperProfilesInfo.value.sendUpdates !== this.model.sendUpdates ) ?  true : false;
    }
    return false;
  }

  saveShipperProfileForm() {
    if (this.isPhoneNumberValid) {
      const companyProfile = this.companyProfilesInfo.value;
      const shipperProfile: ShipperProfileDto = {
        ...this.model,
        businessEntity: companyProfile
      };
      this.profilesActions.saveShipperProfile(shipperProfile);
    }
  }

  onClearErrors() {
    this.profilesActions.hideProfileErrors();
  }

  cancelShipperProfileForm(template: TemplateRef<any>) {
    this.modalService.show(template, { ignoreBackdropClick: true });
  }

  discardProfilesChanges() {
    this.model =  {...this.shipperProfilesInfo.value};
    this.isPhoneNumberValid = true;

    this.modalService.hide(1);
    return true;
  }

  onChangePasswordSubmit(changePassword) {
    this.authActions.requestChangePassword(changePassword);
  }

  clickShowChangePasswordModal() {
    this.authActions.showModal();
  }

}
